import { Pagination } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NoAccess from "../../components/NoAccess";
import Spinner from "../../components/Spinner";
import { apiName, delay, limit } from "../../components/Utils";

const Transporters = () => {
  const navigate = useNavigate();
  const route = useLocation()
  const path = route.pathname
  const roles = useSelector((state) => state.userInfo.payload?.role);

  const [transporters, setTransporters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState({ key: "name", direction: "asc" })
  const [total,setTotal] = useState(0)

  async function getData() {
    setLoading(true);
    try {
      let query = `/transporter?take=${limit}&skip=${count * limit}`
      if(sort.key) query = `${query}&sort=${sort.key}`
      if(sort.direction) query = `${query}&direction=${sort.direction}`

      if (search?.length > 0) query = `${query}&keyword=${search}`
      let res = await API.get(apiName,query)
      setTotal(res?.count)
      setTransporters(res?.items)
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  function handleSort(param) {
    setSort({
      key: param,
      direction: param === sort.key ? sort.direction === 'asc' ? 'desc' : 'asc' : "desc"
    })
  }

  function handlePaginationChange(newPageIndex) {
    setCount(newPageIndex - 1)
  }


  useEffect(() => {
    let timer = setTimeout(() => {
      roles?.includes("TRANSPORTER") && getData();
    }, delay);
    return () => clearTimeout(timer);
  }, [roles, count, search,sort?.key,sort.direction]);

  if (!roles?.includes("TRANSPORTER")) return <NoAccess />;

  return (
    <div className="container-fluid" >
      {/* header starts */}
      <div className="row align-items-center">
        <div className="col">
          <h1 className="header-title text-truncate">Transporters ({total})</h1>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary fw-bold"
            onClick={() => navigate(path.includes("mobile") ? "/mobile/transporter/add" : "/transporter/add")}>
            Add Transporter
          </button>
        </div>
      </div>
      <hr />
      {/* header ends */}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="contactsListPane"
              role="tabpanel"
              aria-labelledby="contactsListTab"
            >
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="input-group input-group-flush input-group-merge input-group-reverse">
                        <input
                          className="form-control list-search"
                          type="search"
                          placeholder="Search by transporter name or number"
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                        <span className="input-group-text">
                          <i className="fe fe-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <Spinner show={loading}>
                  <div className={window.innerWidth > 750 ? "table-responsive" : ''} style={{ height: "64vh" }}>
                    {window.innerWidth > 750 ? (
                      <table className="table table-sm table-hover position-relative ">
                        <thead className="sticky-top top-0">
                          <tr>
                            <th className="pointer" data-sort="id"><span onClick={() => handleSort('id')}>Number</span></th>
                            <th className="pointer" data-sort="id"><span onClick={() => handleSort('name')}>Name</span></th>
                            <th className="pointer text-center" data-sort="id"><span onClick={() => handleSort('vehicle_count')}>
                              No. of Vehicles</span>
                            </th>
                            <th className="text-center" >Work Order</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transporters.map((transporter) => (
                            <tr key={transporter.id} className="pointer" onClick={() => navigate(`/transporter/${transporter.id}`)}>
                              <td>{transporter?.id}</td>
                              <td>{transporter?.name}</td>
                              <td className="text-center">{transporter?.vehicle_count}</td>
                              <td className="text-center">{transporter?.work_order && transporter?.work_order.map(item=>(
                                <div className="mx-5">
                                <span>{item?.route}</span> :  
                                <span> {item?.number}</span>
                                </div>
                              ))}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="overflow-auto" style={{ height: "64vh" }}>
                        {transporters.map((transporter, index) => (
                          <div className="card m-2 pointer "
                            onClick={() => navigate(`/transporter/${transporter.id}`)} key={index}>
                            <div className="p-3 row">
                              <div className="col col-6">
                                <b>{transporter?.name}</b>
                                <p className="text-muted m-0 fs-5"><b>Email</b></p>
                                <p className="m-0"><b>{transporter?.email || 'Unknown'}</b></p>
                                <p className="text-muted m-0 fs-5"><b>Phone</b></p>
                                <p><b>{transporter?.phone || 'Unknown'}</b></p>
                              </div>
                              <div className="col col-6 text-end">
                                <p className="text-muted m-0"><b>No. of Vehicles</b></p>
                                <p className="m-0"><b>{transporter?.vehicles?.items?.length}</b></p>
                              </div>
                            </div>
                          </div>
                        ))}

                      </div>
                    )}
                    {transporters.length === 0 && !loading && (
                      <p className="text-center m-3">No Transporters </p>
                    )}
                  </div>
                  <div className="card-footer d-flex justify-content-center">
                    <Pagination
                      currentPage={count + 1}
                      totalPages={Math.ceil(total / limit)}
                      siblingCount={1}
                      onNext={() => setCount((prev) => prev + 1)}
                      onPrevious={() => setCount((prev) => prev - 1)}
                      onChange={handlePaginationChange}
                    />
                  </div>
                </Spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transporters;