import React from 'react'

const MapLoader = ({ show, children }) => {
    return (
        <>
            {show ?
                <div>
                    <div className="pin"></div>
                    <div className="pulse"></div>
                </div>
                :
                <>{children}</>
            }
        </>
    )
}

export default MapLoader
