import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../components/Spinner";
import { apiName, interval } from "../../components/Utils";
import Header from "../../layouts/Header";
import 'moment-timezone';

export default function GatePassUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.userInfo.payload);
  const role = user?.role;

  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gatepass, setGatepass] = useState({});
  const [vehicle, setVehicle] = useState(null);
  const [driver, setDriver] = useState(null);
  const [printing, setPrinting] = useState(false)
  const [currentJimms, setCurrentJimms] = useState('')
  const [tempGatepass, setTempGatepass] = useState({})


  const [count, setCount] = useState({ driver: null, vehicle: null })
  const [transporter, setTransporter] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [countDown, setCountDown] = useState(interval)
  const [start, setStart] = useState(false)
  const [closeGatepass, setCloseGatepass] = useState(false)


  async function handleDeleteGatepass(id) {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#407050",
        cancelButtonColor: "black",
        confirmButtonText: `Yes, Delete Gatepass`,
      }).then((result) => {
        if (result.isConfirmed) {
          API.put(apiName, `/gatepass/${id}`, { body: { ...gatepass, deleted: true, rail_exit_time: moment().unix(), active: false } })
            .then(() => {
              Swal.fire("", `Gatepass has been removed.`, "info");
              navigate("/gatepass");
            })
            .catch((e) => Swal.fire("Oops !", e.message, "error"));
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleUpdateGatepass() {
    try {
      setIsUpdating(true)
      setCountDown(interval)
      setStart(false)
      let startAt = gatepass?.rail_entry_time || null;
      let endAt = gatepass?.rail_exit_time || null;

      if (role?.includes("OUT_BARRIER")) {
        try {
          if (gatepass?.offline !== true) {
            const pattern = /^[A-Z]\d{8,9}\/\d+$/;
            const isMatch = pattern.test(gatepass?.challan_number);
            if (!isMatch) {
              setIsUpdating(false)
              return toast.error("Invalid JIMMS Challan No.!");
            }
            if (currentJimms !== gatepass?.challan_number) {
              let temp = await API.get(apiName, `/gatepass?challan_number=${gatepass?.challan_number}`)
              if (+temp.count !== 0) {
                setIsUpdating(false)
                return toast.error("JIMMS Challan No. already exists !");
              }
            }
          }

          let averyData = {}
          if (gatepass?.offline !== true) {
            let res = await API.graphql({
              query: updateAveryTripById,
              variables: { tripId: gatepass?.trip_id },
            });

            averyData = JSON.parse(JSON.parse(res.data.updateAveryTripById)?.body)[0];
            if (averyData?.TruckNo !== vehicle?.id) {
              setIsUpdating(false)
              return toast.error("Invalid Trip ID!")
            }
          }
          const istTimestamp = moment(averyData?.SecondWtDateTime).subtract(5, 'hours').subtract(30, 'minutes').tz('Asia/Kolkata').format('X');

          await API.put(apiName, `/gatepass/${gatepass?.id}`, { body: { ...gatepass, trip_id: averyData?.TripId || gatepass?.trip_id, mine_exit_time: moment().unix(), active: false, mine_weight: averyData?.SecondWeight || gatepass?.mine_weight, mine_tare_weight: averyData?.FirstWeight || gatepass?.mine_tare_weight, rail_tare_weight: averyData?.RailFirstWeight || gatepass?.rail_tare_weight, rail_weight: averyData?.RailSecondWeight || gatepass?.rail_weight, mine_exit_lane: user?.lane, challan_number: gatepass?.challan_number?.trim() || null, wb_exit_time: istTimestamp || moment().unix() } })

          setGatepass((prev) => ({
            ...prev,
            mine_tare_weight: averyData.FirstWeight || gatepass?.mine_tare_weight,
            mine_weight: averyData.SecondWeight || gatepass?.mine_weight,
            mine_exit_time: moment().unix(), wb_exit_time: istTimestamp || moment().unix()
          }));


          await API.put(apiName, `/vehicle/${vehicle?.id}`, { body: { ...vehicle, loaded: true } })
          await API.post(apiName, `/gatepass/zk/remote-access?door=${user?.lane}&type=Open&interval=${interval}`)
          setStart(true)
        } catch (error) {
          console.log(error);
          return;
        }
      }

      if (role?.includes("RAILWAY_SIDING_ENTRY")) {
        if (gatepass?.mine_exit_time === null) {
          setIsUpdating(false)
          return toast.error("Gate Pass is not closed yet !");
        }
        startAt = moment().unix();
        await API.put(apiName, `/gatepass/${gatepass?.id}`, { body: { ...gatepass, rail_entry_time: startAt } })
      }

      if (role?.includes("RAILWAY_SIDING_EXIT")) {
        if (gatepass?.rail_entry_time === null) {
          setIsUpdating(false)
          return toast.error("Railway Siding In Time is Empty !");
        }

        let payload = { ...vehicle, loaded: false }
        if (gatepass?.rail_weight === null || gatepass?.rail_weight === undefined) payload = { ...payload, suspicious: true }

        await API.put(apiName, `/vehicle/${vehicle?.id}`, { body: payload })
        endAt = moment().unix();
        await API.put(apiName, `/gatepass/${gatepass?.id}`, { body: { ...gatepass, rail_exit_time: endAt, duraiton: endAt - gatepass?.mine_entry_time } })

        Swal.fire(
          'Trip is closed.',
          `Ref No : ${endAt}`,
          'success'
        ).then(() => navigate("/gatepass/create"))
      }

      setGatepass((prev) => ({
        ...prev,
        rail_entry_time: startAt,
        rail_exit_time: endAt,
      }));
      toast.success("Gatepass updated successfully.");
    } catch (error) {
      console.log(error);
    } finally { setIsUpdating(false) }
  }

  async function getData() {
    try {
      let res = await API.get(apiName, `/gatepass/${id}`)
      let resVehicle = await API.get(apiName, `/vehicle/${res?.vehicle_id}`)
      let resDriver = null;
      if (res?.driver_id) {
        resDriver = await API.get(apiName, `/driver/${res?.driver_id}`)
      }
      let resTransporter = await API.get(apiName, `/transporter/${res?.transporter_id}`)

      let data = res;

      let count = await API.get(apiName, `/gatepass?vehicle_id=${res?.vehicle_id}&start=${moment().startOf("day").unix()}&end=${moment().endOf("day").unix()}&deleted=${false}`)

      setCount((prev) => ({ ...prev, vehicle: count?.count }))

      let dCount = null;
      if (res.driver_id) {
        dCount = await API.get(apiName, `/gatepass?driver_id=${res?.driver_id}&start=${moment().startOf("day").unix()}&end=${moment().endOf("day").unix()}`)
      }
      setCount((prev) => ({ ...prev, driver: dCount?.count }))

      if (data?.trip_id !== null && data.offline !== true) {
        let res = await API.graphql({
          query: updateAveryTripById,
          variables: { tripId: data?.trip_id },
        });
        const averyData = JSON.parse(JSON.parse(res.data.updateAveryTripById)?.body)[0];
        console.log(averyData)
        const istTimestamp = moment(averyData?.SecondWtDateTime).subtract(5, 'hours').subtract(30, 'minutes').tz('Asia/Kolkata').format('X');

        data = { ...data, trip_id: averyData?.TripId, mine_weight: averyData?.SecondWeight, mine_tare_weight: averyData?.FirstWeight, rail_tare_weight: averyData?.RailFirstWeight, rail_weight: averyData?.RailSecondWeight, wb_exit_time: istTimestamp }

        await API.put(apiName, `/gatepass/${data?.id}`, { body: data })

        setGatepass(data);
        setTempGatepass(data)
        setCurrentJimms(data?.challan_number)

        // update gatepass on pagelod in railway-in
        if (role?.includes("RAILWAY_SIDING_ENTRY") && !role?.includes("USERS") && data?.rail_entry_time === null) {
          if (data?.mine_exit_time === null) return toast.error("Gate Pass is not closed yet !");
          await API.put(apiName, `/gatepass/${data?.id}`, { body: { ...data, rail_entry_time: moment().unix() } })
          setGatepass({ ...data, rail_entry_time: moment().unix() })

          toast.success("Gatepass updated successfully.")
        }

        //update gatepass on railway-out 
        if (role?.includes("RAILWAY_SIDING_EXIT") && !role?.includes("USERS") && data?.rail_exit_time === null) {
          if (data.rail_entry_time === null) return toast.error("Railway Siding In Time is Empty !");

          let payload = { ...resVehicle, loaded: false }
          if (data?.rail_weight === null || data?.rail_weight === undefined) payload = { ...payload, suspicious: true }

          await API.put(apiName, `/vehicle/${resVehicle?.id}`, { body: payload })
          let endAt = moment().unix();
          await API.put(apiName, `/gatepass/${data?.id}`, { body: { ...data, rail_exit_time: endAt, duration: endAt - data?.mine_entry_time } })
          setGatepass({ ...data, rail_exit_time: endAt })

          if (data.offline !== true) {
            Swal.fire(
              'Trip is closed.',
              `Ref No : ${endAt}`,
              'success'
            )
          }
        }
      } else setGatepass(res);

      setLoading(false);

      setVehicle(resVehicle);
      setDriver(resDriver);
      setTransporter(resTransporter)

    } catch (error) {
      console.log(error);
    } finally { setLoading(false) }
  }

  async function makeOffline() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Gatepass will change to offline mode!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#407050',
      cancelButtonColor: 'black',
      confirmButtonText: 'Make it Offline',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await API.put(apiName, `/gatepass/${gatepass?.id}`, { body: { ...gatepass, offline: true } })
          toast.success("Gatepass is offline now");
          setGatepass({ ...gatepass, offline: true });
        } catch (error) {
          toast.error("Failed to update gatepass");
          console.log(error);
        }
      }
    });
  }

  async function remote(type) {
    try {
      await API.post(apiName, `/gatepass/zk/remote-access?door=${user?.lane}&type=${type}&interval=${interval}`)
      if (type === 'Open') { setCountDown(interval); setStart(true); }
      else setCountDown(0)
      toast.success(`Gate is ${type}.`)
    } catch (error) {
      console.log(error)
    }
  }

  async function masterCloseGatepass() {
    try {
      await API.put(apiName, `/vehicle/${vehicle?.id}`, { body: { ...vehicle, loaded: false } })
      toast.success("Gatepass is closed.")
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong !')
    }
  }

  useEffect(() => {
    if (role) getData();
  }, [role]);

  let inter;
  useEffect(() => {
    if (start) {
      inter = setInterval(() => {
        setCountDown((prev) => prev - 1)
      }, 1000);
    }
    return () => clearInterval(inter)
  }, [start])

  useEffect(() => {
    if (countDown === 0) setStart(false)
  }, [countDown])

  if (loading) return <Spinner show={true} />;

  return (
    <div className="row">
      <div className="bg-white col-3 rounded-4 d-flex flex-column gap-3 p-4" style={{ height: 'fit-content' }}>
        <Header name={`Gatepass ${gatepass?.vehicle_id} - ${user?.lane}`} />
        {!role?.includes("USERS") && (
          <div>
            <form
              className="my-2  d-flex flex-column gap-3"
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateGatepass();
              }}
            >
              {role?.includes("OUT_BARRIER") && (
                <>
                  <div>
                    <label className="form-label mx-1">
                      JIMMS Challan Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="Enter JIMMS Challan Number"
                      required={gatepass?.offline !== true}
                      value={gatepass?.challan_number || ""}
                      onChange={(e) => setGatepass((prev) => ({ ...prev, challan_number: e.target.value }))}
                    />
                  </div>
                  <div>
                    <label className="form-label mx-1">
                      Trip ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control w-100"
                      placeholder="Enter Trip ID"
                      required
                      value={gatepass?.trip_id || ""}
                      minLength={6}
                      onChange={(e) => setGatepass((prev) => ({ ...prev, trip_id: e.target.value }))}
                    />
                  </div>
                  {gatepass.offline && (
                    <>
                      <div>
                        <label className="form-label mx-1">
                          G.W. at Coal Mine <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          placeholder="Enter G.W. at Coal Mine"
                          value={gatepass?.mine_weight || ""}
                          type="number"
                          min={10000}
                          max={99999}
                          required
                          onChange={(e) =>
                            e.target.value.length <= 5 &&
                            setGatepass((prev) => ({ ...prev, mine_weight: e.target.value }))
                          }
                        />
                      </div>
                      <div>
                        <label className="form-label mx-1">
                          T.W. at Coal Mine <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          placeholder="Enter T.W. at Coal Mine"
                          value={gatepass?.mine_tare_weight || ""}
                          type="number"
                          min={10000}
                          max={99999}
                          required
                          onChange={(e) =>
                            e.target.value.length <= 5 &&
                            setGatepass((prev) => ({ ...prev, mine_tare_weight: e.target.value }))
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}

              {role?.includes("RAILWAY_SIDING_ENTRY") && (
                <div>
                  <label className="form-label mx-1">
                    Gross Weight <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={10000}
                    max={99999}
                    className="form-control"
                    placeholder="Enter Gross Weight"
                    required
                    value={gatepass?.rail_weight || ""}
                    onChange={(e) =>
                      e.target.value.length <= 5 &&
                      setGatepass((prev) => ({ ...prev, rail_weight: e.target.value }))
                    }
                  />
                </div>
              )}
              {role?.includes("RAILWAY_SIDING_EXIT") && (
                <div>
                  <label className="form-label mx-1">
                    Tare Weight <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1000}
                    max={99999}
                    className="form-control"
                    placeholder="Enter Tare Weight"
                    required
                    value={gatepass?.rail_tare_weight || ""}
                    onChange={(e) =>
                      e.target.value.length <= 5 &&
                      setGatepass((prev) => ({ ...prev, rail_tare_weight: e.target.value }))
                    }
                  />
                </div>
              )}
              <button
                className="col-auto btn btn-primary"
                type="submit"
                disabled={isUpdating}
              >
                {isUpdating ? (
                  <div
                    className="spinner-border text-white"
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                    role="status "
                  ></div>
                ) : (
                  <span>Update</span>
                )}
              </button>
            </form>
          </div>
        )}
        <div className="col-12 ms-auto">
          <div className="d-flex gap-3 align-items-center flex-column ">
            <>
              {role && (role?.includes("USERS") || (role?.includes("CONTROL_TOWER") && gatepass?.rail_exit_time === null)) && (
                <>
                  <button
                    className="btn btn-white w-100 d-flex justify-content-center"
                    type="button"
                    onClick={() => setShowEditModal(true)}>
                    Edit  <i className="fe fe-edit ms-1"></i>
                  </button>
                </>
              )}
              {role && (role?.includes("USERS") && (
                <button
                  className="btn btn-danger w-100 d-flex justify-content-center"
                  type="button"
                  onClick={() => handleDeleteGatepass(id)}>
                  Delete  <i className="fe fe-trash-2 ms-1"></i>
                </button>
              ))}
              {/* {(role?.includes("USERS") ||
                            role?.includes("RAILWAY_SIDING_EXIT")) && ( */}
              <button
                className="btn btn-primary d-flex justify-content-center w-100"
                type="button"
                onClick={() => {
                  setPrinting(true)
                  setTimeout(() => {
                    window.print();
                    setPrinting(false)
                  }, 10);
                }}
              >
                Print <i className="fe fe-printer ms-1"></i>
              </button>
              {/* )} */}
              {(role?.includes("USERS") ||
                role?.includes("GATEPASS_OFFLINE")) && (
                  <button
                    className={`btn ${gatepass?.offline ? "btn-primary" : "btn-dark"} w-100 d-flex justify-content-center`}
                    type="button"
                    disabled={gatepass?.offline}
                    onClick={makeOffline}>Offline <i className="fe fe-wifi-off ms-1"></i></button>
                )}
            </>
            <button
              className="btn btn-light w-100 d-flex justify-content-center"
              type="button"
              onClick={() => {
                if (role?.includes("OUT_BARRIER")) {
                  gatepass?.exitAt === null &&
                    toast.error("Kindly Close the Gate Pass !");
                  gatepass?.exitAt !== null &&
                    navigate("/gatepass/create");
                  return;
                }
                navigate("/gatepass/create");
              }}>Clear <i className="fe fe-x ms-1"></i></button>
            {(role?.includes("IN_BARRIER") || role?.includes("OUT_BARRIER")) && (
              <div className="d-flex w-100 gap-2 remote_btns">
                <button className="btn btn-primary d-flex justify-content-center w-100" onClick={() => remote("Open")}><i className="fe fe-chevrons-up" />Open Gate</button>
                <button className="btn btn-danger d-flex justify-content-center w-100" onClick={() => remote("Close")}><i className="fe fe-chevrons-down" /> Close Gate</button>
              </div>)}
          </div>
        </div>
      </div>
      <div className="col-9 px-5">
        <div key={new Date().getSeconds()} className="gatepass_container bg-white  mb-5 p-4 rounded-4" style={{ backgroundImage: 'url("/img/truck-watermark-2.png")', backgroundPosition: "center", backgroundRepeat: 'no-repeat' }}>
          <div className={`row px-5 py-2`}>
            <div className="col-3">
              <img src="/img/logo.svg" alt="logo" height={60} />
            </div>
            <div className="col-6 text-center">
              <h1 className="mb-2">
                <u>Loading Slip & Gate Pass</u>
              </h1>
              <h3 className="mb-2">WBPDCL (A Govt Company)</h3>
              <h3>Pachhwara North Coal Mine</h3>
              {gatepass?.offline && <h3 className="mt-2 text-danger">OFFLINE</h3>}
            </div>
            <div className="col-3">
              {gatepass?.trip_id !== "" && (
                <h2 className="me-4 my-0 text-end">*DUPLICATE</h2>
              )}
              {id !== null && (
                <div className="d-flex justify-content-end">
                  <Barcode
                    value={gatepass?.rail_exit_time ? vehicle?.id : id.toUpperCase()}
                    height={60}
                  />
                </div>
              )}
            </div>
          </div>
          <table className={`table ${printing ? "table-bordered table-sm" : ""}  w-100`}>
            <tbody>
              <tr>
                <td>S. No</td>
                <th>{id}</th>
                <td colSpan={2} className="text-center fs-2">
                  <strong>{transporter?.name}</strong>
                </td>
              </tr>
              <tr>
                <td>Trip ID </td>
                <th>{gatepass?.trip_id}</th>
                <td rowSpan={2} className="text-center fs-1">
                  <strong>{gatepass?.vehicle_id}</strong>
                </td>
                <td rowSpan={2} className="text-center fs-1">
                  <strong style={{ fontWeight: "700" }}>
                    {gatepass?.route_name}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>Work Order No.</td>
                <th>
                  {transporter &&
                    transporter?.work_order?.find(
                      (item) =>
                        item?.route?.toLowerCase() ===
                        vehicle?.route_name?.toLowerCase()
                    )?.number}
                </th>
              </tr>
              <tr>
                <td>RFID No. / RCD Pin</td>
                <th>{vehicle?.rfid} / {vehicle?.rcd_pin}</th>
                <td className="text-start">Today's Trip Count</td>
                <th>{count?.vehicle && +count?.vehicle}</th>
              </tr>
              <tr>
                <td>Driver's Name</td>
                <th style={{ textTransform: "uppercase" }}>
                  {driver?.name} {driver?.id && `(${driver?.id})`}
                </th>
                <td>Driver's age</td>
                <th className="text-start">
                  {driver?.dob &&
                    moment().diff(moment.unix(driver?.dob), "years") + " Years"}
                </th>
              </tr>
              <tr>
                <td>Driver's Licence No.</td>
                <th>{driver?.licence_number}</th>
                <td>Validity Till </td>
                <th>
                  {driver?.licence_expiry &&
                    moment.unix(driver?.licence_expiry).format("DD-MM-YYYY")}
                </th>
              </tr>
              <tr>
                <td>Mobile No. </td>
                <th> {driver?.phone}</th>
                <td>Driver's Today's Trip No. </td>
                <th>{count?.driver !== null ? count?.driver : "-"}</th>
              </tr>
              <tr>
                <td className="w-25">Coal Stock Yard In Time</td>
                <th className="w-25">
                  {gatepass?.mine_entry_time &&
                    moment(gatepass?.mine_entry_time * 1000).format(
                      "DD-MM-YYYY[, ]hh:mm A"
                    )}
                </th>
                <td>Coal Stock Yard Out Time</td>
                <th>
                  {gatepass?.mine_exit_time &&
                    moment.unix(gatepass?.mine_exit_time).format(
                      "DD-MM-YYYY[, ]hh:mm A"
                    )}
                </th>
              </tr>
              <tr>
                <td>JIMMS Challan No. </td>
                <th> {gatepass?.challan_number}</th>
                <td>Weigh Bridge In Time </td>
                <th>
                  {gatepass?.wb_exit_time &&
                    moment.unix(gatepass?.wb_exit_time).format("DD-MM-YYYY[, ]hh:mm A")}
                </th>
              </tr>
              <tr>
                <td>T.W. at Coal Mine </td>
                <th>
                  {gatepass?.mine_tare_weight &&
                    (gatepass?.mine_tare_weight / 1000)?.toFixed(3)}
                </th>
                <td>G.W. at Coal Mine </td>
                <th>
                  {gatepass?.mine_weight &&
                    (gatepass?.mine_weight / 1000)?.toFixed(3)}
                </th>
              </tr>
              <tr>
                <td className="w-25">Railway Siding In Time</td>
                <th className="w-25">
                  {gatepass?.rail_entry_time &&
                    moment.unix(gatepass?.rail_entry_time).format(
                      "DD-MM-YYYY[, ]hh:mm A"
                    )}
                </th>
                <td className="w-25">G.W. at Railway Siding </td>
                <th className="w-25">
                  {gatepass?.rail_weight && (gatepass?.rail_weight / 1000)?.toFixed(3)}
                </th>
              </tr>
              <tr>
                <td>Railway Siding Out Time</td>
                <th>
                  {gatepass?.rail_exit_time &&
                    moment.unix(gatepass?.rail_exit_time).format(
                      "DD-MM-YYYY[, ]hh:mm A"
                    )}
                </th>
                <td>T.W. at Railway Siding </td>
                <th>
                  {gatepass?.rail_tare_weight
                    ? (gatepass?.rail_tare_weight / 1000)?.toFixed(3)
                    : (gatepass?.mine_tare_weight / 1000)?.toFixed(3)}
                </th>
              </tr>
              <tr>
                <td>Mine Entry Lane</td>
                <th>{gatepass?.mine_entry_lane || "-"}</th>
                <td>Mine Exit Lane</td>
                <th>{gatepass?.mine_exit_lane || "-"}</th>
              </tr>
            </tbody>
          </table>
          <div className="row px-3 py-2 small text-center mt-2">
            <div className="col">
              <hr />
              Coal Yard Supervisor Sign
            </div>
            <div className="col">
              <hr />
              In Gate Security Supervisor
            </div>
            <div className="col">
              <hr />
              Weighment Clerk Sign
            </div>
            <div className="col">
              <hr />
              Out Gate Security Supervisor
            </div>
          </div>
        </div>
      </div>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <form onSubmit={async (e) => {
          e.preventDefault()
          try {
            await API.put(apiName, `/gatepass/${id}`, { body: { ...gatepass, active: false } })
            if (closeGatepass) {
              if (!gatepass?.mine_exit_time || !gatepass?.rail_entry_time) return toast.error("Please fill all the details before closing gatepass !")
              await masterCloseGatepass()
            }
            else toast.success("Gatepass updated.")
            setShowEditModal(false)
          } catch (error) {
            toast.error('Something went wrong !')
          }
        }}>
          <Modal.Header>
            <Modal.Title><h2 className="m-0">Edit Gatepass</h2></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-6">
                <label className="my-2">JIMMS Challan No.</label>
                <input type="text" className="form-control" placeholder="Enter Challan Number" value={gatepass?.challan_number || null} onChange={(e) => setGatepass((prev) => ({ ...prev, challan_number: e.target.value }))} />
              </div>
              <div className="col-6">
                <label className="my-2">Weigh Bridge In Time</label>
                <div>
                  {(role?.includes("CONTROL_TOWER") && !role.includes("USERS")) ? <button type="button" className="btn btn-light w-100" onClick={() => setGatepass((prev) => ({ ...prev, wb_exit_time: moment().unix() }))}>Update</button> : <input type="datetime-local" className="form-control" value={moment.unix(gatepass?.wb_exit_time || moment().unix()).format("YYYY-MM-DDTHH:mm")} onChange={(e) => setGatepass((prev) => ({ ...prev, wb_exit_time: moment(e.target.value).unix() }))} />}
                </div>
              </div>
              <div className="col-6">
                <label className="my-2">Trip ID</label>
                <input type="text" className="form-control" placeholder="Enter Challan Number" value={gatepass?.trip_id || null} onChange={(e) => setGatepass((prev) => ({ ...prev, trip_id: e.target.value }))} />
              </div>
              <div className="col-6">
                <label className="my-2">Coal Stock Yard Out Time</label>
                <div>
                  {(role?.includes("CONTROL_TOWER") && !role.includes("USERS")) ? <button type="button" className="btn btn-light w-100" onClick={() => setGatepass((prev) => ({ ...prev, mine_exit_time: moment().unix() }))}>Update</button> : <input type="datetime-local" className="form-control" value={moment.unix(gatepass?.mine_exit_time || moment().unix()).format("YYYY-MM-DDTHH:mm")} onChange={(e) => setGatepass((prev) => ({ ...prev, mine_exit_time: moment(e.target.value).unix() }))} />}
                </div>
              </div>
              <div className="col-6">
                <label className="my-2">Railway Siding In Time</label>
                <div>
                  {(role?.includes("CONTROL_TOWER") && !role.includes("USERS")) ? <button type="button" className="btn btn-light w-100" onClick={() => setGatepass((prev) => ({ ...prev, rail_entry_time: moment().unix() }))}>Update</button> : <input type="datetime-local" className="form-control" value={moment.unix(gatepass?.rail_entry_time || moment().unix()).format("YYYY-MM-DDTHH:mm")} onChange={(e) => setGatepass((prev) => ({ ...prev, rail_entry_time: moment(e.target.value).unix() }))} />}
                </div>
              </div>
              <div className="col-6">
                <label className="my-2">Railway Siding Out Time</label>
                <div>
                  {(role?.includes("CONTROL_TOWER") && !role.includes("USERS")) ? <button type="button" className="btn btn-light w-100" onClick={() => setGatepass((prev) => ({ ...prev, rail_exit_time: moment().unix() }))}>Update</button> : <input type="datetime-local" className="form-control" value={moment.unix(gatepass?.rail_exit_time || moment().unix()).format("YYYY-MM-DDTHH:mm")} onChange={(e) => setGatepass((prev) => ({ ...prev, rail_exit_time: moment(e.target.value).unix() }))} />}
                </div>
              </div>
              <div className="col-6">
                <label className="my-2">G.W. at Coal Mine</label>
                <input min={10000} max={99999} type="number" className="form-control" placeholder="Enter G.W at Coal Mine" value={gatepass?.mine_weight || ""}
                  onChange={(e) => setGatepass((prev) => ({ ...prev, mine_weight: e.target.value }))}
                />
              </div>
              <div className="col-6">
                <label className="my-2">G.W. at Railway Siding</label>
                <input min={10000} max={99999} type="number" className="form-control" placeholder="Enter G.W. at Railway Siding" value={gatepass?.rail_weight || ""}
                  onChange={(e) => setGatepass((prev) => ({ ...prev, rail_weight: e.target.value }))} />
              </div>
              <div className="col-6">
                <label className="my-2">T.W. at Coal Mine</label>
                <input min={10000} max={99999} type="number" className="form-control" placeholder="Enter T.W at Coal Mine" value={gatepass?.mine_tare_weight || ""}
                  onChange={(e) => setGatepass((prev) => ({ ...prev, mine_tare_weight: e.target.value }))}
                />
              </div>
              <div className="col-6">
                <label className="my-2">T.W. at Railway Siding</label>
                <input min={10000} max={99999} type="number" className="form-control" placeholder="Enter T.W. at Railway Siding" value={gatepass?.rail_tare_weight || ""}
                  onChange={(e) => setGatepass((prev) => ({ ...prev, rail_tare_weight: e.target.value }))} />
              </div>
            </div>
            <div className="form-check mt-3">
              <input className="form-check-input" type="checkbox" value={closeGatepass} id="close_gatepass" onChange={(e) => setCloseGatepass(e.target.checked)} />
              <label className="form-check-label" htmlFor="close_gatepass">
                Close Gatepass
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowEditModal(false); setGatepass(tempGatepass) }}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              <b>Save</b>
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {start && (
        <div className="text-center timer bg-danger position-absolute end-0 rounded p-2 text-white" style={{ width: '10rem' }}>
          <p className="m-0">Gate will close in</p>
          <h1 className="m-0 display-4" >{countDown} sec</h1>
        </div>
      )}
    </div>
  );
}

const updateAveryTripById = /* GraphQL */ `
  mutation UpdateAveryTripById($tripId: ID!) {
    updateAveryTripById(tripId: $tripId)
  }
`;
