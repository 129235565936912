import moment from 'moment';
import ReportCard from '../../components/ReportCard';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import NoAccess from '../../components/NoAccess';

export default function VehicleMasterReport() {
    const [data, setData] = useState([])

    function formatData(data) {
        const res = data.map((vehicle, idx) => {
            let report = {
                "SL No": idx + 1,
                "Vehicle In": moment.unix(vehicle?.created).format("DD-MMM-YYYY"),
                "Vehicle No.": vehicle?.id,
                "Type": vehicle?.type_name,
                "Transporter Name": vehicle?.transporter_name,
                "Recomended by": vehicle?.recomended_by,
                "Route": vehicle?.route_name,
                "Model": vehicle?.model,
                "Make": vehicle?.make,
                RFID: vehicle?.rfid,
                RCD_Pin: vehicle?.rcd_pin,
                "GPS Number": vehicle?.gps_number,
                "Issue Date": vehicle?.gps_updated_at,
                "Type of GPS": vehicle?.gps_type,
                "Tare Weight": vehicle?.weight ? +vehicle?.weight / 1000 : '',
                "Chassis No.": vehicle?.details?.chassis,
                "Engine": vehicle?.details?.engine,
                "Tyres": vehicle?.details?.noOfTyres
            };
            return report
        })
        setData(res)
        return res
    }

    const roles = useSelector((state) => state.userInfo.payload?.role)
    if (!roles?.includes("VEHICLE-MASTER")) {
        return <NoAccess />
    }

    return (
        <>
            <ReportCard title={"Vehicle Master Report"} formatData={formatData} api={'vehicle-master-report'} />
            <div className="table-responsive card">
                <table className="table-hover table-bordered">
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                        <tr>
                            {data?.length > 0 && Object.keys(data[0])?.map(item => (
                                <th className='fs-5 text-center' key={item}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, idx) => (
                            <tr key={idx}>
                                <td align='center' className='fs-5'>{item["SL No"]}</td>
                                <td align='center' className='fs-5'>{item["Vehicle In"]}</td>
                                <td align='center' className='fs-5'>{item["Vehicle No."]}</td>
                                <td align='center' className='fs-5'>{item["Type"]}</td>
                                <td align='center' className='fs-5'>{item["Transporter Name"]}</td>
                                <td align='center' className='fs-5'>{item["Recomended by"]}</td>
                                <td align='center' className='fs-5'>{item["Route"]}</td>
                                <td align='center' className='fs-5'>{item["Model"]}</td>
                                <td align='center' className='fs-5'>{item["Make"]}</td>
                                <td align='center' className='fs-5'>{item["RFID"]}</td>
                                <td align='center' className='fs-5'>{item["RCD_Pin"]}</td>
                                <td align='center' className='fs-5'>{item["GPS Number"]}</td>
                                <td align='center' className='fs-5'>{item["Issue Date"]}</td>
                                <td align='center' className='fs-5'>{item["Type of GPS"]}</td>
                                <td align='center' className='fs-5'>{item["Tare Weight"]}</td>
                                <td align='center' className='fs-5'>{item["Chassis No."]}</td>
                                <td align='center' className='fs-5'>{item["Engine"]}</td>
                                <td align='center' className='fs-5'>{item["Tyres"]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}