import { Route, Routes } from "react-router-dom";

import CheckRole from "./pages/auth/CheckRole";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Error from "./pages/other/NoPageFound";
import Pending from "./pages/other/Pending";

import { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";
import Wrapper from "./layouts/Wrapper";
import Drivers from "./pages/driver/Drivers";
import GatepassList from "./pages/gate-pass/GatePassList";
import AddTransporter from "./pages/transporter/AddTransporter";
import Transporters from "./pages/transporter/TransporterList";
import Users from "./pages/users/Users";
import VehicleList from "./pages/vehicles/VehicleList";

import "@aws-amplify/ui-react/styles.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import LiveTracker from "./pages/LiveTracker.jsx";
import AddDriver from "./pages/driver/AddDriver";
import GatepassCreate from "./pages/gate-pass/GatePassCreate";
import GatePassUpdate from "./pages/gate-pass/GatePassUpdate.jsx";
import DRP from "./pages/reports/DRP.jsx";
import Dispatch from "./pages/reports/Dispatch.jsx";
import TransporterMaster from "./pages/reports/TransporterMasterReport.jsx";
import TransporterPerformance from "./pages/reports/TransporterPerformance.jsx";
import VehicleAttendanceReport from "./pages/reports/VehicleAttendanceReport.jsx";
import VehicleMasterReport from "./pages/reports/VehicleMasterReport.jsx";
import VehiclePerformance from "./pages/reports/VehiclePerformance.jsx";
import UpdateUser from "./pages/users/UpdateUser.jsx";
import AddVehicle from "./pages/vehicles/AddVehicle.jsx";
import Exchange from "./pages/vehicles/Exchange.jsx";
import RemoveVehicle from "./pages/vehicles/RemoveVehicle.jsx";
import RequestDetails from "./pages/vehicles/RequestDetails.jsx";
import RequestList from "./pages/vehicles/RequestList.jsx";
import Transfer from "./pages/vehicles/Transfer.jsx";

import Settings from "./pages/Settings.jsx";
import DailyDispatch from "./pages/dashboard/DailyDispatch.jsx";
import EntryPerformance from "./pages/dashboard/EntryPerformance.jsx";
import ExecutiveDashboard from "./pages/dashboard/ExecutiveDashboard.jsx";
import TrafficDashboard from "./pages/dashboard/TrafficDashboard.jsx";
import TransporterPerformanceDash from "./pages/dashboard/TransporterPerformanceDash.jsx";
import VehiclePerformanceDash from "./pages/dashboard/VehiclePerformanceDash.jsx";
import DeviatonReport from "./pages/reports/DeviationReport.jsx";
import EntryReport from "./pages/reports/Entry.jsx";
import ExitReport from "./pages/reports/Exit.jsx";
import HaltedReport from "./pages/reports/HaltedReport.jsx";
import IdleReport from "./pages/reports/IdleReport.jsx";
import Ignition from "./pages/reports/Ignition.jsx";
import Offline from "./pages/reports/Offline.jsx";
import Ping from "./pages/reports/Ping.jsx";
import SecurityVehicle from "./pages/reports/SecurityVehicle.jsx";
import ExitPerformance from "./pages/dashboard/ExitPerformance.jsx";
import Speeding from "./pages/reports/Speeding.jsx";
import Suspicious from "./pages/reports/Suspicious.jsx";
import LoginHistory from "./pages/LoginHistory.jsx";
import Distance from "./pages/reports/Distance.jsx";
import MismatchList from "./pages/gate-pass/MismatchList.jsx";
import PrivacyPolicy from "./pages/other/PrivacyPolicy.jsx";
import Brakedown from "./pages/reports/Brakedown.jsx";
import Hindrance from "./pages/reports/Hindrance.jsx";

Amplify.configure(awsmobile)
function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Login />}></Route>
      <Route exact path="/signup" element={<Signup />}></Route>
      <Route path="/wait" element={<Pending />} />
      <Route path="/check-permission" element={<CheckRole />} />
      <Route exact path="/tracker" element={<LiveTracker />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />


      <Route path="*" element={<Error />} />
      <Route element={<Wrapper />}>
        <Route path="/dashboard">
          <Route exact path="traffic" element={<TrafficDashboard />} />
          <Route exact path="executive" element={<ExecutiveDashboard />} />
          <Route exact path="daily-dispatch" element={<DailyDispatch />} />
          <Route exact path="transporter-performance" element={<TransporterPerformanceDash />} />
          <Route exact path="vehicle-performance" element={<VehiclePerformanceDash />} />
        </Route>

        <Route path="/vehicle">
          <Route exact path="list" element={<VehicleList />} />
          <Route exact path="add" element={<AddVehicle />} />
          <Route exact path=":id" element={<AddVehicle />} />
          <Route exact path="exchange" element={<Exchange />} />
          <Route exact path="exchange/:id" element={<Exchange />} />
          <Route exact path="remove" element={<RemoveVehicle />} />
          <Route exact path="transfer" element={<Transfer />} />

          <Route exact path="request-list" element={<RequestList />} />
          <Route exact path="request/:id" element={<RequestDetails />} />
          <Route exact path="request/edit/:id" element={<AddVehicle />} />
        </Route>

        <Route exact path="/transporters" element={<Transporters />} />
        <Route exact path="/transporter/add" element={<AddTransporter />} />
        <Route exact path="/transporter/:id" element={<AddTransporter />} />

        <Route exact path="/gatepass" element={<GatepassList />} />
        <Route exact path="/gatepass/create" element={<GatepassCreate />} />
        <Route exact path="/gatepass/create-offline" element={<GatepassCreate />} />
        <Route exact path="/gatepass/:id" element={<GatePassUpdate />} />
        <Route exact path="/gatepass/list/mismatch" element={<MismatchList />} />

        <Route exact path="/drivers" element={<Drivers />} />
        <Route exact path="/driver/add" element={<AddDriver />} />
        <Route exact path="/driver/:id" element={<AddDriver />} />

        <Route exact path="/parameters" element={<Settings />} />
        <Route exact path="/login-history" element={<LoginHistory />} />

        <Route path="/report">
          <Route exact path="daily-performance-report" element={<DRP />} />
          <Route exact path="vehicle-performance-report" element={<VehiclePerformance />} />
          <Route exact path="transporter-performance-report" element={<TransporterPerformance />} />
          <Route exact path="transporter-master-report" element={<TransporterMaster />} />
          <Route exact path="dispatch-report" element={<Dispatch />} />
          <Route exact path="vehicle-master-report" element={<VehicleMasterReport />} />
          <Route exact path="vehicle-attendance-report" element={<VehicleAttendanceReport />} />
          <Route exact path="idle-report" element={<IdleReport />} />
          <Route exact path="halted-report" element={<HaltedReport />} />
          <Route exact path="deviation-report" element={<DeviatonReport />} />
          <Route exact path="speeding-report" element={<Speeding />} />
          <Route exact path="offline-report" element={<Offline />} />
          <Route exact path="suspicious-report" element={<Suspicious />} />
          <Route exact path="ignition-report" element={<Ignition />} />
          <Route exact path="ping-report" element={<Ping />} />
          <Route exact path="security-vehicle" element={<SecurityVehicle />} />
          <Route exact path="distance" element={<Distance />} />
          <Route exact path="breakdown" element={<Brakedown />} />
          <Route exact path="hindrance" element={<Hindrance />} />
        </Route>

        <Route exact path="/users" element={<Users />} />
        <Route exact path="/user/:id" element={<UpdateUser />} />
      </Route>

    </Routes>
  );
}

export default App;
