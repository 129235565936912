import React from 'react'

export default function PrivacyPolicy() {
    return (
        <div className='container my-5'>
            <h1>SHAReTrack Privacy Policy</h1>
            <p><strong>Introduction</strong></p>
            <p>SHAReTrack ("we", "our", "us") is committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our customized GPS app for coal transportation. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it. By using the app, you agree to the collection and use of information in accordance with this policy.</p>

            <h2>Information We Collect</h2>
            <p>We collect several types of information for various purposes to provide and improve our app:</p>

            <h3>Personal Data</h3>
            <p>While using our app, we may ask you to provide certain personally identifiable information, including but not limited to:</p>
            <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
            </ul>

            <h3>Location Data</h3>
            <p>To track vehicle locations and monitor routes, we collect precise location data from the device you use our app on. This includes:</p>
            <ul>
                <li>Real-time GPS location</li>
                <li>Route history</li>
            </ul>

            <h3>Vehicle Data</h3>
            <p>For fleet management, we collect data related to vehicles, such as:</p>
            <ul>
                <li>Vehicle identification numbers</li>
                <li>Load status (loaded or empty)</li>
                <li>Entry and exit times at coal yards</li>
            </ul>

            <h3>Usage Data</h3>
            <p>We may also collect information on how the app is accessed and used, including:</p>
            <ul>
                <li>App usage patterns</li>
                <li>Diagnostic data</li>
            </ul>

            <h2>How We Use Your Information</h2>
            <p>We use the collected data for various purposes, including:</p>
            <ul>
                <li>To provide and maintain our app</li>
                <li>To monitor vehicle locations and routes</li>
                <li>To detect and alert for route deviations</li>
                <li>To restrict unauthorized vehicle entry into coal yards</li>
                <li>To issue gate passes to authorized vehicles</li>
                <li>To generate detailed reports on trip history and coal deliveries</li>
                <li>To improve safety and efficiency in fleet management</li>
                <li>To communicate with users and provide customer support</li>
            </ul>

            <h2>Data Sharing and Disclosure</h2>
            <p>We do not sell or trade your personal data. We may share your information in the following situations:</p>
            <h3>With Service Providers</h3>
            <p>We may share your information with third-party service providers to help us operate, maintain, and improve our app.</p>
            <h3>For Legal Requirements</h3>
            <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</p>
            <h3>Business Transfers</h3>
            <p>In the event of a merger, acquisition, or sale of assets, your personal data may be transferred. We will provide notice before your personal data is transferred and becomes subject to a different Privacy Policy.</p>

            <h2>Data Security</h2>
            <p>We take reasonable measures to protect your personal data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.</p>

            <h2>Your Data Protection Rights</h2>
            <p>Depending on your location, you may have the following rights regarding your personal data:</p>
            <ul>
                <li><strong>Access:</strong> You can request access to your personal data.</li>
                <li><strong>Correction:</strong> You can request that we correct any inaccurate or incomplete data.</li>
                <li><strong>Deletion:</strong> You can request that we delete your personal data under certain conditions.</li>
                <li><strong>Restriction:</strong> You can request that we restrict the processing of your personal data.</li>
                <li><strong>Objection:</strong> You can object to the processing of your personal data.</li>
                <li><strong>Data Portability:</strong> You can request a copy of your personal data in a structured, commonly used, and machine-readable format.</li>
            </ul>
            <p>To exercise these rights, please contact us at the provided contact information.</p>

            <h2>Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We advise you to review this Privacy Policy periodically for any changes.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <p>Email: <a href="mailto:support@sharetrack.com">support@sharetrack.com</a></p>

            <p>By using the SHAReTrack app, you acknowledge that you have read and understood this Privacy Policy.</p>
        </div>
    )
}
