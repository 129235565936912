import moment from 'moment'
import React, { useState } from 'react'
import GpsReport from '../../components/GpsReport'
import { useSelector } from 'react-redux'
import NoAccess from '../../components/NoAccess'

export default function Suspicious() {
  const [data, setData] = useState([])

  const formatData = (x) => {
    const res = x.map(item => {
      let report = {
          "Gatepass":item?.id,
          "Vehicle" : item?.vehicle_id,
          "Transporter" : item?.transporter,
          "Driver" : item?.driver,
          "Route" : item?.route_name,
          "Challan No." : item?.challan_number,
          "WB EXIT" : moment.unix(item?.wb_exit_time).format("DD-MMM-YY, hh:mm A"),
          "Trip" : item?.trip_id,
      };
      return report
  })
    setData(x)
    return res
  }

  const roles = useSelector((state)=>state.userInfo.payload?.role)
  if(!roles?.includes("SUSPICIOUS")) return <NoAccess/>


  return (
    <div>
      <GpsReport title={'Suspicious Report'} api={'suspicious'} formatData={formatData} />
      <div className="card">
        <table className='table-hover table-bordered'>
          <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
            <tr>
              <th className="fs-5 text-center">Gatepass</th>
              <th className="fs-5 text-center">Trip</th> 
              <th className="fs-5 text-center">Vehicle</th>
              <th className="fs-5 text-center">Challan No.</th> 
              <th className="fs-5">Transporter</th>
              <th className="fs-5">Driver</th>
              <th className="fs-5 text-center">Route</th> 
              <th className="fs-5 text-center">WB EXIT</th> 
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <tr key={idx}>
                <td align="center">{item?.id}</td>
                <td align="center">{item?.trip_id}</td>
                <td align="center">{item?.vehicle_id}</td>
                <td align="center">{item?.challan_number}</td>
                <td>{item?.transporter}</td>
                <td>{item?.driver}</td>
                <td align="center">{item?.route_name}</td>
                <td align="center">{moment.unix(item?.wb_exit_time).format("DD-MMM-YY, hh:mm A")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
      </div>
    </div>
  )
}
