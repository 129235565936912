import { API } from "aws-amplify";
import html2canvas from "html2canvas";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import NoAccess from "../../components/NoAccess";
import {
  apiName,
  getTime,
  handleSearchRoute,
  handleSearchTransporter,
  handleSearchVehicles,
  selectColor,
} from "../../components/Utils";

export default function Dispatch() {
  const screenShotBtnRef = useRef(null);
  const captureRef = useRef(null);

  const range = {
    start: moment().startOf("day").unix(),
    end: moment().endOf("day").unix(),
  };
  const [dateRange, setDateRange] = useState(range);
  const [vehicle, setVehicle] = useState(null);
  const [transporter, setTransporter] = useState(null);
  const [route, setRoute] = useState(null);
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState(Math.random());

  const [tripsTonData, setTripsTonData] = useState([]);
  const [avgData, setAvgData] = useState([]);
  const [tripDuration, setTripDuration] = useState({ min: [], max: [] });
  const [shortageData, setShortageData] = useState([]);

  const handleDateApplied = (event, picker) => {
    const fromDate = moment(picker.startDate).startOf("day").unix();
    const toDate = moment(picker.endDate).endOf("day").unix();
    setDateRange({ start: fromDate, end: toDate });
  };

  const fetchData = async () => {
    setLoading(true);
    const loading = toast.loading("Loading...");
    try {
      let trips_query = `/report/trips-and-tonnage?start=${dateRange?.start}&end=${dateRange?.end}`;
      let trips_query_transit = `/report/trips-and-tonnage-intransit?start=${dateRange?.start}&end=${dateRange?.end}`;
      let trips_query_delivered = `/report/trips-and-tonnage-delivered?start=${dateRange?.start}&end=${dateRange?.end}`;
      let avg_query = `/report/average?start=${dateRange?.start}&end=${dateRange?.end}`;
      let avg_query_start = `/report/average_start?start=${dateRange?.start}&end=${dateRange?.end}`;
      let max_duration_query = `/report/trip-duration?start=${dateRange?.start}&end=${dateRange?.end}&direction=DESC`;
      let min_duration_query = `/report/trip-duration?start=${dateRange?.start}&end=${dateRange?.end}&direction=ASC`;
      let shortage_0_15 = `/report/shortage-per-tons/?start=${dateRange?.start
        }&end=${dateRange?.end}&start_weight=${0}&end_weight=${1.5}`;
      let shortage_15_3 = `/report/shortage-per-tons/?start=${dateRange?.start
        }&end=${dateRange?.end}&start_weight=${1.5}&end_weight=${3}`;
      let shortage_3_5 = `/report/shortage-per-tons/?start=${dateRange?.start
        }&end=${dateRange?.end}&start_weight=${3}&end_weight=${5}`;
      let shortage_5 = `/report/shortage-per-tons/?start=${dateRange?.start
        }&end=${dateRange?.end}&start_weight=${5}&end_weight=${999999999999}`;

      let queries = [
        trips_query,
        avg_query,
        max_duration_query,
        min_duration_query,
        shortage_0_15,
        shortage_15_3,
        shortage_3_5,
        shortage_5,
        trips_query_delivered,
        trips_query_transit,
        avg_query_start
      ];
      queries = queries.map((query) => {
        if (transporter) query = `${query}&transporter_id=${transporter}`;
        if (vehicle) query = `${query}&vehicle_id=${vehicle}`;
        if (route) query = `${query}&route_id=${route}`;

        return query;
      });

      const trips_res = await API.get(apiName, queries[0]);
      const trips_res_transit = await API.get(apiName, queries[9]);
      const trips_res_delivered = await API.get(apiName, queries[8]);
      const avg_res = await API.get(apiName, queries[1]);
      const max_duration_res = await API.get(apiName, queries[2]);
      const min_duration_res = await API.get(apiName, queries[3]);
      const shortage_0_15_res = await API.get(apiName, queries[4]);
      const shortage_15_3_res = await API.get(apiName, queries[5]);
      const shortage_3_5_res = await API.get(apiName, queries[6]);
      const shortage_5_res = await API.get(apiName, queries[7]);
      const avg_start_res = await API.get(apiName, queries[10]);

      setShortageData([
        { label: "Shortage Category 0 - 1.5 Tons", data: shortage_0_15_res },
        { label: "Shortage Category 1.5 - 3 Ton", data: shortage_15_3_res },
        { label: "Shortage Category 3 - 5  Ton", data: shortage_3_5_res },
        { label: "Shortage Category > 5 Ton", data: shortage_5_res },
      ]);
      for (let item of avg_res) {
        let newData = avg_start_res.find(x => x.route_name === item.route_name)
        item.tons_per_trips = newData?.tons_per_trips
        item.trips_per_vehicle = newData?.trips_per_vehicle
        item.dispatch_per_day = newData?.dispatch_per_day
      }
      setAvgData(avg_res);
      let temp = [];
      for (let i = 0; i < trips_res.length; i++) {
        temp.push({
          ...trips_res[i],
          ...trips_res_transit.find(
            (item) => item.route_name === trips_res[i].route_name
          ),
          ...trips_res_delivered.find(
            (item) => item.route_name === trips_res[i].route_name
          ),
        });
      }
      setTripsTonData(temp);
      setTripDuration({ max: max_duration_res, min: min_duration_res });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      toast.dismiss(loading);
    }
  };

  const clearFilter = () => {
    setVehicle(null);
    setDateRange(range);
    setTransporter(null);
    setRoute(null);
    setKey(Math.random());
  };

  const getSum = (value) => {
    let total = tripsTonData.reduce(
      (acc, item) => acc + (item[value] > 0 ? +item[value] : 0),
      0
    );
    if (value === "excess")
      total = tripsTonData.reduce((acc, item) => acc + +item.excess, 0);
    if (+total) return total;
    return 0;
  };

  const handleCapture = () => {
    screenShotBtnRef.current.style.display = "none";
    if (captureRef.current) {
      captureRef.current.style.padding = "20px";
      html2canvas(captureRef.current).then((canvas) => {
        canvas.toBlob((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "DPR.png";
          link.click();
        }, "image/png");
      });
    }
    captureRef.current.style.padding = "0";
    screenShotBtnRef.current.style.display = "block";
  };

  useEffect(() => {
    fetchData();
  }, [dateRange?.start, vehicle, transporter, route]);

  const roles = useSelector((state) => state.userInfo.payload?.role);
  if (!roles?.includes("DISPATCH")) {
    return <NoAccess />;
  }

  return (
    <div>
      <div className="py-4 row ">
        <div className="col-lg-2 gap-2">
          <AsyncSelect
            key={key}
            isClearable
            theme={selectColor}
            required
            placeholder="Select Route"
            cacheOptions
            loadOptions={handleSearchRoute}
            defaultOptions
            onChange={(e) => setRoute(e?.value)}
          />
        </div>
        <div className="col-lg-2">
          <AsyncSelect
            key={key}
            isClearable
            theme={selectColor}
            required
            placeholder="Select Vehicle"
            cacheOptions
            loadOptions={handleSearchVehicles}
            defaultOptions
            onChange={(e) => setVehicle(e?.value)}
          />
        </div>
        <div className="col-lg-2">
          <AsyncSelect
            key={key}
            isClearable
            theme={selectColor}
            required
            placeholder="Select Transporter"
            cacheOptions
            loadOptions={handleSearchTransporter}
            defaultOptions
            onChange={(e) => setTransporter(e?.value)}
          />
        </div>
        <div className="col-lg-2">
          <DateRangePicker
            initialSettings={{
              startDate: moment.unix(dateRange?.start).format("MM-DD-YYYY"),
              endDate: moment.unix(dateRange?.end).format("MM-DD-YYYY"),
              linkedCalendars: true,
              showCustomRangeLabel: true,
              showDropdowns: true,
              alwaysShowCalendars: true,
            }}
            onApply={handleDateApplied}
          >
            <input
              className={`btn ${dateRange ? "btn-primary" : "btn-white"
                } pointer`}
            />
          </DateRangePicker>
        </div>
        <button
          className="col-auto ms-auto btn btn-light"
          onClick={clearFilter}
        >
          Clear
        </button>
        <button
          className="btn btn-primary col-auto ms-auto"
          ref={screenShotBtnRef}
          onClick={handleCapture}
        >
          Screen Shot
        </button>
      </div>
      <div ref={captureRef}>
        <div className="table-responsive card">
          <h3 className="text-center my-3 fs-1">
            Trips and Tonnage (
            {moment.unix(dateRange?.start).format("DD-MMM-YYYY")} to{" "}
            {moment
              .unix(dateRange?.end)
              .subtract(5, "hours")
              .subtract(30, "minutes")
              .format("DD-MMM-YYYY")}
            )
          </h3>
          <table className="table-hover table-bordered">
            <thead
              className="sticky-top bg-primary text-white"
              style={{ zIndex: 0 }}
            >
              <tr className="dpr-bold">
                <th className="text-center" colSpan={1}>
                  Route
                </th>
                <th className="text-center" colSpan={3}>
                  Dispatch
                </th>
                <th className="text-center" colSpan={2}>
                  In Transit
                </th>
                <th className="text-center" colSpan={4}>
                  Delivered
                </th>
                <th className="text-center" colSpan={1}>
                  Excess
                </th>
                <th className="text-center" colSpan={2}>
                  Shortage
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-light dpr-bold">
                <th></th>
                <th className="text-center">Trips</th>
                <th className="text-center">Tippers</th>
                <th className="text-center">Tons (MW)</th>
                <th className="text-center">Tippers</th>
                <th className="text-center">Tons (MW)</th>
                <th className="text-center">Trips</th>
                <th className="text-center">Tippers</th>
                <th className="text-center">Tons (MW)</th>
                <th className="text-center">Tons (RW)</th>
                <th className="text-center">Tons</th>
                <th className="text-center">Total Tons</th>
                <th className="text-center">AVG Shortage / Trips</th>
              </tr>
              {tripsTonData?.map((item, idx) => (
                <tr key={idx} className="dpr-bold">
                  <td align="center">{item?.route_name}</td>
                  <td align="center">{item?.trips_dispatch || 0}</td>
                  <td align="center">{item?.tippers_dispatch || 0}</td>
                  <td align="center">{item?.tons_mw_dispatch || 0}</td>
                  <td align="center">{item?.trips_in_transit || 0}</td>
                  <td align="center">{item?.tons_in_transit || 0}</td>
                  <td align="center">{item?.trips_delivered || 0}</td>
                  <td align="center">{item?.tippers_delivered || 0}</td>
                  <td align="center">{item?.tons_mw_delivered || 0}</td>
                  <td align="center">{item?.tons_rw_delivered || 0}</td>
                  <td align="center">{item?.excess * -1 || 0}</td>
                  <td align="center">
                    {item?.shortage > 0 ? item?.shortage : 0}
                  </td>
                  <td align="center">
                    {item?.avg_shortage > 0 ? item?.avg_shortage : 0}
                  </td>
                </tr>
              ))}
              {tripsTonData?.length > 1 && (
                <tr className="dpr-bold">
                  <td align="center">Total</td>
                  <td align="center">{getSum("trips_dispatch")}</td>
                  <td align="center">{getSum("tippers_dispatch")}</td>
                  <td align="center">
                    {getSum("tons_mw_dispatch")?.toFixed(2)}
                  </td>
                  <td align="center">{getSum("trips_in_transit")}</td>
                  <td align="center">
                    {getSum("tons_in_transit")?.toFixed(2)}
                  </td>
                  <td align="center">{getSum("trips_delivered")}</td>
                  <td align="center">{getSum("tippers_delivered")}</td>
                  <td align="center">
                    {getSum("tons_mw_delivered")?.toFixed(2)}
                  </td>
                  <td align="center">
                    {getSum("tons_rw_delivered")?.toFixed(2)}
                  </td>
                  <td align="center">{getSum("excess")?.toFixed(2) * -1}</td>
                  <td align="center">{getSum("shortage")?.toFixed(2)}</td>
                  <td align="center">
                    {(getSum("avg_shortage") / 2)?.toFixed(2)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!loading && tripsTonData.length === 0 && (
            <h4 className="text-center my-4">No data found.</h4>
          )}
        </div>
        <div className="card">
          <h3 className="text-center my-3 fs-1">Average Report</h3>
          <table className="table-hover table-bordered">
            <thead
              className="sticky-top bg-primary text-white"
              style={{ zIndex: 0 }}
            >
              <tr className="dpr-bold">
                <th className="text-center">Route</th>
                <th className="text-center">Avg Tons/Trip</th>
                <th className="text-center">Avg Trips/Vehicle </th>
                <th className="text-center">Avg Shortage/Trip</th>
                <th className="text-center">Avg Shortage/Day</th>
                <th className="text-center">Avg Dispatch/Day</th>
                <th className="text-center">Min Shortage</th>
                <th className="text-center">Max Shortage</th>
                <th className="text-center">Avg Time Taken</th>
                <th className="text-center">Min Time</th>
                <th className="text-center">Max Time</th>
              </tr>
            </thead>
            <tbody>
              {avgData?.map((item, idx) => (
                <tr key={idx} className="dpr-bold">
                  <td align="center">{item?.route_name}</td>
                  <td align="center">{item?.tons_per_trips || 0}</td>
                  <td align="center">{item?.trips_per_vehicle || 0}</td>
                  <td align="center">
                    {item?.shortage_per_trip > 0 ? item?.shortage_per_trip : 0}
                  </td>
                  <td align="center">
                    {item?.shortage_per_day > 0 ? item?.shortage_per_day : 0}
                  </td>
                  <td align="center">{item?.dispatch_per_day || 0}</td>
                  <td align="center">{item?.min_shortage || 0}</td>
                  <td align="center">{item?.max_shortage || 0}</td>
                  <td align="center">{getTime(item?.avg_time * 60)}</td>
                  <td align="center">{getTime(item?.min_time * 60)}</td>
                  <td align="center">{getTime(item?.max_time * 60)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!loading && avgData.length === 0 && (
            <h4 className="text-center my-4">No data found.</h4>
          )}
        </div>

        <div>
          <h3 className="text-center fs-1">Trip Duration</h3>
          <div className="row">
            <div className="col-lg-6">
              <div className="card ">
                <h3 className="text-center my-3 fs-1">Minimum Time Taken</h3>
                <table className="table-hover table-bordered ">
                  <thead
                    className="sticky-top bg-primary text-white"
                    style={{ zIndex: 0 }}
                  >
                    <tr className="dpr-bold">
                      <th className=" text-center">Trip ID</th>
                      <th className=" text-center">Vehicle No.</th>
                      <th>Transporter Name</th>
                      <th className=" text-center">Route Name</th>
                      <th className=" text-center">Trip Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tripDuration?.min?.map((item, idx) => (
                      <tr key={idx} className="dpr-bold">
                        <td align="center">{item?.trip_id}</td>
                        <td align="center">{item?.vehicle_id}</td>
                        <td>{item?.transporter_name}</td>
                        <td align="center">{item?.route_name}</td>
                        <td align="center">
                          {getTime(item?.trip_duration * 60)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!loading && tripDuration?.min.length === 0 && (
                  <h4 className="text-center my-4">No data found.</h4>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card ">
                <h3 className="text-center my-3 fs-1">Maximum Time Taken</h3>
                <table className="table-hover table-bordered ">
                  <thead
                    className="sticky-top bg-primary text-white"
                    style={{ zIndex: 0 }}
                  >
                    <tr className="dpr-bold">
                      <th className="text-center"> Trip ID</th>
                      <th className="text-center"> Vehicle No.</th>
                      <th> Transporter Name </th>
                      <th className="text-center"> Route Name</th>
                      <th className="text-center"> Trip Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tripDuration?.max?.map((item, idx) => (
                      <tr key={idx} className="dpr-bold">
                        <td align="center">{item?.trip_id}</td>
                        <td align="center">{item?.vehicle_id}</td>
                        <td>{item?.transporter_name}</td>
                        <td align="center">{item?.route_name}</td>
                        <td align="center">
                          {getTime(item?.trip_duration * 60)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!loading && tripDuration?.max.length === 0 && (
                  <h4 className="text-center my-4">No data found.</h4>
                )}
              </div>
            </div>
          </div>

          {!loading && avgData.length === 0 && (
            <h4 className="text-center my-4">No data found.</h4>
          )}
        </div>

        <div>
          <div className="row">
            {shortageData?.map((item, idx) => (
              <div className="col-lg-3" key={idx}>
                <div className="card ">
                  <h2 className="text-center my-3 fw-bold fs-1">
                    {item?.label}
                  </h2>
                  <table className="table-hover table-bordered ">
                    <thead
                      className="sticky-top bg-primary text-white"
                      style={{ zIndex: 0 }}
                    >
                      <tr className="dpr-bold">
                        <th>Transport Name</th>
                        <th className="text-center">Shortage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item?.data?.map((item, idx) => (
                        <tr key={idx} className="dpr-bold">
                          <td>{item?.transporter_name}</td>
                          <td align="center">{item?.total_shortage}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>

          {!loading && avgData.length === 0 && (
            <h4 className="text-center my-4">No data found.</h4>
          )}
        </div>
      </div>
    </div>
  );
}
