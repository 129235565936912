import { API } from 'aws-amplify'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import toast from 'react-hot-toast'
import { apiName, getTime } from '../../components/Utils'
import * as XLSX from 'xlsx';

export default function IdleReport() {
  const [date, setDate] = useState({ start: moment().startOf('day').unix(), end: moment().endOf('day').unix() })
  const [loading, setLoading] = useState(false)
  const [idleData, setIdleData] = useState([])


  const handleDateApplied = (event, picker) => {
    const fromDate = moment(picker.startDate).startOf("day").unix()
    const toDate = Math.floor(moment(picker.endDate).endOf("day").unix());
    setDate({ start: fromDate, end: toDate });
  };

  const getData = async () => {
    const loading = toast.loading('Loading...')
    try {
      let res = await API.get(apiName, `/report/idle?start=${date?.start}&end=${date?.end}&take=${100}&skip=${0}`)
      setIdleData(res)
    } catch (error) {
      toast.error("Something went wrong !")
    } finally { toast.dismiss(loading) }
  }

  const handleDownload = async () => {
    const loading = toast.loading('Downloading Report. This might take a while based on the internet speed. Please wait..')
    try {
      let take = 100
      let skip = 0
      let data = []
      let res = await API.get(apiName, `/report/idle?start=${date?.start}&end=${date?.end}&take=${take}&skip=${take * skip}`)
      skip++
      data.push(...res)
      while (true) {
        let res = await API.get(apiName, `/report/idle?start=${date?.start}&end=${date?.end}&take=${take}&skip=${take * skip}`)
        data.push(...res)
        skip++
        if (res.length === 0) break
      }

      let dataToDownload = data.map(item => {
        return {
          'Vehicle No': item?.vehicle_id,
          'Transporter': item?.transporter_name,
          'Trip St Time (Mines)': moment.unix(item?.mine_entry_time).format("DD-MMM-YYYY, hh:mm A"),
          'Railway Close Time': moment.unix(item?.rail_exit_time).format("DD-MMM-YYYY, hh:mm A"),
          'Total Duration': getTime(moment.unix(item?.rail_exit_time).diff(moment.unix(item?.mine_entry_time), "minutes")),
          'Idle Hours': getTime(+item?.total_idle_time / 60),
          'Transit Hours': getTime(moment.unix(item?.rail_exit_time).diff(moment.unix(item?.mine_entry_time), "minutes") - +item?.total_idle_time / 60)
        }
      })

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(dataToDownload);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `Idle Report_${moment.unix(date?.start).format('DD-MMM-YYYY')} - ${moment.unix(date?.end).subtract(5, "hours").subtract(30, "minutes").format('DD-MMM-YYYY')}.xlsx`);
      toast.success("Download Successful.")
    } catch (error) {
      toast.error("Something went wrong !")
    } finally { toast.dismiss(loading) }
  }

  useEffect(() => {
    getData()
  }, [date])


  return (
    <div>
      <div>
        <h2 className='text-center'>Idle Report</h2>
        <div className='card p-2'>
          <div className='d-flex align-items-center justify-content-end gap-3'>
            <>
              <DateRangePicker
                initialSettings={{
                  startDate: moment.unix(date?.start).format("MM-DD-YYYY"),
                  endDate: moment.unix(date?.end).subtract(5, 'hours').subtract(30, 'minutes').format("MM-DD-YYYY"),
                  linkedCalendars: true,
                  showCustomRangeLabel: true,
                  showDropdowns: true,
                  alwaysShowCalendars: true,
                  ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                  }
                }}
                onApply={handleDateApplied}
              >
                <input
                  className={`btn btn-light ml-2 pointer`}
                />
              </DateRangePicker>
            </>
            <button disabled={loading} onClick={handleDownload} type='button' style={{ width: 'fit-content' }} className='btn btn-primary'><i className='fe fe-download'></i></button>
          </div>
        </div>
      </div>

      <div className="card">
        <table className='table-hover table-bordered'>
          <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
            <tr>
              <th className="fs-5 text-center">Vehicle No.</th>
              <th className="fs-5 text-center">Transporter</th>
              <th className="fs-5 text-center">Trip St Time (Mines)</th>
              <th className="fs-5 text-center">Railway Close Time</th>
              <th className="fs-5 text-center">Total Hours</th>
              <th className="fs-5 text-center">Idle Hours</th>
              <th className="fs-5 text-center">Transit Hours</th>
            </tr>
          </thead>
          <tbody>
            {idleData?.map((item, idx) => (
              <tr key={idx}>
                <td align="center">{item?.vehicle_id}</td>
                <td align="center">{item?.transporter_name}</td>
                <td align="center">{moment.unix(item?.mine_entry_time).format("DD-MMM-YYYY, hh:mm A")}</td>
                <td align="center">{moment.unix(item?.rail_exit_time).format("DD-MMM-YYYY, hh:mm A")}</td>
                <td align="center">{getTime(moment.unix(item?.rail_exit_time).diff(moment.unix(item?.mine_entry_time), "minutes"))}</td>
                <td align="center">{getTime(+item?.total_idle_time / 60)}</td>
                <td align="center">{getTime(moment.unix(item?.rail_exit_time).diff(moment.unix(item?.mine_entry_time), "minutes") - +item?.total_idle_time / 60)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {idleData.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
      </div>
    </div>
  )
}
