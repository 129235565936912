import React, { useState } from 'react'
import GpsReport from '../../components/GpsReport'
import moment from 'moment'
import { getTime } from '../../components/Utils'
import NoAccess from '../../components/NoAccess'
import { useSelector } from 'react-redux'

export default function Offline() {
  const [data, setData] = useState([])

  const formatData = (x) => {
    const res = x.map(item => {
      let report = {
        "Vehicle": item?.vehicle_number,
        "Transporter": item?.transporter_name,
        "Offline Since": moment.unix(item?.offline_since).format("DD-MMM-YYYY, hh:mm A"),
        "No.of Days offline": `${moment().diff(moment.unix(item?.offline_since), 'days')} Days`
      };
      return report
    })
    setData(x)
    return res
  }

  const roles = useSelector((state) => state.userInfo.payload?.role)
  if (!roles?.includes("OFFLINE")) return <NoAccess />

  return (
    <div>
      <GpsReport title={'Offline Report'} api={'offline_since'} formatData={formatData} showFilter={false} />
      <div className="card">
        <table className='table-hover table-bordered'>
          <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
            <tr>
              <th className="fs-5 text-center">Vehicle</th>
              <th className="fs-5 text-center">Transporter</th>
              <th className="fs-5 text-center">Offline Since</th>
              <th className="fs-5 text-center">No. of Days Offline</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <tr key={idx}>
                <td align="center">{item.vehicle_number}</td>
                <td align="center">{item?.transporter_name}</td>
                <td align="center">{moment.unix(item?.offline_since).format("DD-MMM-YYYY, hh:mm A")}</td>
                <td align="center">{moment().diff(moment.unix(item?.offline_since), 'days')} Days</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
      </div>
    </div>
  )
}
