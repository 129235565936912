
import { Pagination } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NoAccess from "../components/NoAccess";
import Spinner from "../components/Spinner";
import { apiName, delay, limit } from "../components/Utils";



const LoginHistory = () => {
  const navigate = useNavigate();
  const route = useLocation();
  const path = route.pathname;
  const role = useSelector((state) => state.userInfo.payload?.role)

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0)

  function handlePaginationChange(newPageIndex) {
    setCount(newPageIndex - 1)
  }

  async function getData() {
    setLoading(true);
    try {
      let query = `/login-history?take=${limit}&skip=${count * limit}`
      if (search.length !== 0) query = `${query}&keyword=${search}`
      let res = await API.get(apiName,query)
      setLoading(false);
      setUsers(res.items);
      setTotal(res.count)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      getData();
    }, delay);
    return () => clearTimeout(timer);
  }, [search, count]);


  if (!role?.includes("USERS")) {
    return <NoAccess/>;
  }

  return (
    <div className="container-fluid">
      {/* header starts */}
      <div className="row align-items-center">
        <div className="col">
          <h1 className="header-title text-truncate">Login History</h1>
        </div>
      </div>
      <hr />
      <div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              >
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            className="form-control list-search"
                            type="search"
                            autoFocus
                            placeholder="Search by user name"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Spinner show={loading}>
                    <div className="table-responsive" style={{ height: "64vh" }}>
                      <table className="table table-sm table-hover table-nowrap card-table">
                        <thead className="sticky-top  top-0">
                          <tr>
                            <th>Date</th>
                            <th>Username</th>
                            <th>Device</th>
                            <th>Address</th>
                          </tr>
                        </thead>

                        <tbody>
                          {users.map((user) => (
                            <tr key={user.id}>
                            <td>{moment.unix(user?.date)?.format("DD-MM-YY hh:mm A")}</td>
                              <td>{user?.name}</td>
                              <td>{user?.device?.slice(0,40)}</td>
                              <td>{user?.address?.slice(8)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {users.length === 0 && !loading && (
                        <p className="text-center m-3">No data </p>
                      )}
                    </div>
                    <div className="card-footer d-flex justify-content-center">
                        <Pagination
                          currentPage={count + 1}
                          totalPages={Math.ceil(total / limit)}
                          siblingCount={1}
                          onNext={() => setCount((prev) => prev + 1)}
                          onPrevious={() => setCount((prev) => prev - 1)}
                          onChange={handlePaginationChange}
                        />
                      </div>
                  </Spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default LoginHistory;
