import { useState } from 'react';
import ReportCard from '../../components/ReportCard';
import { getTime } from '../../components/Utils';
import NoAccess from '../../components/NoAccess';
import { useSelector } from 'react-redux';

export default function VehiclePerformance() {
    const [data, setData] = useState([])

    function formatData(data) {
        const res = data.map((item, idx) => {
            let report = {
                "S.No": idx + 1,
                "Vehicle No.": item?.vehicle_no,
                "Transporter Name": item?.transporter_name,
                "Route Name": item?.route_name,
                "No. of Days": item?.days_present,
                "No. of Trips": item?.trip_count,
                "Avg Trip/Day": (item?.trip_count / item?.days_present).toFixed(2),
                "Total Trip Duration": Math.floor(item?.total_trip_time / 60),
                "Avg Trip Duration": Math.floor(item?.avg_trip_time / 60),
                "Tare Weight": item?.tare_weight / 1000,
                "Mine Weight": item?.mine_weight / 1000,
                "Rail Weight": item?.rail_weight / 1000,
                "Shortage": item?.total_short_weight > 0 ? item?.total_short_weight / 1000 : 0,
                "Excess": item?.total_short_weight < 0 ? item?.total_short_weight * -1 : 0,
                "Avg Shortage/Trip": item?.total_short_weight > 0 ? ((item?.total_short_weight / item?.trip_count) / 1000).toFixed(2) : 0,
            };
            return report
        })
        setData(res)
        return res
    }

    const roles = useSelector((state) => state.userInfo.payload?.role)
    if (!roles?.includes("VEHICLE-PERFORMANCE")) {
        return <NoAccess />
    }

    return (
        <>
            <ReportCard title={"Vehicle Performance Report"} formatData={formatData} api={'vehicle-performance-report'} />
            <div className="table-responsive card">
                <table className="table-hover table-bordered">
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                        <tr>
                            {data?.length > 0 && Object.keys(data[0])?.map(item => (
                                <th className={`fs-5 ${item !== 'Transporter Name' && "text-center"}`} key={item}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, idx) => (
                            <tr key={idx}>
                                <td align='center' className='fs-5'>{item["S.No"]}</td>
                                <td align='center' className='fs-5'>{item["Vehicle No."]}</td>
                                <td align='left' className='fs-5'>{item["Transporter Name"]}</td>
                                <td align='center' className='fs-5'>{item["Route Name"]}</td>
                                <td align='center' className='fs-5'>{item["No. of Days"]}</td>
                                <td align='center' className='fs-5'>{item["No. of Trips"]}</td>
                                <td align='center' className='fs-5'>{item["Avg Trip/Day"]}</td>
                                <td align='center' className='fs-5'>{item["Total Trip Duration"]}</td>
                                <td align='center' className='fs-5'>{item["Avg Trip Duration"]}</td>
                                <td align='center' className='fs-5'>{item["Tare Weight"]}</td>
                                <td align='center' className='fs-5'>{item["Mine Weight"]}</td>
                                <td align='center' className='fs-5'>{item["Rail Weight"]}</td>
                                <td align='center' className='fs-5'>{item["Shortage"]}</td>
                                <td align='center' className='fs-5'>{item["Excess"]}</td>
                                <td align='center' className='fs-5'>{item["Avg Shortage/Trip"]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
