import { API } from 'aws-amplify'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import toast from 'react-hot-toast'
import { apiName, getPlayerDuration, getTime } from '../../components/Utils'
import * as XLSX from 'xlsx';

export default function Hindrance() {
    const [date, setDate] = useState({ start: moment().subtract(6, 'days').startOf('day').unix(), end: moment().endOf('day').unix() })
    const [loading, setLoading] = useState(false)
    const [hindranceData, setHindranceData] = useState([])


    const handleDateApplied = (event, picker) => {
        const fromDate = moment(picker.startDate).startOf("day").unix()
        const toDate = Math.floor(moment(picker.endDate).endOf("day").unix());
        setDate({ start: fromDate, end: toDate });
    };

    const getData = async () => {
        const loading = toast.loading('Loading...')
        try {
            let res = await API.get(apiName, `/hindrance?start=${date?.start}&end=${date?.end}&take=${100}&skip=${0}`)
            setHindranceData(res.items)
        } catch (error) {
            toast.error("Something went wrong !")
        } finally { toast.dismiss(loading) }
    }

    const handleDownload = async () => {
        const loading = toast.loading('Downloading Report. This might take a while based on the internet speed. Please wait..')
        try {
            let data = []
            let res = await API.get(apiName, `/hindrance?start=${date?.start}&end=${date?.end}&take=${100000000}`)
            data.push(...res.items)

            let dataToDownload = data.map((item, idx) => {
                return {
                    'SL. No': idx + 1,
                    'Date': moment.unix(item?.created_at).format("DD/MM/YYYY"),
                    'Vehicle Count': `${item?.vehicle_loaded} loaded + ${item?.vehicle_unloaded} unloaded`,
                    'Patrolling Team': item?.patrolling_team,
                    'Reason': item?.reason,
                    'Segment': item?.segment,
                    'Place': item?.place,
                    'Thana': item?.thana,
                    'Feedback(Reason for Jam)': item?.feedback,
                    'Start Time': moment.unix(item?.opened_at).format("HH:mm:ss"),
                    'End Time': item?.closed_at ? moment.unix(item?.closed_at).format("HH:mm:ss") : "-",
                    'Duration': item?.closed_at ? getPlayerDuration(item?.opened_at, item?.closed_at) : "-",
                    'Route': item?.route_name,
                    'Shift': item?.shift,
                    'Shift Person': item?.shift_person,
                    'Closing Comments': item?.closing_comments
                }
            })

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(dataToDownload);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, `Hindrance Report_${moment.unix(date?.start).format('DD-MMM-YYYY')} - ${moment.unix(date?.end).subtract(5, "hours").subtract(30, "minutes").format('DD-MMM-YYYY')}.xlsx`);
            toast.success("Download Successful.")
        } catch (error) {
            toast.error("Something went wrong !")
            console.log(error)
        } finally {
            toast.dismiss(loading)
        }
    }

    useEffect(() => {
        getData()
    }, [date])


    return (
        <div>
            <div>
                <h2 className='text-center'>Hindrance Report</h2>
                <div className='card p-2'>
                    <div className='d-flex align-items-center justify-content-end gap-3'>
                        <>
                            <DateRangePicker
                                initialSettings={{
                                    startDate: moment.unix(date?.start).format("MM-DD-YYYY"),
                                    endDate: moment.unix(date?.end).subtract(5, 'hours').subtract(30, 'minutes').format("MM-DD-YYYY"),
                                    linkedCalendars: true,
                                    showCustomRangeLabel: true,
                                    showDropdowns: true,
                                    alwaysShowCalendars: true,
                                    ranges: {
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                    }
                                }}
                                onApply={handleDateApplied}
                            >
                                <input
                                    className={`btn btn-light ml-2 pointer`}
                                />
                            </DateRangePicker>
                        </>
                        <button disabled={loading} onClick={handleDownload} type='button' style={{ width: 'fit-content' }} className='btn btn-primary'><i className='fe fe-download'></i></button>
                    </div>
                </div>
            </div>

            <div className="card">
                <table className='table-hover table-bordered'>
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                        <tr>
                            <th className="fs-5 text-center">Sl No.</th>
                            <th className="fs-5 text-center">Date</th>
                            <th className="fs-5 text-center">Vehicle Count</th>
                            <th className="fs-5 text-center">Patrolling Team</th>
                            <th className="fs-5 text-center">Reason</th>
                            <th className="fs-5 text-center">Segment</th>
                            <th className="fs-5 text-center">Place</th>
                            <th className="fs-5 text-center">Thana</th>
                            <th className="fs-5 text-center">Feedback(Reason for Jam)</th>
                            <th className="fs-5 text-center">Start Time</th>
                            <th className="fs-5 text-center">End Time</th>
                            <th className="fs-5 text-center">Duration</th>
                            <th className="fs-5 text-center">Route</th>
                            <th className="fs-5 text-center">Shift</th>
                            <th className="fs-5 text-center">Shift Person</th>
                            <th className="fs-5 text-center">Closing Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hindranceData?.map((item, idx) => (
                            <tr key={idx}>
                                <td align="center">{idx + 1}</td>
                                <td align="center">{moment.unix(item?.created_at).format("DD/MM/YYYY")}</td>
                                <td align="center">{item?.vehicle_loaded} loaded + {item?.vehicle_unloaded} unloaded</td>
                                <td align="center">{item?.patrolling_team}</td>
                                <td align="center">{item?.reason}</td>
                                <td align="center">{item?.segment}</td>
                                <td align="center">{item?.place}</td>
                                <td align="center">{item?.thana}</td>
                                <td align="center">{item?.feedback}</td>
                                <td align="center">{moment.unix(item?.opened_at).format("HH:mm:ss")}</td>
                                <td align="center">{item?.closed_at ? moment.unix(item?.closed_at).format("HH:mm:ss") : "-"}</td>
                                <td align="center">{item?.closed_at ? getPlayerDuration(item?.opened_at, item?.closed_at) : "-"}</td>
                                <td align="center">{item?.route_name}</td>
                                <td align="center">{item?.shift}</td>
                                <td align="center">{item?.shift_person}</td>
                                <td align="center">{item?.closing_comments}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {hindranceData.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
            </div>
        </div>
    )
}
