import { createSlice } from "@reduxjs/toolkit";

export const vehicleSlice = createSlice({
    name: "vehicles",
    initialState: {},
    reducers: {
        setVehicles: (state, action) => {
            return (state = action);
        },
    },
});

export const { setVehicles } = vehicleSlice.actions;
export default vehicleSlice.reducer;
