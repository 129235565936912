import { Pagination } from "@aws-amplify/ui-react";
import { API } from 'aws-amplify';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import NoAccess from "../../components/NoAccess";
import Spinner from "../../components/Spinner";
import { apiName, currentDate, delay, limit } from "../../components/Utils";


const Drivers = () => {
  const navigate = useNavigate();
  const route = useLocation()
  const path = route.pathname
  const user = useSelector((state) => state.userInfo.payload);
  const role = user?.role;

  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState(0)
  const [driverState, setDriverState] = useState({})
  const [comment, setComment] = useState("")
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0)
  const [total, setTotal] = useState(0)
  const [sort, setSort] = useState({ key: "name", direction: "asc" })


  async function getData() {
    setLoading(true);
    try {
      let query = `/driver?take=${limit}&skip=${count * limit}`
      if (sort.key) query = `${query}&sort=${sort.key}`
      if (sort.direction) query = `${query}&direction=${sort.direction}`
      if (tab === 1) query = `${query}&active=${false}`
      if (search?.length > 0) query = `${query}&keyword=${search}`
      let res = await API.get(apiName, query);
      setTotal(res?.count)
      setDrivers(res?.items)
    } catch (error) {
      console.log(error);
    } finally { setLoading(false) }
  }


  async function handleToggleDriver() {
    const { id, active, comments } = driverState.driver
    const { index } = driverState
    try {
      let commentMsg;
      if (comments) {
        commentMsg = [{ username: user?.name, msg: comment, time: currentDate(), active: !active }, ...comments]
      } else {
        commentMsg = [{ username: user?.name, msg: comment, time: currentDate(), active: !active }]
      }
      const init = { body: { ...driverState.driver, active: !active, comments: commentMsg } };
      await API.put(apiName, `/driver/${id}`, init)

      let temp = structuredClone(drivers);
      temp[index].active = !temp[index].active;
      setDrivers(temp);
      temp[index].active ? toast.success(`Driver is active!`) : toast.success("Driver is inactive!")
      setShow(false);
    } catch (error) {
      toast.error("Something went wrong !")
      console.log(error);
    }
  }

  async function handleDownload() {
    const loading = toast.loading("Downloading...")
    try {
      let data = await API.get(apiName, '/driver?take=99999999');
      let temp = data.items.map(item => {
        return {
          PASS_NO: item?.id,
          NAME: item?.name,
          PHONE: item?.phone,
          LICENCE_NO: item?.licence_number,
          LICENCE_VALID_TILL: moment.unix(item?.licence_expiry).format("DD-MM-YYYY"),
        }
      })
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(temp);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `Driver List ${moment().format("DD-MM-YYYY")}.xlsx`);
    } catch (error) {
      console.log(error)
      toast.error(error.message || "Failed to download list!")
    } finally {
      {
        toast.dismiss(loading)
        setLoading(false)
      }
    }
  }

  function handleSort(param) {
    setSort({
      key: param,
      direction: param === sort.key ? sort.direction === 'asc' ? 'desc' : 'asc' : "desc"
    })
  }
  function handlePaginationChange(newPageIndex) {
    setCount(newPageIndex - 1)
  }
  useEffect(() => {
    let timer = setTimeout(() => {
      role?.includes("DRIVERS") && getData();
    }, delay);
    return () => clearTimeout(timer);
  }, [role, count, search, tab, sort.key, sort.direction]);


  if (!role?.includes("DRIVERS")) return <NoAccess />;

  return (
    <div className="container-fluid" >
      {/* header starts */}
      <div className="row align-items-center">
        <div className="col">
          <h1 className="header-title text-truncate">Drivers ({total})</h1>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary fw-bold"
            onClick={() => navigate(path.includes("mobile") ? "/mobile/driver/add" : "/driver/add")}>
            Add Driver
          </button>
        </div>
      </div>
      <hr />
      {/* header ends */}
      <ul className="nav nav-tabs nav-overflow header-tabs mx-1 my-2">
        <li className="nav-item">
          <div
            className={`nav-link ${tab === 0 ? "active" : ""} pointer`}
            onClick={() => setTab(0)}>
            <b>All</b>
          </div>
        </li>
        <li className="nav-item">
          <div
            className={`nav-link ${tab === 1 ? "active" : ""} pointer`}
            onClick={() => setTab(1)}
          >
            <b>Inactive</b>
          </div>
        </li>
      </ul>
      <div className="recommended-experts-container-content">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              >
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            className="form-control  search"
                            type="search"
                            placeholder="Search by driver name"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="col-auto px-1">
                          <button onClick={handleDownload}
                            className="btn btn-primary w-100 fw-bold" ><i className="fe fe-download" /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Spinner show={loading}>
                    <div className={window.innerWidth > 750 ? "table-responsive" : ''} style={{ height: "64vh" }}>
                      {window.innerWidth > 750 ? (
                        <table className="table table-sm table-hover table-nowrap card-table">
                          <thead className="sticky-top top-0 ">
                            <tr>
                              <th data-sort="item" onClick={() => handleSort('id')} className="pointer">Pass Number</th>
                              <th data-sort="item" onClick={() => handleSort('name')} className="pointer">Name</th>
                              <th>Phone</th>
                              <th>Licence Number / Validity</th>
                              {role && role?.includes("USERS") && (<th className="text-center">Status</th>)}
                            </tr>
                          </thead>

                          <tbody>
                            {drivers.map((driver, index) => (
                              <tr key={driver.id} className="pointer" onClick={() => {
                                navigate(`/driver/${driver?.id}`);
                              }}>
                                <td >{driver?.id}</td>
                                <td>
                                  <div className="d-flex align-items-center ">
                                    <a className="avatar avatar-xs me-2" >
                                      <img
                                        className="avatar-img rounded-circle"
                                        src={driver?.image || "/img/user.png"}
                                        alt="driver"
                                      />
                                    </a>
                                    <span>{driver?.name}</span>
                                  </div>
                                </td>
                                <td>{driver?.phone}</td>
                                <td><div>{driver?.licence_number}</div>
                                  <div className="text-muted">{driver?.licence_expiry && moment.unix(driver?.licence_expiry).format("DD-MM-YYYY")}</div>
                                </td>
                                {role && role?.includes("USERS") && (
                                  <>
                                    <td className="d-flex justify-content-center">
                                      <div
                                        className="form-check form-switch fit"
                                        onClick={async (e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="flexSwitchCheckChecked"
                                          checked={driver?.active ? true : false}
                                          onClick={() => setShow(true)}
                                          onChange={async (e) => setDriverState({ driver: driver, index })}
                                        />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"
                                        ></label>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {drivers?.map((driver, index) => (
                            <div className="card m-2 pointer"
                              onClick={() => {
                                path.includes("mobile") ? navigate(`/mobile/driver/${driver.id}`) : navigate(`/driver/${driver.id}`);
                              }} key={index}>
                              <div className="p-3 row">
                                <div className="col col-6">
                                  <p className="text-muted m-0"><b>Name</b></p>
                                  <p className="m-0"><b>{driver?.name}</b></p>
                                  <p className="text-muted m-0"><b>Pass Number</b></p>
                                  <p className="m-1"><b>{driver?.id}</b></p>
                                  <p className="text-muted m-0"><b>Phone</b></p>
                                  <p className="m-1"><b>{driver?.phone}</b></p>
                                </div>
                                <div className="col col-6 text-end">
                                  <p className="text-muted m-0"><b>Licence Number</b></p>
                                  <p className="m-1"><b>{driver?.licence_number}</b></p>
                                  <p className="text-muted m-0"><b>Licence Valid Till</b></p>
                                  <strong>{driver?.licence_expiry && moment(driver?.licence_expiry).format('DD-MM-YYYY')}</strong>
                                  <p className="text-muted m-0"><b>Status</b></p>
                                  <div
                                    className="form-check form-switch fit d-flex justify-content-end my-2"
                                    onClick={async (e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <input
                                      disabled
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckChecked"
                                      checked={driver?.active ? true : false}
                                      onClick={() => setShow(true)}
                                      onChange={async (e) => {
                                        console.log(e)
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexSwitchCheckChecked"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {drivers.length === 0 && !loading && (
                        <p className="text-center m-3">No Drivers </p>
                      )}
                    </div>
                    <div className="card-footer d-flex justify-content-center">
                      <Pagination
                        currentPage={count + 1}
                        totalPages={Math.ceil(total / limit)}
                        siblingCount={1}
                        onNext={() => setCount((prev) => prev + 1)}
                        onPrevious={() => setCount((prev) => prev - 1)}
                        onChange={handlePaginationChange}
                      />
                    </div>
                  </Spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <Modal show={show} onHide={() => setShow(false)}>
        <form onSubmit={(e) => {
          e.preventDefault()
          handleToggleDriver()
        }}>
          <Modal.Header>
            <Modal.Title><b>Change Status</b></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input required className="form-control" placeholder="Enter reason" value={comment} onChange={(e) => setComment(e.target.value)} maxLength={60} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              <b>Make {driverState?.driver?.active ? "Inactive" : "active"}</b>
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default Drivers;
