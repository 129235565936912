import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import GpsReport from '../../components/GpsReport'
import NoAccess from '../../components/NoAccess'

export default function Ignition() {
  const [data, setData] = useState([])

  const formatData = (x) => {
    try {
      const filteredData = x.filter(item => {
        const duration = moment.duration(item.duration);
        return duration.asSeconds() > moment.duration('00:10:00').asSeconds();
      });
      const res = filteredData.map(item => {
        let report = {
          "Date": moment.unix(item?.date).format("DD-MMM-YYYY, hh:mm A"),
          "Duration" : item?.duration || 0,
          "Latitude" : item?.lat,
          "Longitude" : item?.lng 
        };
        return report
    })

    setData(filteredData)
    return res
  } catch (error) {
      console.log(error)
  }
  }

  const roles = useSelector((state)=>state.userInfo.payload?.role)
  if(!roles?.includes("IGNITION")) return <NoAccess/>

  return (
    <div>
      <GpsReport title={'Ignition Report'} api={'ignition'} formatData={formatData} />
      <div className="card">
        <table className='table-hover table-bordered'>
          <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
            <tr>
              <th className="fs-5 text-center">Date</th>
              <th className="fs-5 text-center">Duration</th>
              <th className="fs-5 text-center">Latitude</th>
              <th className="fs-5 text-center">Longitude</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <tr key={idx}>
                <td align="center">{moment.unix(item?.date).format("DD-MMM-YYYY, hh:mm A")}</td>
                <td align="center">{item?.duration || 0}</td>
                <td align="center">{item?.lat || 0}</td>
                <td align="center">{item?.lng || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
      </div>
    </div>
  )
}
