
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import GpsReport from '../../components/GpsReport'
import NoAccess from '../../components/NoAccess'
import { getDistance } from 'geolib'

export default function SecurityVehicle() {
  const [data, setData] = useState([])

  const formatData = (x) => {
    const res = x.map(item => {
      let totalDistance = 0;
      const uniqueObjects = Array.from(
        new Set(item?.location.map(obj => JSON.stringify(obj))),
        str => JSON.parse(str)
      );
      for (let i = 0; i < uniqueObjects.length - 1; i++) {
        totalDistance += getDistance(uniqueObjects[i], uniqueObjects[i + 1]);
      }
      let report = {
          "Vehicle": item?.id,
          "Distance_Travelled" : totalDistance / 1000
      };
      return report
  })
 
    setData(res)
    return res
  }

  const roles = useSelector((state)=>state.userInfo.payload?.role)
  if(!roles?.includes("SECURITY_VEHICLE")) return <NoAccess/>

  return (
    <div>
      <GpsReport title={'Security Vehicle Report'} api={'security-vehicle'} formatData={formatData} />
      <div className="card">
        <table className='table-hover table-bordered'>
          <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
            <tr>
              <th className="fs-5 text-center">Vehicle No.</th>
              <th className="fs-5 text-center">Distance Travelled</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <tr key={idx}>
                <td align="center">{item?.Vehicle }</td>
                <td align="center">{item?.Distance_Travelled} Km</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
      </div>
    </div>
  )
}
