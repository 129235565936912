import { API } from 'aws-amplify'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import toast from 'react-hot-toast'
import AsyncSelect from 'react-select/async'
import * as XLSX from 'xlsx'
import { apiName, handleSearchRoute, handleSearchTransporter, handleSearchVehicles, selectColor } from './Utils'
import { useNavigate } from 'react-router-dom'

export default function ReportCard({ title, handleDownload, formatData, api, handleUpload }) {
    const [date, setDate] = useState({ start: moment().startOf('day').unix(), end: moment().endOf('day').unix() })
    const [loading, setLoading] = useState(false)
    const [vehicle, setVehicle] = useState(null)
    const [transporter, setTransporter] = useState(null)
    const [route, setRoute] = useState(null)
    const [routeName, setRouteName] = useState(null)
    const [data, setData] = useState([])
    const [routes, setRoutes] = useState(null)
    const navigate = useNavigate()

    async function handleView() {
        if (api === "daily-performance") {
            let days = moment.unix(date.end).diff(moment.unix(date.start), 'days')
            // if (days > 4) return toast.error("Max date range is 5 days.")
        }
        const loading = toast.loading("Loading...")
        setLoading(true)
        try {
            let query = `/report/${api}?start=${date?.start}&end=${date?.end}`
            if (vehicle) query = `${query}&vehicle_id=${vehicle}`
            if (transporter) query = `${query}&transporter_id=${transporter}`
            if (route) query = `${query}&route_id=${route}`

            let data = await await API.get(apiName, query);
            setData(data)

            if (api === "vehicle-attendance-report") {
                let query = `/report/vehicle-attendance-count?start=${date?.start}&end=${date?.end}`
                let query2 = `/report/vehicle-count?start=${date?.start}&end=${date?.end}`
                if (vehicle) query = query2 = `${query}&vehicle_id=${vehicle}`
                // if (vehicle) query2 = `${query}&vehicle_id=${vehicle}`
                if (transporter) query = query2 = `${query}&transporter_id=${transporter}`
                // if (transporter) query2 = `${query}&transporter_id=${transporter}`
                if (route) query = query2 = `${query}&route_id=${route}`
                // if (route) query2 = `${query}&route_id=${route}`

                let res = await API.get(apiName, query);
                let totalCountRes = await API.get(apiName, query2);
                return formatData(data, res, totalCountRes)
            }

            return formatData(data, routeName, routes)
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong !")
        } finally {
            setLoading(false)
            toast.dismiss(loading)
        }
    }

    async function handleDownload() {
        if (api === "daily-performance") {
            const loading = toast.loading('Downloading...')
            const response = await axios.get("https://" + data, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `DPR ${moment.unix(date?.start).format('DD-MMM-YYYY')} - ${moment.unix(date?.end).subtract(5, 'hours').subtract(30, 'minutes').format('DD-MMM-YYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();
            toast.success("Download successful.")
            toast.dismiss(loading);
            return
        }
        const loading = toast.loading("Downloading...")
        setLoading(true)
        let x = data
        try {
            if (data.length === 0) {
                let query = `/report/${api}?start=${date?.start}&end=${date?.end}`
                if (vehicle) query = `${query}&vehicle_id=${vehicle}`
                if (transporter) query = `${query}&transporter_id=${transporter}`
                if (route) query = `${query}&route_id=${route}`
                let data = await API.get(apiName, query);
                x = data
            }

            let temp = formatData(x, routeName, routes)
            if (temp.length === 0) return toast.error("No Data Found.")
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(temp);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, `${title} ${moment.unix(date?.start).format('DD-MMM-YYYY')} - ${moment.unix(date?.end).subtract(5, 'hours').subtract(30, 'minutes').format('DD-MMM-YYYY')}.xlsx`);
            toast.success("Download Successful.")
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong !")
        } finally {
            setLoading(false)
            toast.dismiss(loading)
        }
    }

    const handleDateApplied = (event, picker) => {
        const fromDate = moment(picker.startDate).startOf("day").unix()
        const toDate = Math.floor(moment(picker.endDate).endOf("day").unix());
        setDate({ start: fromDate, end: toDate });
    };

    const getRoutes = async () => {
        try {
            let routeRes = await API.get(apiName, `/route`)
            setRoutes(routeRes.items)
        } catch (error) {

        }
    }

    useEffect(() => {
        getRoutes()
    }, [])

    useEffect(() => {
        if (routes) handleView()
    }, [date, vehicle, transporter, route, routes])
    return (
        <>
            <h2 className='text-center'>{title}</h2>
            <div className='card p-2'>
                <div className='d-flex justify-content-end  align-items-center gap-2'>
                    <DateRangePicker
                        initialSettings={{
                            startDate: moment.unix(date?.start).format("MM-DD-YYYY"),
                            endDate: moment.unix(date?.end).subtract(5, 'hours').subtract(30, 'minutes').format("MM-DD-YYYY"),
                            linkedCalendars: true,
                            showCustomRangeLabel: true,
                            showDropdowns: true,
                            alwaysShowCalendars: true,
                            ranges: {
                                'Today': [moment(), moment()],
                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }
                        }}
                        onApply={handleDateApplied}
                    >
                        <input
                            className={`btn btn-light ml-2 pointer`}
                        />
                    </DateRangePicker>


                    <div style={{ width: '200px' }}>
                        <AsyncSelect isClearable theme={selectColor} required placeholder="Select Route" cacheOptions loadOptions={handleSearchRoute} defaultOptions onChange={(e) => {
                            setRouteName(e?.label)
                            setRoute(e?.value)
                        }} />
                    </div>
                    {title !== "Transporter Master Report" && (
                        <>
                            <div style={{ width: '200px' }}>
                                <AsyncSelect isClearable theme={selectColor} required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => setVehicle(e?.value)} />
                            </div>

                            <div style={{ width: '200px' }}>
                                <AsyncSelect isClearable theme={selectColor} required placeholder="Select Transporter" cacheOptions loadOptions={handleSearchTransporter} defaultOptions onChange={(e) => setTransporter(e?.value)} />
                            </div>

                        </>
                    )}

                    <button disabled={loading} onClick={handleDownload} type='button' style={{ width: 'fit-content' }} className='btn btn-primary'><i className='fe fe-download'></i></button>
                    {api === "daily-performance" && (
                        <>
                            <input type="file" name="file_upload" hidden id="file_upload" onChange={handleUpload} />
                            <label htmlFor="file_upload" className='btn btn-light'>
                                <i className='fe fe-upload' ></i>
                            </label>
                            <button className='btn btn-danger' title='Mismatch Gatepass List' onClick={() => navigate('/gatepass/list/mismatch')}>
                                <i className='fe fe-alert-triangle'></i>
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
