import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import NoAccess from '../components/NoAccess'
import { apiName, fileUpload, handleSearchRoute, selectColor } from '../components/Utils'
import Header from '../layouts/Header'
import AsyncSelect from 'react-select/async'
import { Button, Modal } from 'react-bootstrap'
import Select from 'react-select'

export default function Settings() {
    const allsettings = useSelector(state => state?.setting?.payload)
    const [settings, setSettings] = useState([])
    const [type, setType] = useState("")
    const [vehicleTypes, setVehicleTypes] = useState([])
    const [showTypeModal, setShowTypeModal] = useState(false)
    const [currentType, setCurrentType] = useState('')
    const [routes, setRoutes] = useState([])
    const [route, setRoute] = useState("")


    const [iconTypes, setIconTypes] = useState({})
    const [default_types, setDefault_types] = useState({
        unloaded: "",
        unloaded_tampered: "",
        unloaded_offline: "",
        unloaded_deviated: "",
        unloaded_suspicious: "",
        unloaded_idle: "",
        unloaded_halted: "",
        unloaded_ignition_off: "",
        unloaded_suspicious_deviated: "",
        unloaded_idle_deviated: "",
        unloaded_halted_deviated: "",
        unloaded_brakedown: "",
        loaded: "",
        loaded_tampered: "",
        loaded_offline: "",
        loaded_deviated: "",
        loaded_idle: "",
        loaded_halted: "",
        loaded_idle_deviated: "",
        loaded_halted_deviated: "",
        loaded_brakedown: ""
    })

    async function updateSetting(item) {
        try {
            await API.put(apiName, `/dashboard/settings/${item?.id}`, { body: item })
            toast.success(`${item?.name?.toUpperCase()} has been updated.`)
        } catch (error) {
            toast.error("Something went wrong !")
            console.log(error)
        }
    }

    async function upload(file, name) {
        let ext = file?.name?.split('.')[1]
        if (ext !== 'kml') return toast.error("Only KML files are allowed !")
        let link = await fileUpload(file)
        let item = settings.find(item => item?.name === name)
        let idx = settings.findIndex(item => item?.name === name)
        await API.put(apiName, `/dashboard/settings/${item?.id}`, { body: { ...item, value: link } })
        if (idx) {
            let temp = structuredClone(settings)
            temp[idx].value = link
            setSettings(temp)
        }
        toast.success("Kml Updated.")
    }
    async function uploadTypeIcon(file, item) {
        try {
            let link = await fileUpload(file)
            let temp = structuredClone(iconTypes)
            temp[item] = link
            setIconTypes(temp)
            let temp2 = structuredClone(currentType)
            temp2.icon = temp
            setCurrentType(temp2)
            let temp3 = structuredClone(vehicleTypes)
            temp3[currentType?.idx].icon = JSON.stringify(temp)
            setVehicleTypes(temp3)
        } catch (error) {
            console.log(error)
        }
    }

    async function addType() {
        try {
            if (type === "") return toast.error("Vehicle Type is Empty !")
            let loading = toast.loading("Adding...")
            await API.post(apiName, "/vehicle-type", { body: { name: type, icon: JSON.stringify({ unloaded: "https://bgr-storage143049-prod.s3.ap-south-1.amazonaws.com/public/activity-666tcliimzc.png" }) } })
            toast.success("Type Added Successfully.")
            toast.dismiss(loading)
            setType("")
            getTypes()
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong!")
        }
    }

    async function getTypes() {
        try {
            let res = await API.get(apiName, `/vehicle-type`)
            setVehicleTypes(res.items)
        } catch (error) {
            console.log(error)
        }
    }

    async function updateType(type) {
        try {
            if (type?.name === '') return toast.error("Type can't be blank !")
            await API.put(apiName, `/vehicle-type/${type?.id}`, { body: { ...type, icon: type.icon } })
            toast.success('Vehicle Type Updated')
            setShowTypeModal(false)
        } catch (error) {
            console.log(error)
        }
    }

    async function getRoutes() {
        try {
            let res = await API.get(apiName, '/route')
            setRoutes(res.items)
        } catch (error) {
            console.log(error)
        }
    }

    async function addRoute() {
        try {
            if (route.length == 0) return toast.error('Please type the route name !')
            await API.post(apiName, '/route', { body: { name: route, id: routes.at(-1).id + 1 } })
            setRoutes((prev) => ([...prev, { name: route, id: routes.at(-1).id + 1 }]))
        } catch (e) {
            console.log(e)
            toast.error("Something went wrong !")
        }
    }

    async function updateRoute(item) {
        try {
            if (+item.vehicle_count > 0) return toast.error("Oops ! Can't update the Route !")
            await API.put(apiName, `/route/${item?.id}`, { body: item })
            toast.success('Route name updated.')

        } catch (e) {
            console.log(e)
            toast.error("Something went wrong !")
        }
    }

    useEffect(() => {
        getTypes()
        getRoutes()
    }, [])

    useEffect(() => {
        setSettings(allsettings)
    }, [allsettings])

    const roles = useSelector((state) => state.userInfo.payload?.role)
    if (!roles?.includes("PARAMETERS")) {
        return <NoAccess />
    }


    return (
        <div>
            <Header name={"Parameters"} />
            <div className='row w-100'>
                <div className='col col-lg-4'>
                    <h2>General Settings</h2>
                    {settings?.map((item, idx) => (
                        <React.Fragment key={item?.name}>
                            {item?.name !== 'route' && item?.name !== 'geo_fence' && item?.name !== "default_route" && item?.name !== "block more than 2 dumka gatepass" && (
                                <div>
                                    <label>{item?.name?.toUpperCase()}</label>
                                    <div className='d-flex gap-2 align-items-center' >
                                        <input className='form-control w-100 my-2' type="text" value={item?.value} onChange={(e) => {
                                            let temp = structuredClone(settings)
                                            temp[idx].value = e.target.value
                                            setSettings(temp)
                                        }} />
                                        <button className='btn btn-primary' onClick={() => updateSetting(settings.find(x => x.name === item?.name))}><i className='fe fe-save' /></button>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                    <div>
                        <label>Block more than 2 dumka gatepass</label>
                        <div className='d-flex align-items-center gap-2'>
                            <div className='w-100'>
                                <Select
                                    theme={selectColor}
                                    defaultValue={{ label: allsettings.find(item => item.name === 'block more than 2 dumka gatepass')?.value === 'true' ? 'YES' : 'NO', value: allsettings.find(item => item.name === 'block more than 2 dumka gatepass')?.value }}
                                    options={[{ value: true, label: "YES" }, { value: false, label: "NO" }]}
                                    onChange={(e) => {
                                        let temp = structuredClone(settings)
                                        let idx = settings.findIndex(item => item.name === 'block more than 2 dumka gatepass')
                                        temp[idx].value = e?.value
                                        setSettings(temp)
                                    }}
                                />
                            </div>
                            <button className='btn btn-primary' onClick={() => updateSetting(settings.find(item => item.name === 'block more than 2 dumka gatepass'))}><i className='fe fe-save' /></button>
                        </div>
                    </div>
                    <div>
                        <label>Default Route for Gatepass</label>
                        <div className='d-flex align-items-center gap-2'>
                            <div className='w-100'>
                                <AsyncSelect theme={selectColor}
                                    defaultValue={settings ? JSON.parse(allsettings.find(item => item.name === 'default_route')?.value) : null}
                                    isClearable required placeholder="Select Route" cacheOptions loadOptions={handleSearchRoute} defaultOptions onChange={(e) => {
                                        let temp = structuredClone(settings)
                                        let idx = settings.findIndex(item => item.name === 'default_route')
                                        temp[idx].value = JSON.stringify(e)
                                        setSettings(temp)
                                    }} />
                            </div>
                            <button className='btn btn-primary' onClick={() => updateSetting(settings.find(item => item.name === 'default_route'))}><i className='fe fe-save' /></button>
                        </div>
                        <div className='row mt-5'>
                            <h2>Live Tracker Settings</h2>
                            <div className='col-lg-6'>
                                <label>Upload Route KML</label>
                                <input type="file" className='form-control my-2' accept='.kml' onChange={(e) => upload(e.target.files[0], 'route')} />
                                <a target='_blank' href={settings?.length > 0 ? settings.find(item => item?.name === "route")?.value : ""}>{settings?.length > 0 && settings.find(item => item?.name === "route")?.value}</a>
                            </div>
                            <div className='col-lg-6'>
                                <label>Upload GeoFence KML</label>
                                <input type="file" className='form-control my-2' accept='.kml' onChange={(e) => upload(e.target.files[0], 'geo_fence')} />
                                <a target='_blank' href={settings?.length > 0 ? settings.find(item => item?.name === "geo_fence")?.value : ""}>{settings?.length > 0 && settings.find(item => item?.name === "geo_fence")?.value}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col col-lg-4 align-content-between'>
                    <div>
                        <h2>Vehicle Types</h2>
                        <div className='col'>
                            <label>Add New Vehicle Type</label>
                            <div className='d-flex gap-2 align-items-center' >
                                <input className='form-control w-100 my-2' type="text" value={type} onChange={(e) => {
                                    setType(e.target.value)
                                }} />
                                <button className='btn btn-primary' onClick={() => addType()}><i className='fe fe-plus' /></button>
                            </div>
                            <div>
                                {vehicleTypes?.map((item, idx) => (
                                    <div className='d-flex gap-2 align-items-center' key={item?.id}>
                                        <input className='form-control w-100 my-2' type="text" value={item?.name} onChange={(e) => {
                                            let temp = structuredClone(vehicleTypes)
                                            let idx = temp.findIndex(x => x.id === item.id)
                                            temp[idx].name = e.target.value
                                            setVehicleTypes(temp)
                                        }} />
                                        <i className='pointer fe fe-upload-cloud p-3 bg-white rounded' onClick={() => {
                                            setCurrentType({ ...item, idx })
                                            setShowTypeModal(true)
                                            setIconTypes(item?.icon ? JSON.parse(item?.icon) : {})
                                        }}></i>
                                        <button className='btn btn-primary' onClick={() => updateType(item)}><i className='fe fe-save' /></button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>

                <div className='col col-lg-4 align-content-between'>
                    <h2>Routes</h2>
                    <div>
                        <div className='col'>
                            <label>Add New Route</label>
                            <div className='d-flex gap-2 align-items-center' >
                                <input className='form-control w-100 my-2' type="text" value={route} onChange={(e) => {
                                    setRoute(e.target.value)
                                }} />
                                <button className='btn btn-primary' onClick={() => addRoute()}><i className='fe fe-plus' /></button>
                            </div>
                            <div>
                                {routes?.map((item, idx) => (
                                    <div className='d-flex gap-2 align-items-center' key={item?.id}>
                                        <input disabled={+item?.vehicle_count !== 0} className='form-control w-100 my-2' type="text" value={item?.name} onChange={(e) => {
                                            let temp = structuredClone(routes)
                                            let idx = temp.findIndex(x => x.id === item.id)
                                            temp[idx].name = e.target.value
                                            setRoutes(temp)
                                        }} />
                                        {+item?.vehicle_count === 0 && (
                                            <button className='btn btn-primary' onClick={() => updateRoute(item)}><i className='fe fe-save' /></button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <Modal show={showTypeModal}>
                <Modal.Header>
                    <Modal.Title><b>Update Icons for {currentType?.name}</b></Modal.Title>
                    <div>
                        <Button variant="secondary" className='mx-2' onClick={() => setShowTypeModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => updateType(currentType)}>
                            Save
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='col'>
                        <div>
                            <Select placeholder='Selcet Type of Icon' theme={selectColor} options={Object.keys(default_types).map((item) => {
                                return {
                                    label: item, value: item
                                }
                            })} onChange={(e) => setIconTypes((prev) => ({ [e?.value]: "", ...prev }))} />
                            {Object.keys(iconTypes || {})?.map((item) => (
                                <div className='d-flex gap-2 mt-3 align-items-center justify-content-between border-bottom' key={item}>
                                    <h4 className='p-3'>{item}</h4>
                                    <input hidden type="file" accept='image/*' id={`file_upload${item}`} onChange={(e) => {
                                        uploadTypeIcon(e.target.files[0], item)
                                    }} />
                                    <div>
                                        {iconTypes[item] !== "" && <img src={iconTypes[item]} alt="icon" height={60} />}
                                        <label htmlFor={`file_upload${item}`}><i className='pointer fe fe-upload-cloud p-3 bg-white rounded'></i></label>
                                        {/* <i className='fe fe-x pointer' onClick={()=>{
                                            let temp = structuredClone(iconTypes)
                                            delete temp[item]
                                            setIconTypes(temp)
                                        }}></i> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
