import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import NoAccess from "../../components/NoAccess";
import Spinner from "../../components/Spinner";
import { apiName } from "../../components/Utils";
import Header from "../../layouts/Header";


export default function AddTransporter() {
  const navigate = useNavigate();
  const { id } = useParams();
  const route = useLocation()
  const path = route.pathname
  const userRole = useSelector((state) => state?.userInfo?.payload?.role)
  const [rawData, setRawData] = useState({})

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [transporter, setTransporter] = useState({
    name: "",
    phone: "",
    email: "",
    gst: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    postalCode: "",
    state: "",
  });

  function handleFormChange(field, value) {
    let temp = structuredClone(transporter);
    temp[field] = value;
    setTransporter(temp);
  }

  async function handleSubmit() {
    try {
      setSubmitting(true);
      let payload = {
        name: transporter?.name.trim(),
        phone: transporter?.phone || null,
        email: transporter?.email?.trim() || null,
        gstin: transporter?.gst?.trim(),
        pan_number: transporter?.pan_number?.trim(),
        address: {
          address1: transporter?.address1?.trim(),
          address2: transporter?.address2?.trim(),
          address3: transporter?.address3?.trim(),
          city: transporter?.city?.trim(),
          postalCode: transporter?.postalCode,
          state: transporter?.state?.trim(),
        },
        deleted: false
      }
      id ? await API.put(apiName, `/transporter/${id}`, { body: { ...payload, work_order: JSON.stringify(transporter.work_order) } }) : await API.post(apiName, "/transporter", { body: payload })
      toast.success(`Transporter ${id ? "updated" : "added"} successfully.`)
      navigate("/transporters");
    } catch (error) {
      console.log(error)
      setSubmitting(false);
      if (error?.errors?.length > 0 && error.errors[0].message.includes('phone')) {
        toast.error("Invalid phone number!")
        return;
      }
      id
        ? toast.error("Failed to update transporter!")
        : toast.error("Failed to add transporter!");
    }
  }

  async function getData() {
    setLoading(true);
    try {
      let res = await API.get(apiName, `/transporter/${id}`)
      setRawData(res)
      let { name, phone, email, gstin, address, pan_number } = res
      let temp = {
        name,
        phone,
        email,
        gst: gstin,
        address1: address?.address1,
        address2: address?.address2,
        address3: address?.address3,
        city: address?.city,
        country: address?.country,
        postalCode: address?.postalCode,
        state: address?.state,
        pan_number
      };
      setTransporter({ ...res, ...temp });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    id && getData();
  }, []);

  const onDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this transporter!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#407050',
      cancelButtonColor: 'black',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        API.put(apiName, `/transporter/${id}`, { body: { ...rawData, deleted: true, work_order: JSON.stringify(transporter.work_order) } }).then((res) => {
          Swal.fire(
            'Deleted!',
            'Transporter has been deleted.',
            'success'
          );
          navigate("/transporters")
        }).catch((err) => {
          toast.error("Something went wrong !")
          console.log(err)
        })
      }
    });
  }

  if (!userRole?.includes("TRANSPORTER")) return <NoAccess />;

  if (loading) return <Spinner show={true} />;

  return (
    <div className="container" >
      <Header name={id ? "Update Transporter" : "New Transporter"} />
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <form
            className="tab-content py-2"
            id="wizardSteps"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div>
              <div className="form-group">
                <label className="form-label mx-1">Transporter Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Eg. Anuj Prasad"
                  required
                  value={transporter.name || ""}
                  onChange={(e) => handleFormChange("name", e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="form-label mx-1">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Eg. anuj@gmail.com"
                  value={transporter?.email || ""}
                  onChange={(e) => handleFormChange("email", e.target.value)}
                />
              </div>

              <div className="form-group">
                <label className="form-label mx-1">GST Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Eg. 07AAAAA1234A1Z1"
                  maxLength={15}
                  value={transporter.gst || ""}
                  onChange={(e) => handleFormChange("gst", e.target.value.toUpperCase())}
                />
              </div>
              <div className="form-group">
                <label className="form-label mx-1">PAN Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Eg. JUESS0966J"
                  maxLength={15}
                  value={transporter.pan_number || ""}
                  onChange={(e) => handleFormChange("pan_number", e.target.value.toUpperCase())}
                />
              </div>
              <div className="form-group w-100">
                <label id="topic" className="form-label mx-1">Phone</label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  country={"in"}
                  value={transporter.phone || ""}
                  onChange={(phone) => handleFormChange("phone", "+" + phone)}
                />
              </div>
              <div className="row">
                <div className="form-group col-sm col-lg-4">
                  <label className="form-label mx-1">Address 1</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. Padum Nagar"
                    value={transporter.address1 || ""}
                    onChange={(e) => handleFormChange("address1", e.target.value)}
                  />
                </div>
                <div className="form-group col-sm col-lg-4">
                  <label className="form-label mx-1">Address 2</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. Church Street"
                    value={transporter?.address2 || ""}
                    onChange={(e) => handleFormChange("address2", e.target.value)}
                  />
                </div>
                <div className="form-group col-sm col-lg-4">
                  <label className="form-label mx-1">Landmark</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. Near Pakur Lake"
                    value={transporter?.address3 || ""}
                    onChange={(e) => handleFormChange("address3", e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm col-lg-4">
                  <label className="form-label mx-1">City</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. Pakur"
                    value={transporter?.city || ""}
                    onChange={(e) => handleFormChange("city", e.target.value)}
                  />
                </div>
                <div className="form-group col-sm col-lg-4">
                  <label className="form-label mx-1">State</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. Jharkhand"
                    value={transporter.state || ""}
                    onChange={(e) => handleFormChange("state", e.target.value)}
                  />
                </div>
                <div className="form-group col-sm col-lg-4">
                  <label className="form-label mx-1">Postal Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. Near Pakur Lake"
                    maxLength={6}
                    value={transporter.postalCode || ""}
                    onChange={(e) => handleFormChange("postalCode", e.target.value)}
                  />
                </div>
              </div>

              <div className="nav row align-items-center my-3">
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  <div className="col-auto">
                    <button
                      type="submit"
                      className="btn btn-primary fw-bold"
                      disabled={submitting}
                    >
                      {submitting ? (
                        <div className="spinner-border text-white"
                          style={{ height: "25px", width: "25px" }}
                          role="status "
                        ></div>
                      ) : (
                        <span>Save</span>
                      )}
                    </button>
                    {!submitting && (
                      <button onClick={() => navigate(path.includes("mobile") ? "/mobile/transporters" : "/transporters")}
                        type="button"
                        className="btn btn-light mx-2 fw-bold">Cancel</button>
                    )}
                    {id && !submitting && userRole?.includes("USERS") && (
                      <button onClick={() => onDelete(id)}
                        type="button"
                        className="btn btn-dark mx-2 fw-bold">Delete</button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}


