import { Authenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { apiName } from "../../components/Utils";
import AuthLayout from "../../layouts/AuthLayout";


const Signup = () => {
  const navigate = useNavigate();
  // formFields
  const formFields = {
    // signUp
    signUp: {
      email: {
        order: 2,
      },
      name: {
        order: 1,
      },
      password: {
        order: 3,
      },
      phone_number: {
        order: 5,
        dialCodeList: ['+91']
    },
      confirm_password: {
        order: 4,
      },
    },
  };

  //  components
  const components = {
    // SignUp
    SignUp: {
      Header() {
        return (
          <div className="text-center">
            <img src="/img/logo.svg" alt="BGR_Background_Image" className="img-fluid" width={200} />
            <h1 className="display-4 text-center mt-5 mb-9">Registration</h1>
          </div>
        );
      },
    },

    Footer() {
      return (
        <p className="text-center">
          Already have an account? <Link to="/">Sign In</Link>
        </p>
      );
    },

    // ConfirmSignUp
    ConfirmSignUp: {
      Header() {
        return (
          <h1 className="display-4 text-center mt-5 mb-9">Verify Account</h1>
        );
      },
    },

    ResetPassword: {
      Header() {
        <div className="d-flex justify-content-center">
          <h1 className="display-2 text-center mt-5 mb-9 mx-auto">
            Reset Password
          </h1>
          <p className="text-muted text-center">Reset Your OTP for free</p>
        </div>;
      },
    },
  };

  const handleCreateUser = async (uservalues) => {
    try {
      const { name, sub : id_ext, email, phone_number:phone } = uservalues.attributes;
      const init = {body : { name, email, phone, id_ext }}
      await API.post(apiName,'/user', init)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout>
      <Authenticator
        signUpAttributes={["phone_number"]}
        formFields={formFields}
        components={components}
        initialState="signUp"
      >
        {({ signOut, user }) => {
          handleCreateUser(user);
          return navigate("/wait");
        }}
      </Authenticator>
    </AuthLayout>
  );
};

export default Signup;