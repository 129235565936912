import { Pagination } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import NoAccess from "../../components/NoAccess";
import Spinner from "../../components/Spinner";
import * as XLSX from 'xlsx';
import {
  apiName,
  currentDate,
  delay,
  handleSearchRoute,
  handleSearchTransporter,
  limit,
  selectColor,
} from "../../components/Utils";

const Vehicles = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const selectRef = useRef(null);
  const resetRefBtn = useRef(null);

  const user = useSelector((state) => state.userInfo.payload);
  let transporterId = user?.transporter_id;
  const role = user?.role;

  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [route, setRoute] = useState(null);
  const [transporter, setTransporter] = useState(null);
  const [display, setDisplay] = useState(0);
  const [vehicleState, setVehicleState] = useState({});
  const [comment, setComment] = useState("");

  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [vehcleApproveList, setVehicleApproveList] = useState([]);
  const [status, setStatus] = useState(null);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);

  async function getData() {
    try {
      let query = `/vehicle?take=${limit}&skip=${count * limit
        }&keyword=${search}`;
      let active = null;
      let deleted = null;
      if (display === 0) active = true;
      if (display === 1) active = false;
      if (display === 2) {
        deleted = true;
        active = null;
      }

      if (active !== null) query = `${query}&active=${active}`;
      if (deleted !== null) query = `${query}&deleted=${deleted}`;
      if (transporter || transporterId)
        query = `${query}&transporter_id=${transporter || transporterId}`;
      if (display === 1) query = `${query}&active=${false}&deleted=${false}`;
      if (route) query = `${query}&route_id=${route}`;
      let response = await API.get(apiName, query);
      if (display === 1)
        response.items = response.items.filter(
          (item) => item?.deleted !== true
        );
      setVehicles(response.items);
      setTotal(response.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handlePaginationChange(newPageIndex) {
    setCount(newPageIndex - 1);
  }

  async function handleToggleVehicle(vehicleState) {
    const { id, comments, active } = vehicleState.vehicle;
    const { index } = vehicleState;
    try {
      let commentMsg;
      if (comments) {
        commentMsg = [
          {
            username: user?.name,
            msg: comment,
            time: currentDate(),
            active: status,
          },
          ...comments,
        ];
      } else {
        commentMsg = [
          {
            username: user?.name,
            msg: comment,
            time: currentDate(),
            active: status,
          },
        ];
      }

      await API.put(apiName, `/vehicle/${id}`, {
        body: {
          ...vehicleState.vehicle,
          active: !active,
          comments: commentMsg,
        },
      });

      let temp = structuredClone(vehicles);
      temp[index].active = !active;
      temp[index].comment = commentMsg;
      setVehicles(temp);

      temp[index].active
        ? toast.success(`${id} is active. `)
        : toast.error(`${id} is inactive!`);
      setShow(false);
      setVehicleApproveList([]);
    } catch (error) {
      console.log(error);
    }
  }

  function toggleMultiVehicle() {
    if (vehcleApproveList.length === 0)
      return toast.error("No vehicle selected!");
    vehcleApproveList.map((vehicleState) => {
      handleToggleVehicle(vehicleState);
    });
    setVehicleApproveList([]);
  }

  const handleUpload = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader();
    const fileType = file?.name.includes('xlsx') || file?.name.includes('xls') || file?.name.includes('csv'); // Extract file extension
    if (fileType) {
      reader.onload = async () => {
        const data = reader.result;
        const workbook = XLSX.read(data, { type: 'binary', cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, dateNF: 'yyyy-mm-dd', });
        for (let item of jsonData) {
          if (item[0] && item[1]) {
            await handleUpdateVehicleRCD(item)
          }
        }
      };
      reader.readAsBinaryString(file);
    } else {
      toast.error("Please upload excel file.")
    }
  }

  const handleUpdateVehicleRCD = async (vehicle) => {
    try {
      let data = await API.get(apiName, `/vehicle/${vehicle[0]}`)
      if (data?.id) {
        await API.put(apiName, `/vehicle/${data?.id}`, { body: { ...data, rcd_pin: vehicle[1] } })
        toast.success(`Updated ${vehicle[0]}`)
      }
    } catch (error) {
      toast.error(`Failed ${vehicle[0]}`)
      console.log(error)
    }

  }
  useEffect(() => {
    if (role?.includes("VEHICLE")) {
      setLoading(true);
      let timer = setTimeout(() => {
        getData();
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [path, role, search, count, transporter, route, display]);

  useEffect(() => {
    setComment("");
  }, [show]);

  if (!role?.includes("VEHICLE")) return <NoAccess />;

  return (
    <div className="container-fluid">
      {/* header starts */}
      <div className="row align-items-center">
        <div className="col">
          <h1 className="header-title text-truncate">Vehicles ({total})</h1>
        </div>
        {role && role?.includes("USERS") && user?.transporter_id === null && (
          <div className="col-auto">
            {vehcleApproveList?.length > 0 && (
              <>{vehcleApproveList?.length} selected</>
            )}
            <button
              className="btn btn-primary mx-2"
              onClick={() => {
                if (vehcleApproveList.length === 0)
                  return toast.error("No vehicle selected!");
                setShow(true);
                setStatus(true);
              }}
            >
              <i className="fe fe-check"></i>
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                if (vehcleApproveList.length === 0)
                  return toast.error("No vehicle selected!");
                setShow(true);
                setStatus(false);
              }}
            >
              <i className="fe fe-x"></i>
            </button>
          </div>
        )}
      </div>
      <hr />
      {/* header ends */}
      {path?.includes("list") && (
        <ul className="nav nav-tabs nav-overflow header-tabs mx-1 my-2">
          <li className="nav-item">
            <div
              className={`nav-link ${display === 0 ? "active" : ""} pointer`}
              onClick={() => setDisplay(0)}
            >
              <b>Active</b>
            </div>
          </li>
          <li className="nav-item">
            <div
              className={`nav-link ${display === 1 ? "active" : ""} pointer`}
              onClick={() => setDisplay(1)}
            >
              <b>Blocked</b>
            </div>
          </li>
          {(role?.includes("USERS") ||
            user?.approvalAccess === "CONTROL_ROOM") && (
              <li className="nav-item">
                <div
                  className={`nav-link ${display === 2 ? "active" : ""} pointer`}
                  onClick={() => setDisplay(2)}
                >
                  <b>Removed</b>
                </div>
              </li>
            )}
          <li className="nav-item">
            <div
              className={`nav-link ${display === 3 ? "active" : ""} pointer`}
              onClick={() => setDisplay(3)}
            >
              <b>All</b>
            </div>
          </li>
        </ul>
      )}
      <div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              >
                <div className="card">
                  <div
                    className={window.innerWidth > 750 ? "card-header" : "p-2"}
                  >
                    <div
                      className={
                        window.innerWidth > 750 ? "row align-items-center" : ""
                      }
                    >
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            autoFocus
                            className="form-control list-search"
                            type="search"
                            placeholder="Search by vehicle number"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value.toUpperCase());
                            }}
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                      </div>
                      {(display === 0 || display === 3) && (
                        <>
                          <div
                            className="col-auto mt-1"
                            style={{
                              width: window.innerWidth > 750 ? "17rem" : "auto",
                            }}
                          >
                            {user?.transporter_id === null && (
                              <AsyncSelect
                                isClearable
                                theme={selectColor}
                                required
                                placeholder="Select Transporter"
                                cacheOptions
                                loadOptions={handleSearchTransporter}
                                defaultOptions
                                onChange={(e) => setTransporter(e?.value)}
                              />
                            )}
                          </div>
                          <div className="col-auto">
                            <AsyncSelect
                              isClearable
                              theme={selectColor}
                              required
                              placeholder="Select Route"
                              cacheOptions
                              loadOptions={handleSearchRoute}
                              defaultOptions
                              onChange={(e) => setRoute(e?.value)}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-auto my-2" title="Excel File must have two columns 1.Vehicle_no 2.RCD PIN">
                        <button className="btn btn-dark" onClick={() => setShowUpload(true)}>
                          Update RCD Pin</button>
                      </div>
                      <div className="col-auto px-1">
                        <button
                          ref={resetRefBtn}
                          className="btn btn-light"
                          onClick={() => {
                            setTransporter(null);
                            setSearch("");
                            setRoute(null);
                            setVehicleApproveList([]);
                          }}
                        >
                          <i className="fe fe-x" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <Spinner show={loading}>
                    <div
                      className={"table-responsive"}
                      style={{ height: "64vh" }}
                    >
                      <table className="table table-sm table-hover table-nowrap card-table">
                        <thead className="sticky-top" style={{ zIndex: 0 }}>
                          <tr>
                            <th></th>
                            <th>Vehicle</th>
                            <th>Transport Name</th>
                            <th>RFID</th>
                            <th>RCD Pin</th>
                            <th>Route</th>
                            <th>Type</th>
                            <th className="text-center">
                              Created Date(DD-MM-YYYY)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {vehicles?.map((vehicle, index) => (
                            <tr
                              key={vehicle.id}
                              className="pointer"
                              onClick={() => navigate(`/vehicle/${vehicle.id}`)}
                            >
                              <td onClick={(e) => e.stopPropagation()}>
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setVehicleApproveList((prev) => [
                                        ...prev,
                                        { vehicle, index },
                                      ]);
                                    } else {
                                      let temp =
                                        structuredClone(vehcleApproveList);
                                      temp = temp.filter(
                                        (x) => x.vehicle.id !== vehicle.id
                                      );
                                      setVehicleApproveList(temp);
                                    }
                                  }}
                                />{" "}
                              </td>
                              <td>{vehicle?.id}</td>
                              <td>{vehicle?.transporter_name || "_"}</td>
                              <td>{vehicle?.rfid || "_"}</td>
                              <td>{vehicle?.rcd_pin || "_"}</td>
                              <td>{vehicle?.route_name || "_"}</td>
                              <td>{vehicle?.type_name}</td>
                              <td className="text-center">
                                {moment
                                  .unix(vehicle?.created)
                                  .format("DD-MM-YYYY")}
                              </td>
                              {role &&
                                role?.includes("USERS") &&
                                user?.transporters?.items?.length === 0 &&
                                display === 0 && (
                                  <td>
                                    <div
                                      className="form-check form-switch fit"
                                      onClick={async (e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <input
                                        disabled
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={vehicle?.active ? true : false}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexSwitchCheckChecked"
                                      ></label>
                                    </div>
                                  </td>
                                )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {vehicles?.length === 0 && !loading && (
                        <p className="text-center m-3">No vehicles </p>
                      )}
                    </div>
                    <div className="card-footer d-flex justify-content-center">
                      <Pagination
                        currentPage={count + 1}
                        totalPages={Math.ceil(total / limit)}
                        siblingCount={1}
                        onNext={() => setCount((prev) => prev + 1)}
                        onPrevious={() => setCount((prev) => prev - 1)}
                        onChange={handlePaginationChange}
                      />
                    </div>
                  </Spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            toggleMultiVehicle();
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>Change Status {vehicleState?.id}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              required
              className="form-control"
              placeholder="Enter reason"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              maxLength={60}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              <b>Make {!status ? "Inactive" : "active"}</b>
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal centered show={showUpload} onHide={() => setShowUpload(false)}>
        <Modal.Body >
          <h3 className="m-0">Please make sure the format of the excel file is as below </h3>
          <ul className="mt-4">
            <li>First Column - Vehicle Number</li>
            <li>Second Column - RCD Pin</li>
          </ul>

        </Modal.Body >
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpload(false)}>
            Close
          </Button>
          <>
            <input type="file" name="file_upload" hidden id="file_upload" onChange={handleUpload} />
            <label htmlFor="file_upload" className='btn btn-dark' >
              <i className='fe fe-upload mx-2' ></i>
              Upload Excel File
            </label>
          </>
        </Modal.Footer>
      </Modal >
    </div >
  );
};

export default Vehicles;
