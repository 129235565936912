import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import NoAccess from '../../components/NoAccess';
import ReportCard from '../../components/ReportCard';
import axios from 'axios';
import { apiName } from '../../components/Utils';
import { API } from 'aws-amplify';


export default function DRP() {
    const [data, setData] = useState([])

    async function formatData(data) {
        const response = await axios.get("https://" + data, { responseType: 'arraybuffer' });

        const x = response.data;
        const workbook = XLSX.read(x, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        jsonData.shift()
        const res = jsonData.map((item) => {
            let report = {
                "S.No": item[0],
                Date: item[1],
                Gate_Pass_No: item[2],
                JIMMS_Challan_No: item[3],
                Trip_Id: item[4],
                Transporter_Name: item[5],
                Driver_Name: item[6],
                Vehicle_Id: item[7],
                Tare_WT: item[8],
                GW_01_Mines: item[9],
                Net_WT_01_Mines: item[10],
                Route: item[11],
                In_Time_Mines: item[12],
                Out_Time_Mines: item[13],
                Coal_Mine_Duration: item[14],
                In_Time_RLW: item[15],
                Tare_WT_RLW: item[16],
                GW_02_RLW: item[17],
                Net_WT_02_Railway: item[18],
                Coal_Ton_Variation: item[19],
                Out_Time_RLW: item[20],
                Trip_Duration: item[21],
                weighBridgeExitAt: item[22].replace(",", ""),
                weighBridgeDuration: item[23],
                Mine_Entry_Lane: item[24],
                Mine_Exit_Lane: item[25]
            };
            return report
        })
        setData(res)
        return data
    }

    const handleUpload = (e) => {
        const file = e.target.files[0]
        const reader = new FileReader();
        const fileType = file?.name.includes('xlsx') || file?.name.includes('xls'); // Extract file extension
        if (fileType) {
            reader.onload = () => {
                const data = reader.result;
                const workbook = XLSX.read(data, { type: 'binary', cellDates: true });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, dateNF: 'yyyy-mm-dd', });
                const mainData = []
                for (let item of jsonData) {
                    let date = item[4]
                    if (typeof date === "string") {
                        date = date.replace("PM", "")
                    }
                    date = moment(date).format('DD-MMM-YYYY hh:mm A')
                    const payload = {
                        vehicle_id: item[5],
                        JIMMS_Challan_No: item[2],
                        issued_on: date
                    }
                    mainData.push(payload)
                }
                mainData.shift()
                getJimmsInfo(mainData)
            };
            reader.readAsBinaryString(file);
        } else {
            toast.error("Please upload excel file.")
        }
    }

    async function getJimmsInfo(uploadedData) {
        try {
            let finalItems = []
            if (uploadedData?.length > 0) {
                let itemsToUpdate = []

                let allJims = uploadedData.map(x => { return x.JIMMS_Challan_No })
                for (let x of data) {
                    if (allJims.includes(x?.JIMMS_Challan_No) !== true) {
                        if (x?.JIMMS_Challan_No) itemsToUpdate.push(x)
                    }
                }

                for (let item of itemsToUpdate) {
                    let jimms = uploadedData.find(x => {
                        var date1 = moment(x.issued_on, 'DD-MMM-YYYY, hh:mm A');
                        var date2 = moment(item.weighBridgeExitAt, 'DD-MM-YYYY hh:mm A');
                        var diff = date2.diff(date1, 'hours');
                        return x.vehicle_id === item?.Vehicle_Id && diff < 2
                    })
                    if (jimms) {
                        jimms.gatepass_no = item?.Gate_Pass_No
                        finalItems.push(jimms)
                    }
                }
                if (finalItems?.length > 0) {
                    update(finalItems)
                } else {
                    toast.error("No data for correction")
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const update = async (data) => {
        try {
            let count = 0
            for (const item of data) {
                count = count + 1
                const loading = toast.loading(`Updating ${count} of ${data?.length}`)
                const res = await API.get(apiName, `/gatepass/${item?.gatepass_no}`)
                await API.put(apiName, `/gatepass/${item?.gatepass_no}`, { body: { ...res, challan_number: item?.JIMMS_Challan_No } })
                toast.dismiss(loading)
            }
            toast.success("Jimms Updated.")
        } catch (error) {
            console.log(error)
        }
    }

    const roles = useSelector((state) => state.userInfo.payload?.role)
    if (!roles?.includes("DAILY-PERFORMANCE")) {
        return <NoAccess />
    }

    return (
        <>
            <ReportCard title={"Daily Performance Report"} formatData={formatData} api={'daily-performance'} handleUpload={handleUpload} />
            <div className="table-responsive card">
                <table className="table-hover table-bordered">
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                        <tr>
                            {data?.length > 0 && Object.keys(data[0])?.map(item => (
                                <th className={`fs-5 ${item !== 'Transporter_Name	' || item !== 'Driver_Name' && "text-center"}`} key={item}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(0, 1000)?.map((item, idx) => (
                            <tr key={idx}>
                                <td align='center' className='fs-5'>{item["S.No"]}</td>
                                <td align='center' className='fs-5'>{item["Date"]}</td>
                                <td align='center' className='fs-5'>{item["Gate_Pass_No"]}</td>
                                <td align='left' className='fs-5'>{item["JIMMS_Challan_No"]}</td>
                                <td align='center' className='fs-5'>{item["Trip_Id"]}</td>
                                <td className='fs-5'>{item["Transporter_Name"]}</td>
                                <td className='fs-5'>{item["Driver_Name"]}</td>
                                <td align='center' className='fs-5'>{item["Vehicle_Id"]}</td>
                                <td align='center' className='fs-5'>{item["Tare_WT"]}</td>
                                <td align='center' className='fs-5'>{item["GW_01_Mines"]}</td>
                                <td align='center' className='fs-5'>{item["Net_WT_01_Mines"]}</td>
                                <td align='center' className='fs-5'>{item["Route"]}</td>
                                <td align='center' className='fs-5'>{item["In_Time_Mines"]}</td>
                                <td align='center' className='fs-5'>{item["Out_Time_Mines"]}</td>
                                <td align='center' className='fs-5'>{item["Coal_Mine_Duration"]}</td>
                                <td align='center' className='fs-5'>{item["In_Time_RLW"]}</td>
                                <td align='center' className='fs-5'>{item["Tare_WT_RLW"]}</td>
                                <td align='center' className='fs-5'>{item["GW_02_RLW"]}</td>
                                <td align='center' className='fs-5'>{item["Net_WT_02_Railway"]}</td>
                                <td align='center' className='fs-5'>{item["Coal_Ton_Variation"]}</td>
                                <td align='center' className='fs-5'>{item["Out_Time_RLW"]}</td>
                                <td align='center' className='fs-5'>{item["Trip_Duration"]}</td>
                                <td align='center' className='fs-5'>{item["weighBridgeExitAt"]}</td>
                                <td align='center' className='fs-5'>{item["weighBridgeDuration"]}</td>
                                <td align='center' className='fs-5'>{item["Mine_Entry_Lane"]}</td>
                                <td align='center' className='fs-5'>{item["Mine_Exit_Lane"]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
