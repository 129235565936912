import moment from 'moment'
import React, { useState } from 'react'
import GpsReport from '../../components/GpsReport'
import { useSelector } from 'react-redux'
import NoAccess from '../../components/NoAccess'

export default function Speeding() {
  const [data, setData] = useState([])

  const formatData = (x) => {
    const res = x.map(item => {
      let report = {
          "Date": moment.unix(item?.ts).format("DD-MMM-YYYY, hh:mm A"),
          "Speed" : (item?.speed || 0) + 'Km/h',
      };
      return report
  })
    setData(x)
    return res
  }

  const roles = useSelector((state)=>state.userInfo.payload?.role)
  if(!roles?.includes("SPEEDING")) return <NoAccess/>

  return (
    <div>
      <GpsReport title={'Speeding Report'} api={'over-speed'} formatData={formatData} />
      <div className="card">
        <table className='table-hover table-bordered'>
          <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
            <tr>
              <th className="fs-5 text-center">Date</th>
              <th className="fs-5 text-center">Speed</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <tr key={idx}>
                <td align="center">{moment.unix(item?.ts).format("DD-MMM-YYYY, hh:mm A")}</td>
                <td align="center">{item?.speed || 0} Km/h</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
      </div>
    </div>
  )
}
