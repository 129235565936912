import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import DropUpload from "../../components/DropUpload";
import NoAccess from "../../components/NoAccess";
import Spinner from "../../components/Spinner";
import {
  apiName,
  fileUpload,
  getNextDate,
  handleSearchDriver,
  handleSearchRoute,
  handleSearchTransporter,
  handleSearchVehicleType,
  selectColor,
} from "../../components/Utils";
import Header from "../../layouts/Header";
import Select from 'react-select'

export default function AddVehicle() {
  const user = useSelector((state) => state?.userInfo?.payload);
  const navigate = useNavigate();
  const { id } = useParams();
  const route = useLocation();
  const path = route.pathname;
  const isRequest = path.includes("/vehicle/request/edit");

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [woNo, setWoNo] = useState("");
  const [transporterWoNo, setTransporterWoNo] = useState([]);
  const [requestData, setRequestData] = useState({});

  const [vehicle, setVehicle] = useState({
    number: "",
    model: "",
    make: "",
    type: null,
    transporter: null,
    route: null,
    driver: null,
    rfidNumber: "",
    gpsNumber: "",
    capacity: "",
    insuranceNumber: "",
    insuranceStart: "",
    insuranceEnd: "",
    weight: "",
    chassis: "",
    engine: "",
    noOfTyres: "",
    rcd_pin: "",
    recomended_by: ""
  });

  async function handleDrop(file) {
    let temp = structuredClone(documents);
    if (!Array.isArray(temp)) temp = [];
    temp.push({
      type: file?.type,
      url: await fileUpload(file),
    });

    setDocuments(temp);
  }

  function handleFormChange(field, value) {
    let temp = structuredClone(vehicle);
    temp[field] = value;
    setVehicle(temp);
  }

  async function handleSubmit() {
    setSubmitting(true);
    try {
      if (isRequest && requestData?.approved_by_control_room)
        return toast.error("Vehicle is already approved !");

      let res = await API.get(apiName, `/vehicle/${vehicle?.number}`);

      if (res !== null && id === undefined) {
        toast.error("Vehicle already exists!");
      } else {
        let transporter = await API.get(
          apiName,
          `/transporter/${vehicle?.transporter?.id}`
        );
        let payload = transporter;
        let isExists = transporterWoNo?.find(
          (item) =>
            item?.route?.toLowerCase() === vehicle?.route?.name.toLowerCase()
        );
        if (isExists) {
          let index = transporterWoNo?.indexOf(isExists);
          transporterWoNo[index].WoNo = woNo;
          transporterWoNo[index].number = woNo;
          payload = { ...payload, work_order: JSON.stringify(transporterWoNo) };
          await API.put(apiName, `/transporter/${vehicle?.transporter?.id}`, {
            body: payload,
          });
        }

        if (isExists === undefined) {
          payload = {
            ...payload,
            work_order: JSON.stringify([
              ...transporterWoNo,
              { route: vehicle?.route?.name, number: woNo },
            ]),
          };
          await API.put(apiName, `/transporter/${vehicle?.transporter?.id}`, {
            body: payload,
          });
        }

        let input = {
          id: id && !isRequest ? id : vehicle?.number.trim(),
          transporter_id: vehicle?.transporter?.id,
          route_id: vehicle?.route?.id,
          driver_id: vehicle?.driver?.id,
          type_id: vehicle?.type?.id,
          type_name: vehicle?.type?.name,
          model: vehicle?.model?.trim(),
          make: vehicle?.make?.trim(),
          documents: documents,
          rfid: vehicle?.rfidNumber?.trim() || null,
          gps_number: vehicle?.gpsNumber?.trim() || null,
          capacity: +vehicle?.capacity,
          insurance: {
            number: vehicle?.insuranceNumber?.trim(),
            start: moment(vehicle?.insuranceStart).unix(),
            end: moment(vehicle?.insuranceEnd).unix(),
          },
          weight: +vehicle?.weight,
          details: {
            chassis: vehicle?.chassis,
            engine: vehicle?.engine,
            noOfTyres: vehicle?.noOfTyres,
          },
          deleted: false,
          route_name: vehicle?.route?.name,
          transporter_name: vehicle?.transporter?.name,
          rcd_pin: vehicle?.rcd_pin,
          gps_type: vehicle?.gps_type,
          recomended_by: vehicle?.recomended_by
        };
        const createVehicle =
          vehicle?.type?.name === "SECURITY" || vehicle?.type?.name === "BGR";
        if (!id) {
          input = {
            ...input,
            active: true,
            loaded: false,
            approved: createVehicle ? true : false,
          };
        }
        if (
          user?.role?.includes("APPROVAL_DISPATCH_MANAGER") ||
          user?.role?.includes("APPROVAL_CONTROL_ROOM")
        ) {
          if (!id) {
            if (!createVehicle)
              await API.post(apiName, `/vehicle-request`, {
                body: {
                  type: "ADD",
                  new_vehicle_id: vehicle?.number.trim(),
                  new_vehicle_details: input,
                },
              });
            else API.post(apiName, `/vehicle`, { body: input });
          } else if (isRequest)
            await API.put(apiName, `/vehicle-request/${id}`, {
              body: {
                ...requestData,
                new_vehicle_id: vehicle?.number.trim(),
                new_vehicle_details: input,
              },
            });
          else
            await API.put(apiName, `/vehicle/${id}`, {
              body: { ...input, ...vehicle, approved: true },
            });
        } else {
          toast.error("You don't have access");
          setSubmitting(false);
          return;
        }
        toast.success(`Vehicle ${id ? "updated" : "created"} successfully.`);
        navigate(
          (id && !isRequest) || createVehicle
            ? "/vehicle/list"
            : "/vehicle/request-list"
        );
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    } finally {
      setSubmitting(false);
    }
  }

  async function getData() {
    try {
      setLoading(true);
      let vehicle;
      if (id) {
        let res = await API.get(apiName, `/vehicle/${id}`);
        vehicle = res;
      }
      if (isRequest) {
        const res = await API.get(apiName, `/vehicle-request/${id}`);
        vehicle = res?.new_vehicle_details;
        setRequestData(res);
      }
      let chassis, engine, noOfTyres;
      if (vehicle?.details) {
        chassis = vehicle?.details.chassis;
        engine = vehicle?.details.engine;
        noOfTyres = vehicle?.details.noOfTyres;
      }
      let temp = {
        number: vehicle?.id,
        model: vehicle?.model,
        make: vehicle?.make,
        type: vehicle?.type,
        transporter: {
          id: vehicle?.transporter_id,
          name: vehicle?.transporter_name,
        },
        route: { id: vehicle?.route_id, name: vehicle?.route_name },
        driver: { id: vehicle?.driver_id, name: vehicle?.driver_name },
        type: { id: vehicle?.type_id, name: vehicle?.type_name },
        rfidNumber: vehicle?.rfid,
        gpsNumber: vehicle?.gps_number,
        capacity: vehicle?.capacity,
        insuranceNumber: vehicle?.insurance?.number,
        weight: vehicle?.weight,
        chassis,
        insuranceStart: vehicle?.insurance?.start
          ? moment.unix(vehicle?.insurance?.start).format("YYYY-MM-DD")
          : null,
        insuranceEnd: vehicle?.insurance?.end
          ? moment.unix(vehicle?.insurance?.end).format("YYYY-MM-DD")
          : null,
        engine,
        noOfTyres,
        comments: vehicle?.comments,
        active: vehicle?.active,
        rcd_pin: vehicle?.rcd_pin,
        gps_type: vehicle?.gps_type,
        recomended_by: vehicle?.recomended_by
      };
      vehicle?.documents && setDocuments(vehicle?.documents);
      getWoNumber(vehicle?.transporter_id, vehicle?.route_name);
      setVehicle(temp);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getWoNumber(transporterId, route) {
    const loading = toast.loading("Loading...");
    try {
      let res = await API.get(apiName, `/transporter/${transporterId}`);
      let temp = res?.work_order;
      if (temp) setTransporterWoNo(temp);
      let number = temp?.find(
        (item) => route.toLowerCase() === item?.route?.toLowerCase()
      );
      if (number) setWoNo(number?.number);
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
    }
  }

  useEffect(() => {
    if (user?.role?.includes("VEHICLE")) {
      getData();
    }
  }, [id, user]);

  if (!user?.role?.includes("VEHICLE")) return <NoAccess />;

  if (loading) return <Spinner show={true} />;

  return (
    <div className="container mt-4">
      <Header
        name={
          id ? `Update Vehicle ${isRequest ? " Request" : ""} ` : "New Vehicle"
        }
      />
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <form
            className="tab-content py-2"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div>
              <div className="card px-3 py-4 row-grid">
                <div className="w-100">
                  <label className="form-label mx-1">
                    Registration Number <span className="text-danger">*</span>
                  </label>

                  <input
                    disabled={id && !isRequest ? true : false}
                    type="text"
                    className="form-control"
                    placeholder="Eg. OD20N6758"
                    required
                    value={vehicle.number || ""}
                    maxLength={10}
                    minLength={9}
                    onChange={async (e) => {
                      handleFormChange("number", e.target.value.toUpperCase());
                    }}
                  />
                </div>

                <div className=" w-100">
                  <label id="topic" className="form-label mx-1">
                    {" "}
                    Make{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. Tata Motors"
                    value={vehicle.make || ""}
                    onChange={(e) => handleFormChange("make", e.target.value)}
                  />
                </div>

                <div>
                  <label className="form-label mx-1">Model</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. 3718"
                    maxLength={20}
                    value={vehicle.model || ""}
                    onChange={(e) => handleFormChange("model", e.target.value)}
                  />
                </div>
                <div>
                  <label className="form-label mx-1">
                    Type <span className="text-danger">*</span>
                  </label>
                  <AsyncSelect
                    isClearable
                    theme={selectColor}
                    defaultValue={
                      vehicle?.type
                        ? {
                          label: vehicle.type?.name,
                          value: vehicle?.type?.id,
                        }
                        : null
                    }
                    required
                    placeholder="Select Vehicle Type"
                    cacheOptions
                    loadOptions={handleSearchVehicleType}
                    defaultOptions
                    onChange={(e) =>
                      setVehicle((prev) => ({
                        ...prev,
                        type: { id: e?.value, name: e?.label },
                      }))
                    }
                  />
                </div>
                <div>
                  <label className="form-label mx-1">
                    Transporter <span className="text-danger">*</span>
                  </label>
                  <AsyncSelect
                    required
                    isClearable
                    theme={selectColor}
                    defaultValue={
                      vehicle?.transporter?.id
                        ? {
                          label: vehicle?.transporter?.name,
                          value: vehicle?.transporter?.id,
                        }
                        : null
                    }
                    placeholder="Select Transporter"
                    cacheOptions
                    loadOptions={handleSearchTransporter}
                    defaultOptions
                    onChange={(e) => {
                      setWoNo("");
                      setVehicle((prev) => ({
                        ...prev,
                        transporter: { id: e?.value, name: e?.label },
                      }));
                      if (vehicle?.route)
                        getWoNumber(e?.value, vehicle?.route?.name);
                    }}
                  />
                </div>
                <div>
                  <label className="form-label mx-1">
                    Route <span className="text-danger">*</span>
                  </label>
                  <AsyncSelect
                    isClearable
                    theme={selectColor}
                    defaultValue={
                      vehicle?.route?.id
                        ? {
                          label: vehicle?.route?.name,
                          value: vehicle?.route?.id,
                        }
                        : null
                    }
                    required
                    placeholder="Select Route"
                    cacheOptions
                    loadOptions={handleSearchRoute}
                    defaultOptions
                    onChange={(e) => {
                      setWoNo("");
                      let temp = structuredClone(vehicle);
                      let obj = { id: e?.value, name: e?.label };
                      temp.route = obj;
                      setVehicle(temp);
                      if (vehicle?.transporter)
                        getWoNumber(vehicle?.transporter?.id, e?.label);
                    }}
                  />
                </div>
                <div>
                  <label className="form-label mx-1">
                    WO Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. 23"
                    required
                    value={woNo}
                    onChange={(e) => setWoNo(e.target.value)}
                  />
                </div>
                <div>
                  <label className="form-label mx-1">Driver</label>
                  <AsyncSelect isClearable theme={selectColor}
                    defaultValue={
                      vehicle?.driver?.id
                        ? {
                          label: vehicle?.driver?.name,
                          value: vehicle?.driver?.id,
                        }
                        : null
                    }
                    placeholder="Select Driver" loadOptions={handleSearchDriver} defaultOptions onChange={(e) => {
                      setVehicle((prev) => ({
                        ...prev,
                        driver: { id: e?.value, name: e?.label },
                      }));
                    }} />
                </div>
                <div>
                  <label className="form-label mx-1">RCD Pin</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Eg. 324521"
                    value={vehicle?.rcd_pin}
                    onChange={(e) =>
                      setVehicle((prev) => ({
                        ...prev,
                        rcd_pin: e.target.value,
                      }))
                    }
                  />
                </div>
                <div>
                  <label className="form-label mx-1">GPS Type</label>
                  <Select
                    theme={selectColor}
                    defaultValue={{ label: vehicle?.gps_type, value: vehicle?.gps_type }}
                    options={[{ value: 'NEW', label: 'NEW' }, { value: 'REPALCEMENT', label: 'REPALCEMENT' }, { value: 'OWN DEVICE', label: 'OWN DEVICE' }, { value: 'OTHER', label: 'OTHER' }]}
                    onChange={(e) => {
                      setVehicle((prev) => ({ ...prev, gps_type: e?.value }))
                    }}
                  />
                </div>
              </div>

              <div className="card px-3 py-4 row-grid">
                <div className=" w-100">
                  <label className="form-label mx-1">Chassis Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. MAT808015N1E14780"
                    value={vehicle.chassis || ""}
                    maxLength={30}
                    onChange={(e) =>
                      handleFormChange("chassis", e.target.value.toUpperCase())
                    }
                  />
                </div>
                <div className=" w-100">
                  <label className="form-label mx-1"> Engine Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. B6.7B6A250D02122E64214145"
                    value={vehicle.engine || ""}
                    maxLength={40}
                    onChange={(e) =>
                      handleFormChange("engine", e.target.value.toUpperCase())
                    }
                  />
                </div>
                <div className=" w-100">
                  <label className="form-label mx-1">RFID Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. FASDF9798745RE"
                    value={vehicle.rfidNumber || ""}
                    maxLength={30}
                    onChange={(e) =>
                      handleFormChange(
                        "rfidNumber",
                        e.target.value.toUpperCase()
                      )
                    }
                  />
                </div>
                <div className=" w-100">
                  <label className="form-label mx-1">GPS Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. 7987S4"
                    value={vehicle.gpsNumber || ""}
                    maxLength={30}
                    onChange={(e) =>
                      handleFormChange(
                        "gpsNumber",
                        e.target.value.toUpperCase()
                      )
                    }
                  />
                </div>
                <div className=" w-100">
                  <label className="form-label mx-1">Capacity</label>
                  <input
                    min={1000}
                    type="number"
                    className="form-control"
                    placeholder="Eg. 3991"
                    value={vehicle.capacity || ""}
                    onChange={(e) =>
                      handleFormChange("capacity", e.target.value)
                    }
                  />
                </div>
                <div className=" w-100">
                  <label className="form-label mx-1">Tare Weight</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Eg. 7987"
                    value={vehicle.weight || ""}
                    min={1000}
                    onChange={(e) => handleFormChange("weight", e.target.value)}
                  />
                </div>
                <div className="w-100">
                  <label className="form-label mx-1">No. of Tyres</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Eg. 12"
                    value={vehicle.noOfTyres || ""}
                    maxLength={2}
                    onChange={(e) =>
                      e.target.value.length <= 2 &&
                      handleFormChange("noOfTyres", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="card px-3 py-4 row-grid">
                <div className=" w-100">
                  <label className="form-label mx-1">Recomended by</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. Rajesh"
                    value={vehicle?.recomended_by || ""}
                    maxLength={30}
                    onChange={(e) =>
                      handleFormChange("recomended_by", e.target.value)
                    }
                  />
                </div>
              </div>

              <div>
                <label className="form-label mx-1">
                  Documents (Upload only Images and PDF)
                </label>
                <DropUpload
                  state={documents}
                  handleDrop={handleDrop}
                  setState={setDocuments}
                />
              </div>

              <div className="nav row align-items-center mb-3">
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  <div className="col-auto">
                    {(user?.role?.includes("APPROVAL_DISPATCH_MANAGER") ||
                      user?.role?.includes("APPROVAL_CONTROL_ROOM") ||
                      user?.role?.includes("USER")) && (
                        <button
                          type="submit"
                          className="btn btn-primary fw-bold"
                          disabled={
                            submitting ||
                            !vehicle.type ||
                            !vehicle.transporter ||
                            !vehicle.route
                          }
                        >
                          {submitting ? (
                            <div
                              className="spinner-border text-white"
                              style={{
                                height: "25px",
                                width: "25px",
                              }}
                              role="status "
                            ></div>
                          ) : (
                            <span>Save</span>
                          )}
                        </button>
                      )}
                    {!submitting && (
                      <button
                        onClick={() => navigate("/vehicle/list")}
                        type="submit"
                        className="btn btn-light mx-2 fw-bold"
                        disabled={submitting}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                )}
              </div>
              {vehicle?.comments && (
                <div className="table-responsive bg-white my-5">
                  <h3 className="px-3 pt-3">Status History</h3>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Reason</th>
                        <th>Status</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehicle?.comments &&
                        vehicle?.comments?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.username}</td>
                            <td>{item?.msg}</td>
                            <td>{item?.active ? "Active" : "Block"}</td>
                            <td style={{ minWidth: "200px" }}>
                              {moment(item?.time).format(
                                "DD-MM-YYYY[, ]hh:mm A"
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </form>
        </div>
      </div >
    </div >
  );
}
