import { Authenticator } from "@aws-amplify/ui-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";

const Login = () => {
  const navigate = useNavigate();

  const components = {
    Header() {
      return (
        <div className="text-center">
          <img src="/img/logo.svg" alt="BGR" className="img-fluid mb-3" width={400} />
        </div>
      );
    },
  };

  return (
    <AuthLayout>
      <Authenticator hideSignUp={true} components={components}>
        {({ signOut, user }) => navigate("/check-permission")}
      </Authenticator>
    </AuthLayout>
  );
};

export default Login;