import { useState } from 'react';
import ReportCard from '../../components/ReportCard';
import { getTime } from '../../components/Utils';
import NoAccess from '../../components/NoAccess';
import { useSelector } from 'react-redux';

export default function TransporterPerformance() {
    const [data, setData] = useState([])
    
    function formatData(data) {
        const res = data.map(item => {
            let report = {
                "Transporter Name": item?.transporter_name,
                "Route" : item?.route_name,
                "Total Vehicles" : item?.vehicle_count,
                "Total Trips" :  item?.trip_count,
                "Avg Trip Duration" : getTime(item?.avg_trip_time),
                "Total Trip Duration" : getTime(item?.total_trip_time),
                "Total Shortage (Ton)" : item?.total_short_weight
            };
            return report
        })
        setData(res)
        return res
    }

    const roles = useSelector((state)=>state.userInfo.payload?.role)
    if(!roles?.includes("TRANSPORTER-PERFORMANCE")){
        return <NoAccess/>
    }

    return (
        <>
            <ReportCard title={"Transporter Performance Report"} formatData={formatData} api={'transporter-performance-report'} />
            <div className="table-responsive card">
                <table className="table-hover table-bordered">
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                        <tr>
                            {data?.length > 0 && Object.keys(data[0])?.map(item => (
                                <th className={`fs-5 ${item !== 'Transporter Name' && "text-center"}`} key={item}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, idx) => (
                            <tr key={idx}>
                                <td align='left' className='fs-5'>{item["Transporter Name"]}</td>
                                <td align='center' className='fs-5'>{item["Route"]}</td>
                                <td align='center' className='fs-5'>{item["Total Vehicles"]}</td>
                                <td align='center' className='fs-5'>{item["Total Trips"]}</td>
                                <td align='center' className='fs-5'>{item["Avg Trip Duration"]}</td>
                                <td align='center' className='fs-5'>{item["Total Trip Duration"]}</td>
                                <td align='center' className='fs-5'>{item["Total Shortage (Ton)"]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
