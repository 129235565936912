import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from "../../components/Spinner";
import { apiName } from '../../components/Utils';


const RequestDetails = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [spinner, setSpinner] = useState(true)
  const user = useSelector((state) => state?.userInfo?.payload);
  const [requestedData, setRequestedData] = useState([])

  useEffect(() => {
    if (id) {
      getData()
    }
  }, [id])

  const getData = async () => {
    try {
      const res = await API.get(apiName,`/vehicle-request/${id}`)
      setRequestedData(res)
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      console.log(error)
    }
  }

  const formData = (newVehicle) => (
    <div className="row">
      <div className="card px-3 py-4 row-grid">
        <div className="card-body">
          <div className='my-1'>
            <label className="form-label">Vehicle ID</label>
            <input type="text" className='form-control' value={newVehicle?.id || ""} disabled />
          </div>
          <div className='my-1'>
            <label className="form-label">Transporter</label>
            <input type="text" className='form-control' value={newVehicle?.transporter_name || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">Make</label>
            <input type="text" className='form-control' value={newVehicle?.make || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">Model</label>
            <input type="text" className='form-control' value={newVehicle?.model || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">Type</label>
            <input type="text" className='form-control' value={newVehicle?.type_name || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">Route</label>
            <input type="text" className='form-control' value={newVehicle?.route_name || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">Chassis Number</label>
            <input type="text" className='form-control' value={(newVehicle?.details && newVehicle?.details.chassis) || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">Engine Number</label>
            <input type="text" className='form-control' value={(newVehicle?.details && newVehicle?.details.engine) || ""}  disabled/>
          </div>
        </div>
        <div className='card-body'>
          <div className='my-1'>
            <label className="form-label">RFID Number</label>
            <input type="text" className='form-control' value={newVehicle?.rfid || ""} disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">GPS Number</label>
            <input type="text" className='form-control' value={newVehicle?.gps_number || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">Capacity</label>
            <input type="text" className='form-control' value={newVehicle?.capacity || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">Tare Weight</label>
            <input type="text" className='form-control' value={(newVehicle?.weight && newVehicle?.weight / 1000) || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">No of Tyres</label>
            <input type="text" className='form-control' value={(newVehicle?.details && newVehicle?.details.noOfTyres) || ""}  disabled/>
          </div>
          <div className='my-1'>
            <label className="form-label">Insurance Number</label>
            <input type="text" className='form-control' value={newVehicle?.insurance?.numbe  || ""}  disableds/>
          </div>
        </div>
      </div>
    </div>
  )

  const ViewData = (data) => {
  
    switch (data?.type?.toLowerCase()) {
      case "transfer":
        return (
          <>
            <div className="row">
              <div className="card px-3 py-4 row-grid">
                <div className="card-body">
                  <h2>Vehicle Details</h2>
                  <div className='my-1'>
                    <label className="form-label">Vehicle</label>
                    <input type="text" className='form-control' value={data?.old_vehicle_details?.id || ""} disabled/>
                  </div>
                  <div className='my-1'>
                    <label className="form-label">Transporter</label>
                    <input type="text" className='form-control' value={data?.old_vehicle_details?.transporter_name || ""} disabled />
                  </div>
                  <div className='my-1'>
                    <label className="form-label">Route</label>
                    <input type="text" className='form-control' value={data?.old_vehicle_details?.route_name  || ""} disabled />
                  </div>
                </div>
                <div className="card-body">
                  <h2>Transfer to</h2>
                  <div className='my-1'>
                    <label className="form-label">Transporter Name</label>
                    <input type="text" className='form-control' value={data?.new_vehicle_details?.transporter_name || ""}  disabled/>
                  </div>
                  <div className='my-1'>
                    <label className="form-label">Route</label>
                    <input type="text" className='form-control' value={data?.new_vehicle_details?.route_name || ""}  disabled/>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      case "add":
        return (
          <>{formData(data?.new_vehicle_details)}</>
        )
      case "delete":
        return (
          <>{formData(data?.old_vehicle_details)}</>
        )
      case "exchange":
        return (
          <>
            <h2>Old Vehicle</h2>
            <>{formData(data?.old_vehicle_details)}</>

            <h2>New Vehicle</h2>
            <>{formData(data?.new_vehicle_details)}</>
          </>
        )
      default:
        return (<h1>No data available...</h1>)
    }
  }

  if (spinner) return <Spinner show={true} />;

  return (
    <div className='container'>
      <div className="header">
      <div className="header-body">
        <div className="d-flex">
          <div className="col">
            <h1 className="header-title">{requestedData?.type === "ADD" ? 'New Vehicle Request' : `Vehicle requested for ${requestedData?.type?.toLowerCase()}`} {requestedData?.type === "ADD" || requestedData?.type === "EXCHNAGE" ? "Edit" : null}</h1>
            </div>
            {(requestedData?.type === "ADD" || requestedData?.type?.toLowerCase() === "exchange") && (
              <button className='btn btn-light' onClick={() => navigate(`${requestedData?.type === "ADD" ? "/vehicle/request/edit/" : "/vehicle/exchange/"}${id}`)}>Edit</button>
            )}
        </div>
      </div>
    </div>
      {ViewData(requestedData)}
        <h2>Documents</h2>
      <div className='d-flex gap-3 mt-3'>
        {requestedData?.new_vehicle_details && requestedData?.new_vehicle_details?.documents?.map(item => (
          <div key={item?.url}>
            {item?.type.includes('image') ? <a href={item?.url} target="blank">
              <img src={item?.url} alt="docx" height={"140px"}
                width={"140px"}
                style={{ objectFit: "contain" }} />
            </a> :
              <a href={item?.url} target="blank">
                <img
                  src="/img/pdf.png"
                  height={"100px"}
                  width={"100px"}
                  style={{ objectFit: "contain" }}
                />
              </a>}
          </div>
        ))}
      </div>

      {requestedData?.history && (
        <div className="table-responsive bg-white my-5">
          <h3 className="px-3 pt-3">Status History</h3>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Reason</th>
                <th>Role</th>
                <th className='text-center'>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {requestedData?.history && requestedData?.history?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.user}</td>
                  <td>{item?.comment}</td>
                  <td>{item?.role}</td>
                  <td className='text-center'>{item?.status ? <i className='fe fe-check-circle'></i> : <i className='fe fe-x-circle'></i>}</td>
                  <td style={{ minWidth: '200px' }}>{item?.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>)}
      <p style={{ visibility: "hidden" }}>dd</p>
    </div>
  )
}

export default RequestDetails;