import { Pagination } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import * as XLSX from 'xlsx';
import NoAccess from "../../components/NoAccess";
import Spinner from "../../components/Spinner";
import { apiName, delay, formatDateTime, getDuration, handleSearchVehicles, limit, selectColor, timeDifference } from "../../components/Utils";


const GatepassList = () => {
  const navigate = useNavigate();
  const route = useLocation();
  const path = route.pathname;
  const roles = useSelector((state) => state.userInfo?.payload?.role)

  const [gatepass, setGatepass] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState(0);
  const [direction, setDirection] = useState(null)
  const [show, setShow] = useState(false)
  const [dates, setDates] = useState(null)

  const [vehicle, setVehicle] = useState(null)

  const [closed, setClosed] = useState(0)
  const [notClosed, setNotClosed] = useState(0)
  const [reset, setReset] = useState(new Date().getSeconds())
  const [count, setCount] = useState(0)
  const [todays, setTodays] = useState(0)

  const [dateFilters, setDateFilters] = useState(null);

  async function getData() {
    setLoading(true);
    try {
      let query = `/gatepass?take=${limit}&skip=${count * limit}&deleted=${false}`
      let active = null
      if (tab === 1) active = false
      if (tab === 2) active = true

      if (active !== null) query = `${query}&active=${active}`
      if (search.length > 0) query = `${query}&keyword=${search}`
      if (vehicle) query = `${query}&vehicle_id=${vehicle}`
      if (dateFilters) query = `${query}&start=${dateFilters?.start}&end=${dateFilters?.end}`

      let data = await API.get(apiName, query)
      setTotal(data.count)
      setLoading(false);
      setGatepass(data.items);
    } catch (error) {
      console.log(error);
    }
  }
  function getTime(time) {
    const duration = moment.duration(time * 60 * 1000);
    const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const seconds = String(duration.seconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;

  }

  const handleDownload = async () => {
    try {
      if (!dateFilters) return toast.error("Date range is required !")
      // setShow(false)
      const loading = toast.loading("Downloading...")
      let data = await API.get(apiName, `/gatepass?take=${9999999}&start=${dateFilters?.start}&end=${dateFilters?.end}`)

      let temp = data?.items.map((item, idx) => {
        return {
          ["S.No"]: idx + 1,
          ID: item.id,
          VEHICLE_NUMBER: item?.vehicle_id,
          DRIVER_NAME: item?.driver_name,
          TRANSPORTER_NAME: item?.transporter_name,
          ROUTE: item?.route_name,
          IN_GATE: moment.unix(item?.mine_entry_time).format("DD-MM-YYYY h:mm a"),
          ["WB-IN TIME"]: item?.wb_exit_time && moment.unix(item?.wb_exit_time).format("DD-MM-YYYY h:mm a"),
          IN_WB_DURATION: item?.wb_exit_time && timeDifference(item?.mine_entry_time, item?.wb_exit_time),
          OUT_GATE: item?.mine_exit_time && moment.unix(item?.mine_exit_time).format("DD-MM-YYYY h:mm a"),
          WB_OUT_DURATION: item?.mine_exit_time && timeDifference(item?.wb_exit_time, item?.mine_exit_time),
          IN_OUT_DURATION: item?.mine_exit_time && timeDifference(item?.mine_entry_time, item?.mine_exit_time),
          RAILWAY_IN_TIME: item?.rail_entry_time && moment.unix(item?.rail_entry_time).format("DD-MM-YYYY h:mm a"),
          RAILWAY_OUT_TIME: item?.rail_exit_time && moment.unix(item?.rail_exit_time).format("DD-MM-YYYY h:mm a"),
          OUT_RAIL_IN_DURATION: item?.rail_entry_time && timeDifference(item?.mine_exit_time, item?.rail_entry_time),
          TOTAL_DURATION: item?.duration && getTime(item?.duration / 60),
          REFERRENCE_NO: item?.rail_exit_time,
          Offline: item?.offline ? "Yes" : "No",
          MINE_ENTRY_LANE: item?.mine_entry_lane || "_",
          MINE_EXIT_LANE: item?.mine_exit_lane || "-",
        }
      })
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(temp);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `Gatepass List ${moment.unix(dateFilters?.start).format("DD-MMM-YYYY HH:mm")} - ${moment.unix(dateFilters?.end).format("DD-MMM-YYYY HH:mm")}.xlsx`);
      toast.dismiss(loading)
      toast.success("Download Successful.")
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong !")
    }
  }

  function handlePaginationChange(newPageIndex) {
    setCount(newPageIndex - 1)
  }

  const handleDateApplied = (event, picker) => {
    const fromDate = moment(picker.startDate).unix()
    const toDate = Math.floor(moment(picker.endDate).unix());

    setDateFilters({ start: fromDate, end: toDate });
    setDates({ start: fromDate, end: toDate });
  };

  const handleDateAppliedModal = (event, picker) => {
    const fromDate = moment(picker.startDate).unix()
    const toDate = Math.floor(moment(picker.endDate).unix());

    setDates({ start: fromDate, end: toDate });
  };
  async function getClosed() {
    try {
      let res = await API.get(apiName, `/gatepass?take=${999999999}&active=${false}&start=${dateFilters?.start || moment().startOf("day").unix()}&end=${dateFilters?.end || moment().endOf('day').unix()}`)
      setClosed(res.count)
    } catch (error) {
      console.log(error);
    }
  }

  async function getNotClosed() {
    try {
      let res = await API.get(apiName, `/gatepass?take=${9999999999}&active=${true}&start=${dateFilters?.start || moment().startOf("day").unix()}&end=${dateFilters?.end || moment().endOf('day').unix()}`)
      setNotClosed(res.count)
    } catch (error) {
      console.log(error);
    }
  }
  async function getTodays() {
    try {
      let res = await API.get(apiName, `/gatepass?take=${99999999}&start=${dateFilters?.start || moment().startOf("day").unix()}&end=${dateFilters?.end || moment().endOf('day').unix()}`)
      setTodays(res.count)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    let timer = setTimeout(async () => {
      const loading = toast.loading("Loading...")
      await Promise.all([getData(),
      getClosed(),
      getNotClosed(), getTodays()
      ])
      toast.dismiss(loading)
    }, delay);
    return () => clearTimeout(timer);
    //eslint-disable-next-line
  }, [search, dateFilters, tab, direction, reset, count, vehicle]);

  useEffect(() => {
    setLoading(true);
  }, [tab])

  if (!roles?.includes("GATEPASS_LIST")) return <NoAccess />;

  return (
    <div className="container-fluid" id="hello">
      {/* header starts */}
      <div className="row align-items-center">
        <div className="col">
          <h1 className="header-title text-truncate">Gate Pass ({todays})</h1>
        </div>
        {!path.includes("mobile") && (
          <div className="col-auto">
            <button
              className="btn btn-primary fw-bold"
              onClick={() => navigate("/gatepass/create")}>
              Create Gate Pass
            </button>
          </div>
        )}
      </div>
      <hr />
      {/* header ends */}
      <div>
        <div className="col my-3 ms-1">
          <ul className="nav nav-tabs nav-overflow header-tabs">
            <li className="nav-item">
              <div
                className={`nav-link ${tab === 0 ? "active" : ""} pointer`}
                onClick={() => setTab(0)}>
                <b>All</b>
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${tab === 1 ? "active" : ""} pointer`}
                onClick={() => setTab(1)}>
                <button className="nav-link"><b>Closed</b> <span className="rounded-pill badge bg-secondary m-1">{closed}</span></button></div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${tab === 2 ? "active" : ""} pointer`}
                onClick={() => setTab(2)}>
                <button role="button" className="nav-link"><b>Not closed</b> <span className="rounded-pill badge bg-secondary m-1">{notClosed}</span></button>
              </div>
            </li>
          </ul>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab">
                <div className="card">
                  <div className={window.innerWidth > 750 ? "card-header" : "p-2"}>
                    <div className={window.innerWidth > 750 ? "row align-items-center" : ""}>
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            autoFocus
                            className="form-control list-search"
                            type="search"
                            placeholder="Search by Gatepass number"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value.toUpperCase());
                            }}
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                      </div>
                      <div className={window.innerWidth > 750 ? "col-auto" : "d-flex mt-2"} style={{ width: "260px" }}>
                        <AsyncSelect isClearable theme={selectColor} required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => setVehicle(e?.value)} />
                      </div>
                      <div className={window.innerWidth > 750 ? "col-auto" : "d-flex mt-2"}>
                        <DateRangePicker
                          initialSettings={{
                            startDate: moment.unix(dateFilters?.start || moment().unix()).format("MM-DD-YYYY"),
                            endDate: moment.unix(dateFilters?.end || moment().unix()).format("MM-DD-YYYY"),
                            linkedCalendars: true,
                            showCustomRangeLabel: true,
                            showDropdowns: true,
                            alwaysShowCalendars: true,
                            timePicker: true
                          }}
                          onApply={handleDateApplied}
                        >
                          <input
                            className={`btn ${dateFilters ? "btn-primary" : "btn-white"
                              } ml-2 pointer`}
                          />
                        </DateRangePicker>
                        {window.innerWidth < 750 && (
                          <>
                            <button
                              className="btn btn-light ms-auto"
                              onClick={() => {
                                setSearch("");
                                setDateFilters(null);
                                setDirection('ASC');
                                setVehicle(null)
                              }}
                            >
                              <i className='fe fe-refresh-cw' />
                            </button>
                          </>
                        )}
                      </div>
                      {window.innerWidth > 750 && (
                        <>
                          <div className="col-auto px-1">
                            <button
                              className="btn btn-light"
                              onClick={() => {
                                setSearch("");
                                setDateFilters(null);
                                setReset(new Date().getSeconds())
                              }}
                            >
                              <i className='fe fe-refresh-cw' />
                            </button>
                          </div>
                          <div className="col-auto px-1">
                            <button className="btn btn-primary w-100 fw-bold" onClick={() => handleDownload()}><i className="fe fe-download" /></button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <Spinner show={loading}>
                    <div style={{ height: "64vh" }} className={window.innerWidth > 750 ? "table-responsive" : ""} >
                      {window.innerWidth > 750 ? (
                        <table className='table table-sm table-hover'>
                          <thead className="sticky-top" style={{ zIndex: 0 }}>
                            <tr>
                              <th>Number</th>
                              <th>Vehicle Number</th>
                              <th>Route</th>
                              <th>Driver / Transporter</th>
                              <th className='text-center'>In Time</th>
                              <th className='text-center'>Out Time</th>
                              <th className='pointer text-center' onClick={() => {
                                setDirection((prev) => prev === 'desc' ? 'asc' : 'desc');
                              }}>
                                Duration
                              </th>
                              <th className='text-center'>Railway In Time</th>
                              <th className='text-center'>Railway Out Time</th>
                              <th className='text-center'>Mine Entry Lane</th>
                              <th className='text-center'>Mine Exit Lane</th>
                            </tr>
                          </thead>
                          <tbody>
                            {gatepass.map((gatepass) => (
                              <tr key={gatepass.id} className='pointer'
                                onClick={() => path.includes('mobile') ? navigate(`/mobile/gatepass/${gatepass.id}`) : navigate(`/gatepass/${gatepass.id}`)}>
                                <td>{gatepass?.id}</td>
                                <td>{gatepass?.vehicle_id}</td>
                                <td>{gatepass?.route_name}</td>
                                <td>
                                  <div>{gatepass?.driver_name}</div>
                                  <div className='small text-muted'>{gatepass?.transporter_name}</div>
                                </td>
                                <td className='text-center'>{formatDateTime(gatepass?.mine_entry_time)}</td>
                                <td className='text-center'>{formatDateTime(gatepass?.mine_exit_time)}</td>
                                <td className='text-center'>
                                  {getDuration(gatepass?.mine_entry_time, gatepass?.mine_exit_time)}
                                </td>
                                <td className='text-center'>{formatDateTime(gatepass?.rail_entry_time)}</td>
                                <td className='text-center'>{formatDateTime(gatepass?.rail_exit_time)}</td>
                                <td className='text-center'>{gatepass?.mine_entry_lane}</td>
                                <td className='text-center'>{gatepass?.mine_exit_lane}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {gatepass?.map((gatepass, index) => (
                            <div className="card m-2" key={index}>
                              <div className="p-3 row">
                                <div className="col col-6">
                                  <p className="text-muted m-0"><b>Number</b></p>
                                  <p className="m-0"><b>{gatepass?.id}</b></p>
                                  <p className="text-muted m-0"><b>Vehicle Number</b></p>
                                  <p className="m-1"><b>{gatepass?.vehicle?.number}</b></p>
                                  <p className="text-muted m-0"><b>Transporter's Name</b></p>
                                  <p className="m-1"><b>{gatepass?.transporter?.name}</b></p>
                                  <p className="text-muted m-0"><b>Driver's Name</b></p>
                                  <p className="m-1"><b>{gatepass?.driver?.user?.name}</b></p>
                                </div>
                                <div className="col col-6 text-start">
                                  <p className="text-muted m-0"><b>Route</b></p>
                                  <p className="m-1"><b>{gatepass?.vehicle?.route?.name}</b></p>
                                  <p className="text-muted m-0"><b>In Time</b></p>
                                  <p className="m-1"><b>{moment(gatepass?.entryAt * 1000).format("DD-MM-YYYY[, ]hh:mm A")}</b></p>
                                  <p className="text-muted m-0"><b>Out Time</b></p>
                                  <p className="m-1"><b>{gatepass?.exitAt && moment(gatepass?.exitAt * 1000).format("DD-MM-YYYY[, ]hh:mm A")}</b></p>
                                  <p className="text-muted m-0"><b>Duration</b></p>
                                  <p className="m-1"><b>{gatepass?.gatepassDuration && getDuration(gatepass?.gatepassDuration)}</b></p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {gatepass.length === 0 && !loading && (
                        <p className="text-center m-3">No gatepass</p>
                      )}
                    </div>
                    <div className="card-footer d-flex justify-content-center">
                      <Pagination
                        currentPage={count + 1}
                        totalPages={Math.floor(total / limit) - 1}
                        siblingCount={1}
                        onNext={() => setCount((prev) => prev + 1)}
                        onPrevious={() => setCount((prev) => prev - 1)}
                        onChange={handlePaginationChange}
                      />
                    </div>
                  </Spinner>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <form onSubmit={(e) => {
          e.preventDefault()
          handleDownload()
        }}>
          <Modal.Header>
            <Modal.Title><b>Change Status</b></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Select Date Range *</h4>
            <DateRangePicker
              initialSettings={{
                startDate: moment.unix(dates?.start || moment().unix()).format("MM-DD-YYYY"),
                endDate: moment.unix(dates?.end || moment().unix()).format("MM-DD-YYYY"),
                linkedCalendars: true,
                showCustomRangeLabel: true,
                showDropdowns: true,
                alwaysShowCalendars: true,
                timePicker: true
              }}
              onApply={handleDateAppliedModal}
            >
              <input
                className={`btn ${dateFilters ? "btn-primary" : "btn-white"
                  } ml-2 pointer`}
              />
            </DateRangePicker>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Download
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default GatepassList;
