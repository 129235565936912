import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import toast from 'react-hot-toast';
import { apiName } from "../../components/Utils";
import { API } from "aws-amplify";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import {useNavigate, useSearchParams} from 'react-router-dom'

export default function MismatchList() {
    const [loading,setLoading] = useState([])
    const [date, setDate] = useState({ start: moment().startOf('day').unix(), end: moment().endOf('day').unix() })
    const [gatepassList,setGatepassList] = useState([])
    const [searchParams,setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    const handleDateApplied = (event, picker) => {
        const fromDate = moment(picker.startDate).startOf("day").unix()
        const toDate = Math.floor(moment(picker.endDate).endOf("day").unix());
        setDate({ start: fromDate, end: toDate });
        setSearchParams({ start: fromDate, end: toDate })
    };

    async function getList(){
        try {
        setLoading(true)
          let res = await API.get(apiName,`/gatepass/list/mismatch_list?start=${searchParams?.get("start") || date?.start}&end=${searchParams?.get("end") || date?.end}`);
          setGatepassList(res)
          setLoading(false)
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong");
        }
    }

    useEffect(() => {
      getList()
    }, [date])
    
  return (
    <>
      <div className="row align-items-center">
        <div className="col">
          <h1 className="header-title text-truncate">Mismatch List</h1>
        </div>
      </div>
      <hr />
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="contactsListPane"
              role="tabpanel"
              aria-labelledby="contactsListTab"
            >
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="input-group input-group-flush input-group-merge input-group-reverse">
                      <DateRangePicker
                        initialSettings={{
                            startDate: moment.unix(searchParams?.get("start") || date?.start).format("MM-DD-YYYY"),
                            endDate: moment.unix(searchParams?.get("end") || date?.end).format("MM-DD-YYYY"),
                            linkedCalendars: true,
                            showCustomRangeLabel: true,
                            showDropdowns: true,
                            alwaysShowCalendars: true,
                            ranges: {
                                'Today': [moment(), moment()],
                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }
                        }}
                        onApply={handleDateApplied}
                    >
                        <input
                            className={`btn btn-light ml-2 pointer`}
                        />
                    </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <Spinner show={loading}>
                  <div className={window.innerWidth > 750 ? "table-responsive" : ''} style={{ height: "64vh" }}>
                      <table className="table table-sm table-hover position-relative ">
                        <thead className="sticky-top top-0">
                          <tr>
                            <th className="pointer">ID</th>
                            <th className="pointer">Route</th>
                            <th className="pointer">Trip ID</th>
                            <th className="pointer text-center">Mine Tare Weight</th>
                            <th className="pointer text-center">Mine Weight</th>
                            <th className="text-center">Rail Weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          {gatepassList.map((gatepass) => (
                            <tr key={gatepass.id} className="pointer" onClick={()=>navigate(`/gatepass/${gatepass?.id}`)}>
                              <td>{gatepass?.id}</td>
                              <td>{gatepass?.route_name}</td>
                              <td>{gatepass?.trip_id}</td>
                              <td className="text-center">{gatepass?.mine_tare_weight}</td>
                              <td className="text-center">{gatepass?.mine_weight}</td>
                              <td className="text-center">{gatepass?.rail_weight}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    {gatepassList.length === 0 && !loading && (
                      <p className="text-center m-3">No Gatepass found</p>
                    )}
                  </div>
                  {/* <div className="card-footer d-flex justify-content-center">
                    <Pagination
                      currentPage={count + 1}
                      totalPages={Math.ceil(total / limit)}
                      siblingCount={1}
                      onNext={() => setCount((prev) => prev + 1)}
                      onPrevious={() => setCount((prev) => prev - 1)}
                      onChange={handlePaginationChange}
                    />
                  </div> */}
                </Spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
