import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NoAccess from '../../components/NoAccess'
import AsyncSelect from 'react-select/async'
import { apiName, selectColor } from '../../components/Utils'
import moment from 'moment'
import toast from 'react-hot-toast'
import * as XLSX from 'xlsx';
import { API } from 'aws-amplify'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios'


export default function Distance() {
    const [date, setDate] = useState({ start: moment().startOf('day').unix(), end: moment().endOf('day').unix() })
    const [vehicle, setVehicle] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const formatData = (x) => {
        const res = x.map(item => {
            let report = {
                "Date": item?.start + ' to ' + item?.end,
                "Vehicle": item?.vehicle_id,
                "Start Location": item?.start_location?.slice(8),
                "End Location": item?.end_location?.slice(8),
                "Distance Travelled": `${item?.total_distance} km`
            };
            return report
        })
        setData(x)
        return res
    }

    const handleView = async () => {
        if (vehicle) {
            const load = toast.loading("Loading...")
            setLoading(true)
            try {
                let query = `/report/distance?start=${date?.start}&end=${date?.end}&vehicle_id=${vehicle?.value}`
                let res = await API.get(apiName, query);
                console.log(res)
                if (res[0].vehicle_id === null) return toast.error("No data found !")
                var url1 = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + res[0].start_lat + "," + res[0].start_lng + "&key=AIzaSyA406ENQAh8iTmJGj8jURjfnscx5osITcQ";
                let res1 = await axios.get(url1)
                var url2 = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + res[0].end_lat + "," + res[0].end_lng + "&key=AIzaSyA406ENQAh8iTmJGj8jURjfnscx5osITcQ";
                let res2 = await axios.get(url2)
                res[0].start_location = res1.data.plus_code.compound_code
                res[0].end_location = res2.data.plus_code.compound_code
                res[0].start = moment.unix(date?.start).format("DD-MM-YYYY HH:mm")
                res[0].end = moment.unix(date?.end).format("DD-MM-YYYY HH:mm")
                setData((prev => ([...prev, ...res])))
                return formatData([...data, ...res])
            } catch (error) {
                console.log(error)
            } finally {
                {
                    toast.dismiss(load)
                    setLoading(false)
                }
            }
        }
    }

    const handleDownload = () => {
        try {
            let temp = formatData(data)
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(temp);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, `Distance Report_${moment.unix(date?.start).format('DD-MMM-YYYY')} - ${moment.unix(date?.end).subtract(5, "hours").subtract(30, "minutes").format('DD-MMM-YYYY')}.xlsx`);
            toast.success("Download Successful.")
        } catch (error) {
            toast.error("Something went wrong")
        }
    }

    const handleDateApplied = (event, picker) => {
        const fromDate = moment(picker.startDate).unix()
        const toDate = Math.floor(moment(picker.endDate).unix());
        setDate({ start: fromDate, end: toDate });
    };

    const filterVehicles = async (inputValue) => {
        let res = await API.get(apiName, `/vehicle?keyword=${inputValue}&deleted=${false}&not_type_id=10003`);
        let values = res.items.map((item) => {
            return { label: item.id, value: item.id };
        });
        return values;
    };

    const handleSearchVehicles = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterVehicles(inputValue));
            }, 1000);
        });

    useEffect(() => {
        handleView()
        if (!vehicle) setData([])
    }, [vehicle, date])

    const roles = useSelector((state) => state.userInfo.payload?.role)
    if (!roles?.includes("DISTANCE")) return <NoAccess />



    return (
        <div>
            <div>
                <h2 className='text-center'>Distance Report</h2>
                <div className='card p-2'>
                    <div className='d-flex align-items-center justify-content-end gap-2'>
                        <DateRangePicker
                            initialSettings={{
                                startDate: moment.unix(date?.start).format("MM-DD-YYYY"),
                                endDate: moment.unix(date?.end).subtract(5, 'hours').subtract(30, 'minutes').format("MM-DD-YYYY"),
                                linkedCalendars: true,
                                showCustomRangeLabel: true,
                                showDropdowns: true,
                                alwaysShowCalendars: true,
                                timePicker: true,
                                ranges: {
                                    'Today': [moment(), moment()],
                                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                    'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                }
                            }}
                            onApply={handleDateApplied}
                        >
                            <input className={`btn btn-light ml-2 pointer`} style={{ width: '20rem' }} />
                        </DateRangePicker>
                        <div className='distance_select'>
                            <AsyncSelect isClearable theme={selectColor} isMulti required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => setVehicle(e[e?.length - 1])} />
                        </div>
                        <button disabled={loading} onClick={handleDownload} type='button' style={{ width: 'fit-content' }} className='btn btn-primary'><i className='fe fe-download'></i></button>
                    </div>
                </div>
                {data?.length === 0 &&
                    <span>*Please select vehicle to see the data</span>}
            </div>
            <div className="card">
                <table className='table-hover table-bordered'>
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                        <tr>
                            <th>Date</th>
                            <th className="fs-5">Vehicle</th>
                            <th className="fs-5">Start Point</th>
                            <th className="fs-5">End Point</th>
                            <th className="fs-5 text-center">Distance Travelled</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, idx) => (
                            <tr key={idx}>
                                <td>{item?.start} to {item?.end}</td>
                                <td>{item?.vehicle_id}</td>
                                <td>{item?.start_location?.slice(8)}</td>
                                <td>{item?.end_location?.slice(8)}</td>
                                <td align="center">{item?.total_distance} km</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {data.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
            </div>
        </div>
    )
}
