import { API } from "aws-amplify"
import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { apiName, handleSearchVehicles, selectColor } from "../../components/Utils"
import Header from "../../layouts/Header"

export default function RemoveVehicle() {
    const navigate = useNavigate()
    const [vehicle,setVehicle] = useState({})
    const [loading,setLoading] = useState(false)

    async function handleCreateReq(){
        const loading = toast.loading('Loading...')
        setLoading(true)
        try {
            await API.post(apiName,'/vehicle-request',{body : {old_vehicle_id: vehicle?.id,old_vehicle_details:vehicle,type:"DELETE"}})
            toast.success('Delete request created')
            navigate('/vehicle/request-list')
        } catch (error) {
            console.log(error)
            toast.error("Soemthing went wrong.")
        }finally{{
            toast.dismiss(loading)
            setLoading(false)}}
    }
  return (
      <div className="container my-4">
        <Header name={'Remove Vehicle'} />

          <div className="card col col-lg-6 p-5 mt-5">
              <form onSubmit={(e)=>{
                e.preventDefault()
                handleCreateReq()
              }}>
                  <label className="my-2">Select Vehicle <span className="text-danger">*</span></label>
                  <AsyncSelect theme={selectColor} required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={async (e) => {
                      let res = await API.get(apiName,`/vehicle/${e?.value}`)
                      setVehicle(res)
                  }} />
                  <button disabled={loading} className="btn btn-primary my-3">Create Request</button>
              </form>
          </div>
      </div>
  )
}
