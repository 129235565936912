
import { Pagination } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../components/Spinner";
import { apiName, delay, limit } from "../../components/Utils";
import NoAccess from "../../components/NoAccess";



const Users = () => {
  const navigate = useNavigate();
  const route = useLocation();
  const role = useSelector((state) => state.userInfo.payload?.role)

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [display, setDisplay] = useState(0);
  const [count, setCount] = useState(0)

  async function deleteUser(user) {
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#e63757',
        cancelButtonColor: 'black',
        confirmButtonText: `Delete ${user?.name || "user"}`,
      }).then(async (result) => {
        try {
          if (result.isConfirmed) {
            setLoading(true);
            await API.put(apiName, `/user/${user?.id}`, { body: { ...user, deleted: true, active: false } })
            setLoading(false);
            let temp = structuredClone(users);
            temp = temp.filter(item => item.id !== user?.id)
            setUsers(temp);
            toast.success(`User deleted successfully.`);
          }
        } catch (error) {
          console.log(error)
        } finally { setLoading(false) }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function toggleAccess(user, block) {
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: `${block ? 'maroon' : '#407050'}`,
        cancelButtonColor: 'black',
        confirmButtonText: `${block ? "Block" : "Unblock"} ${user?.name || "user"}`,
      }).then(async (result) => {
        try {
          if (result.isConfirmed) {
            setLoading(true);
            await API.put(apiName, `/user/toggle_access/${user?.id_ext}`, { body: { block } })
            await API.put(apiName, `/user/${user?.id}`, { body: { ...user, blocked: block } })
            setLoading(false);
            let temp = structuredClone(users);
            temp = temp.filter(item => item.id !== user?.id)
            setUsers(temp);
            toast.success(`User ${block ? 'blocked' : 'Unblocked'} successfully.`);
          }
        } catch (error) {
          console.log(error)
        } finally { setLoading(false) }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function handlePaginationChange(newPageIndex) {
    setCount(newPageIndex - 1)
  }

  async function getData() {
    setLoading(true);
    try {
      let query = `/user?take=${limit}&skip=${count * limit}`
      if (search.length !== 0) query = `${query}&keyword=${search}`
      if (display === 1) query = `${query}&active=${false}`
      if (display === 2) query = `${query}&blocked=${true}`
      let res = await API.get(apiName, query)
      setTotal(res.count);
      setLoading(false);
      setUsers(res.items);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      getData();
    }, delay);
    return () => clearTimeout(timer);
  }, [search, display, count]);


  if (!role?.includes("USERS")) {
    return <NoAccess />;
  }

  return (
    <div className="container-fluid">
      {/* header starts */}
      <div className="row align-items-center">
        <div className="col">
          <h1 className="header-title text-truncate">Users ({total})</h1>
        </div>
      </div>
      <hr />
      {/* header ends */}
      <div className="row align-items-center my-3">
        <div className="col">
          <ul className="nav nav-tabs nav-overflow header-tabs">
            <li className="nav-item">
              <div
                className={`nav-link ${display === 0 ? "active" : ""} pointer`}
                onClick={() => {
                  setDisplay(0);
                  setLoading(true);
                }}
              >
                <b>Active</b>
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${display === 1 ? "active" : ""} pointer`}
                onClick={() => {
                  setDisplay(1);
                  setLoading(true);
                }}
              >
                <b>Pending</b>
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link ${display === 2 ? "active" : ""} pointer`}
                onClick={() => {
                  setDisplay(2);
                  setLoading(true);
                }}
              >
                <b>Blocked</b>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              >
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                          <input
                            className="form-control list-search"
                            type="search"
                            autoFocus
                            placeholder="Search by name or email"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Spinner show={loading}>
                    <div className="table-responsive" style={{ height: "64vh" }}>
                      <table className="table table-sm table-hover table-nowrap card-table">
                        <thead className="sticky-top  top-0">
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th className="text-center">Block / Unblock</th>
                            <th className="text-center">Delete</th>
                          </tr>
                        </thead>

                        <tbody>
                          {users.map((user) => (
                            <tr
                              key={user.id}
                              className="pointer"
                              onClick={() => navigate(`/user/${user.id}`)}>
                              <td>{user?.name}</td>
                              <td>{user?.email}</td>
                              <td className="text-center" onClick={(e) => e.stopPropagation()}>
                                {user?.blocked ? <button className="btn btn-primary btn-sm" onClick={() => toggleAccess(user, false)}>Unblock</button> : <button onClick={() => toggleAccess(user, true)} className="btn btn-sm btn-dark">Block</button>}
                              </td>
                              <td className="text-center" onClick={(e) => e.stopPropagation()}> <button className="btn btn-danger btn-sm" onClick={() => deleteUser(user)}><i className="fe fe-trash" /></button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {users.length === 0 && !loading && (
                        <p className="text-center m-3">No users </p>
                      )}
                    </div>
                    <div className="card-footer d-flex justify-content-center">
                      <Pagination
                        currentPage={count + 1}
                        totalPages={Math.ceil(total / limit)}
                        siblingCount={1}
                        onNext={() => setCount((prev) => prev + 1)}
                        onPrevious={() => setCount((prev) => prev - 1)}
                        onChange={handlePaginationChange}
                      />
                    </div>
                  </Spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Users;
