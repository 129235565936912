import { API, Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiName } from '../../components/Utils';
import { setSetting } from '../../store/setting';
import { setUserInfo } from '../../store/userInfo';

export default function CheckRole() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function checkAuthState() {
        try {
            let cogntioRes = await Auth.currentAuthenticatedUser();;
            let currentUser = await API.get(apiName,`/user/${cogntioRes?.attributes?.sub}`)
            
            if(currentUser?.deleted === true){
                toast.error("Unauthorized Access")
                Auth.signOut()
                navigate("/")
                return;
            }
            let roleArray = currentUser?.role;
            dispatch(setUserInfo(currentUser));

            let data = await API.get(apiName,"/dashboard/settings")
            dispatch(setSetting(data))
            if (!roleArray) {
                navigate("/wait");
                return;
            }
            console.log(roleArray)
            switch (roleArray[0]) {
                case "EXECUTIVE":
                    navigate("/dashboard/executive");
                    break;
                case "DAILY_DISPATCH":
                    navigate("/dashboard/daily-dispatch");
                    break;
                case "TRAFFIC":
                    navigate("/dashboard/traffic");
                    break;
                case "TRANSPORTER_PERFORMANCE":
                    navigate("/dashboard/transporter-performance");
                    break;
                case "VEHICLE_PERFORMANCE":
                    navigate("/dashboard/vehicle-performance");
                    break;
                case "TRACKER":
                    navigate("/tracker");
                    break;
                case "PATROLLING_VEHICLE":
                    navigate("/tracker");
                    break;
                case "GATEPASS":
                    navigate("/gatepass/create");
                    break;
                case "GATEPASS_OFFLINE":
                    navigate("/gatepass/create");
                    break;
                case "IN_BARRIER":
                    navigate("/gatepass/create");
                    break;
                case "OUT_BARRIER":
                    navigate("/gatepass/create");
                    break;
                case "WEIGH_BRIDGE":
                    navigate("/gatepass/create");
                    break;
                case "RAILWAY_SIDING_ENTRY":
                    navigate("/gatepass/create");
                    break;
                case "RAILWAY_SIDING_EXIT":
                    navigate("/gatepass/create");
                    break;
                case "GATEPASS_LIST":
                    navigate("/gatepass");
                    break;
                case "TRANSPORTERS":
                    navigate("/transporters");
                    break;
                case "DRIVERS":
                    navigate("/drivers");
                    break;
                case "VEHICLE":
                    navigate("/vehicle/list");
                    break;
                case "ROUTES":
                    navigate("/route");
                    break;
                case "REPORTS":
                    navigate("/report");
                    break;
                case "USERS":
                    navigate("/users");
                    break;
                default:
                    navigate("/*");
                    break;
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        checkAuthState()
    }, [])

    return (
        <>
        </>
    )
}
