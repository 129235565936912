import { GoogleMap, InfoWindow, KmlLayer, Marker, Polyline, TrafficLayer, useLoadScript } from '@react-google-maps/api';
import { kml } from '@tmcw/togeojson';
import { API, Amplify, Auth } from 'aws-amplify';
import { getDistance } from 'geolib';
import moment from 'moment';
import React, { Children, useEffect, useRef, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { default as awsconfig, default as awsmobile } from "../aws-exports";
import MapLoader from '../components/MapLoader';
import PoweredBy from '../components/PoweredBy';
import { apiName, getPlayerDuration, handleSearchRoute, handleSearchTransporter, handleSearchVehicleType, handleSearchVehicles, selectColor, shifts } from '../components/Utils';
import * as gen from '../generated';
import * as geolib from 'geolib';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import CreatableSelect from 'react-select/creatable';


const randomColor = require('randomcolor');
const legend = require('../data/legend.json')
const waypoints = require('../data/waypoints.json')


const LiveTracker = () => {
    const navigate = useNavigate();
    const audioRef = useRef(null)
    const [settings, setSettings] = useState([])
    const [route, setRoute] = useState([])
    const [tempRoute, setTempRoute] = useState([])
    const [vehicleTypes, setVehicleTypes] = useState(null)
    const [hindranceData, setHindranceData] = useState({})
    const [hindranceCount, setHindranceCount] = useState([])
    const [hindranceReasons, setHindranceReasons] = useState([])

    const [searchParams, setSearchParams] = useSearchParams()
    const { isLoaded } = useLoadScript({ googleMapsApiKey: 'AIzaSyA406ENQAh8iTmJGj8jURjfnscx5osITcQ' })

    const [initialView, setInitialView] = useState(
        {
            center: {
                lat: 24.495771731686176,
                lng: 87.57790405529408
            },
            zoom: 11
        })
    const [view, setView] = useState("hybrid")
    const [loader, setLoader] = useState(false)
    const [mode, setMode] = useState(0)
    const [sliderValue, setSliderValue] = useState(0)

    const [tripHistory, setTripHistory] = useState([])
    const [totalDistance, setTotalDistance] = useState([])
    const [playTripHistory, setPlayTripHistory] = useState(false)
    const [tripHistoryIndex, setTripHistoryIndex] = useState(0);
    const [playForward, setPlayForward] = useState(10)
    const timer = useRef();

    const [vehicles, setVehicles] = useState({})
    const [trips, setTrips] = useState({})
    const [segmentOptions, setSegmentOptions] = useState(false)
    const [geoFenceOptions, setGeoFenceOption] = useState(false)
    const [showLegend, setShowLegend] = useState(false)
    const [mute, setMute] = useState(true)
    const [geoFence, setGeoFence] = useState([])
    const [todaysUnloaded, setTodaysUnloaded] = useState([])

    const [dateFilters, setDateFilters] = useState(null)
    const [filter, setFilter] = useState({
        vehicles: null,
        route: null,
        segment: searchParams.get("segment") ? `${searchParams.get("segment")}` : null,
        loaded: null,
        deviated: null,
        idle: null,
        moving: null,
        halted: null,
        offline: null,
        loaded_empty: null,
        deviated_empty: null,
        idle_empty: null,
        moving_empty: null,
        halted_empty: null,
        offline_empty: null,
        transporterId: null,
        brakedown: null,
        brakedown_empty: null,
        loaders: null,
        dumpers: null,
        geofence: null
    });

    const [showDistanceMarker, setShowDistanceMarker] = useState(false)
    const [distanceMarkers, setDistanceMarkers] = useState([])
    const [showHindranceMarker, setShowHindranceMarker] = useState(false)
    const [allHindranceData, setAllHindranceData] = useState([])
    const [roles, setRoles] = useState([])

    const [stats, setStats] = useState({
        all: 0,
        loaded: 0,
        unloaded: 0,
        in_route: 0,
        deviated: 0,
        idle: 0,
        today_loaded: 0,
        today_unloaded: 0
    })

    const pickupAndDropMarkers = {
        dumka: {
            location: {
                longitude: 87.260474386027,
                latitude: 24.28944158134833
            }
        },
        coal_yard: {
            location: {
                longitude: 87.45181345653168,
                latitude: 24.54536215076811
            }
        },
        pakur: {
            location: {
                longitude: 87.839301955064,
                latitude: 24.62302087060489
            }
        }
    }

    const checkAuthState = async () => {
        try {
            let cogntioRes = await Auth.currentAuthenticatedUser();
            let currentUser = await API.get(apiName, `/user/${cogntioRes?.attributes?.sub}`)
            let roleArray = currentUser?.role;
            if (!roleArray?.includes("TRACKER")) return navigate("/*");
            let access = currentUser?.getUser?.approvalAccess;
            setRoles([...roleArray, access])
            if (!roleArray) {
                navigate('/wait');
                return;
            }
            let res = await API.get(apiName, `/vehicle-type`)
            setVehicleTypes(res.items)
            getVehicleList(currentUser?.transporter_id, roleArray)
        } catch (e) {
            await Auth.signOut()
            navigate('/');
            console.log(e);
        }
    }

    const updateTripHistoryIndex = () => {
        if (tripHistoryIndex < dateFilters.end) {
            setTripHistoryIndex(index => index + 60);
            timer.current = setTimeout(updateTripHistoryIndex, 100 * playForward)
        }
    }

    const playRev = () => {
        onPause()
        if (tripHistoryIndex < dateFilters.end) {
            setTripHistoryIndex(index => index - 60);
            timer.current = setTimeout(playRev, 100 * playForward)
        }
        setPlayTripHistory(true);
    }


    const onPlay = () => {
        onPause()
        updateTripHistoryIndex();
        setPlayTripHistory(true);
    }

    const onPause = () => {
        setPlayTripHistory(false);
        clearTimeout(timer.current);
    }

    const getVehicleList = async (transporterId, roles) => {
        try {
            let route = []
            setLoader(true)
            let query = `/vehicle?take=${999999999}`
            if (transporterId) query = `${query}&transporter_id=${transporterId}`

            let data = await API.get(apiName, query)
            let vehiclesMasterList = {}

            data.items = data.items.filter(item => item.deleted === false && item.gps_number !== null)

            if (!roles.includes("USERS") && !roles.includes("CONTROL_TOWER")) {
                data.items = data.items.filter(item => item?.type_id !== 10004)
            }

            let types = roles.filter(item => +item === Number(item))

            data.items = data.items.filter(item => types.includes(String(item.type_id)))
            let res = await API.get(apiName, '/dashboard/settings')
            let max_speed = res?.find(item => item?.name === "max_speed")?.value
            setSettings(res)

            await fetch(res?.find(item => item?.name === "route")?.value)
                .then(function (response) {
                    return response.text();
                })
                .then(function (xml) {
                    route = kml(new DOMParser().parseFromString(xml, "text/xml")).features
                    setRoute(route)
                    setTempRoute(route)
                });

            let x = []
            await fetch(res?.find(item => item?.name === "geo_fence")?.value)
                .then(function (response) {
                    return response.text();
                })
                .then(function (xml) {
                    x = kml(new DOMParser().parseFromString(xml, "text/xml")).features
                    x = x.sort((a, b) => a.properties.name.localeCompare(b.properties.name))
                    setGeoFence(x)

                });

            for (let item of data.items) {
                let items = {
                    id: item.id,
                    deviated: item?.type_id === 10003 ? (item?.deviated || false) : false,
                    ignition: item?.ignition,
                    location: { latitude: +item?.lat || 24.545407, longitude: +item?.lng || 87.451069 },
                    gsmSignal: item?.signal,
                    speed: item?.speed,
                    gpsUpdatedAt: +item?.ts,
                    segment: item?.segment,
                    moving: item.moving === null || item.moving === false ? false : true,
                    offline: item.offline === null || item.offline === true ? true : false,
                    idle: item.idle === null || item.idle === false ? false : true,
                    halted: item.halted === null || item.halted === false ? false : true,
                    deviated_since: +item?.deviated_since,
                    idle_since: +item?.idle_since,
                    halted_since: +item?.halted_since,
                    offline_since: +item?.offline_since,
                    voltage: item?.voltage,
                    loaded: item?.loaded ?? false,
                    type_id: item?.type_id,
                    geofence: getGeofenceName(x, { latitude: +item?.lat || 24.545407, longitude: +item?.lng || 87.451069 })
                }

                vehiclesMasterList[item.id] = { ...item, ...items }
            }
            setVehicles(vehiclesMasterList);

            setLoader(false)
            Amplify.configure(gen.config)
            const subscription2 = gen.subscribe("vehicle_data", ({ data }) => {
                if (data) {
                    const res = JSON.parse(data)
                    const oldVehicle = Object.values(vehiclesMasterList).find(item => item.id === res.id)
                    if (oldVehicle) {
                        vehiclesMasterList[oldVehicle?.id] = { ...vehiclesMasterList[oldVehicle?.id], loaded: res?.loaded, suspicious: res?.suspicious };
                        let temp = structuredClone(vehiclesMasterList)
                        setVehicles(temp)
                    }
                }
            })

            const subscription = gen.subscribe("vehicle", ({ data }) => {
                const vehicle = JSON.parse(data);
                const oldVehicle = Object.values(vehiclesMasterList).find(item => item.gps_number === vehicle.gpsNumber)
                vehicle.id = oldVehicle?.id

                if (vehicle && vehiclesMasterList[vehicle?.id] && oldVehicle) {
                    let myVehicle = vehiclesMasterList[vehicle?.id]

                    if (myVehicle?.speed < max_speed && vehicle?.speed > max_speed) {
                        toast.error(`${vehicle?.id} is overspeeding at ${vehicle?.speed} km/h.`, { position: "bottom-right" })
                        if (audioRef.current && audioRef.current.muted === false) audioRef.current.play();
                    }

                    if (moment.unix(moment().unix()).diff(moment.unix(vehicle?.idle_since), 'hours') > 2 && myVehicle.idleAlertGenerated !== true) {
                        toast.error(`${vehicle?.id} is idle for more than 2 hours. `, { position: "bottom-right" })
                        myVehicle.idleAlertGenerated = true
                    }

                    if (myVehicle?.suspicious === false && vehicle?.suspicious === true) {
                        toast.error(`${vehicle?.id} is suspicious.`, { position: "bottom-right" })
                        if (audioRef.current && audioRef.current.muted === false) audioRef.current.play();
                    }

                    myVehicle.speed = vehicle.speed
                    myVehicle.gsmSignal = vehicle.gsmSignal
                    myVehicle.gpsUpdatedAt = vehicle.gpsUpdatedAt
                    myVehicle.gsmSignal = vehicle.signal
                    myVehicle.ignition = vehicle.ignition
                    myVehicle.moving = vehicle.moving || false
                    myVehicle.halted = vehicle.halted || false
                    myVehicle.offline = vehicle.offline
                    myVehicle.idle = vehicle.idle || false
                    myVehicle.deviated_since = +vehicle.deviated_since
                    myVehicle.idle_since = +vehicle.idle_since
                    myVehicle.halted_since = +vehicle.halted_since
                    myVehicle.offline_since = +vehicle.offline_since
                    myVehicle.suspicious = vehicle?.suspicious || false

                    //tampered alert
                    // if(myVehicle?.voltage > 3500 && vehicle?.voltage < 3500){
                    //     toast.error(`${vehicle?.id} is tampered.`, { position: "bottom-right" })
                    //     if (audioRef.current && audioRef.current.muted === false) audioRef.current.play();
                    // }
                    myVehicle.voltage = +vehicle?.voltage

                    if (myVehicle.deviated === false && vehicle.deviated === true && myVehicle?.loaded === true && myVehicle?.type_name === "COAL_TIPPER") {
                        toast.error(`${vehicle?.id} is deviated from ${oldVehicle?.segment}`, { position: "bottom-right" })
                        if (audioRef.current && audioRef.current.muted === false) audioRef.current.play();
                    }
                    myVehicle.deviated = myVehicle?.type_name === "COAL_TIPPER" ? vehicle.deviated : false

                    if (myVehicle.deviated === false && vehicle.deviated === true) myVehicle.deviatedAt = vehicle.gpsUpdatedAt
                    if (myVehicle.deviated === true && vehicle.deviated === false) myVehicle.deviatedAt = null

                    if (vehicle?.latitude && vehicle?.longitude) {
                        let location = { latitude: +vehicle?.latitude, longitude: +vehicle?.longitude }
                        myVehicle.geofence = getGeofenceName(x, location)
                        myVehicle.location = location
                        myVehicle.segment = vehicle.segment
                    }
                    vehiclesMasterList[vehicle.id] = myVehicle;
                    let temp = structuredClone(vehiclesMasterList)
                    setVehicles(temp)
                }
            })
            Amplify.configure(awsconfig);
            return () => {
                subscription.unsubscribe()
                subscription2.unsubscribe()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getTripList = async () => {
        try {

            let data = await API.get(apiName, `/gatepass?wb_start=${moment().startOf('day').unix()}&wb_end=${moment().endOf('day').unix()}&take=${99999999}`)

            let todaysUnloaded = await API.get(apiName, `/gatepass?rail_start=${moment().startOf('day').unix()}&take=${99999999}`)
            let unloadStat = {}

            for (let item of todaysUnloaded.items) {
                if (item.rail_entry_time) {
                    unloadStat[item.id] = item
                }
            }

            let trips = {}
            for (let item of data.items) {
                if (item?.wb_exit_time) {
                    trips[item.id] = item
                }
            }

            setTrips(trips);
            setTodaysUnloaded(unloadStat)

            Amplify.configure(gen.config)
            const subscription = gen.subscribe('gatepass', ({ data }) => {
                if (data) {
                    let trip = JSON.parse(data)
                    if (trip?.wb_exit_time !== null && trip?.wb_exit_time >= moment().startOf('day').unix()) {
                        let myTrip = trips[trip?.id]
                        myTrip = trip
                        setTrips((prev) => { return { ...prev, [trip?.id]: myTrip } })
                    }
                    if (trip?.rail_entry_time !== null && trip?.rail_entry_time >= moment().startOf('day').unix()) {
                        let currUnload = unloadStat[trip?.id]
                        currUnload = trip
                        setTodaysUnloaded((prev) => { return { ...prev, [trip?.id]: currUnload } })
                    }
                }
            })
            Amplify.configure(awsmobile)
            return () => {
                subscription.unsubscribe()
            }

        } catch (error) {
            console.log(error)
        }

    }
    const onLoadMap = (google) => { }

    const getVehicles = () => {
        let items = Object.values(vehicles);

        if (filter.route) items = items.filter(x => x.route_id === filter.route);
        if (filter.type) items = items.filter(x => x.type_id === filter.type);
        if (filter.type && filter.route) items = items.filter(x => x.route_id === filter.route && x.type_id === filter.type)
        if (filter.segment) items = items.filter(x => x.segment === filter.segment)
        if (filter.transporterId) items = items.filter(x => x.transporter_id === filter.transporterId)
        if (filter.geofence) items = items.filter(x => x.geofence === filter.geofence)


        if (filter.loaded !== null) items = items.filter(x => x.loaded === filter.loaded);
        if (filter.deviated !== null) items = items.filter(x => x.deviated === filter.deviated && x.loaded === true)
        if (filter.moving) items = items.filter(x => x.moving === true && x.loaded === true)
        if (filter.idle) items = items.filter(x => x.loaded === true && x.idle === true)
        if (filter.halted) items = items.filter(x => x.loaded === true && x.halted === true)
        if (filter.offline) items = items.filter(x => (x.loaded === true && x.offline === true))
        if (filter.brakedown) items = items.filter(x => (x.loaded === true && x.isbrakedown === true))

        // unloaded vehicles
        if (filter.deviated_empty !== null) items = items.filter(x => x.deviated === filter.deviated_empty && x.loaded !== true)
        if (filter.moving_empty) items = items.filter(x => x.moving === true && x.loaded !== true)
        if (filter.idle_empty) items = items.filter(x => x.loaded !== true && x.idle === true)
        if (filter.halted_empty) items = items.filter(x => x.loaded !== true && x.halted === true)
        if (filter.offline_empty) items = items.filter(x => (x.loaded !== true && x.offline === true))
        if (filter.brakedown_empty) items = items.filter(x => (x.loaded !== true && x.isbrakedown === true))
        if (filter.loaders) items = items.filter(x => x.type_id === 10026)
        if (filter.dumpers) items = items.filter(x => x.type_id === 10027)
        if (filter?.vehicles?.length > 0) {
            items = []
            for (var vehicle of filter?.vehicles) {
                items.push(vehicles[vehicle.value])
            }
        }
        return items;
    }

    function filterStats() {
        let myVehicles = Object.values(vehicles);
        let myTrips = Object.values(trips);
        let unloadStat = Object.values(todaysUnloaded)

        let stats = { all: myVehicles.length }

        if (filter.segment) myVehicles = myVehicles?.filter(item => item?.segment === filter.segment)
        if (filter?.route) {
            myVehicles = myVehicles?.filter(item => item?.route_id === filter?.route)
            myTrips = myTrips?.filter(item => item?.route_id === filter?.route)
            unloadStat = unloadStat?.filter(item => item?.route_id === filter?.route)
        }
        if (filter.type) myVehicles = myVehicles?.filter(item => item?.type_id === filter.type)
        if (filter.transporterId) myVehicles = myVehicles?.filter(item => item?.transporter_id === filter.transporterId)
        if (filter.geofence) myVehicles = myVehicles?.filter(item => item?.geofence === filter.geofence)
        if (filter.transporterId) myTrips = myTrips?.filter(item => item?.transporter_id === filter.transporterId)


        if (filter.segment && filter.type) myVehicles = myVehicles?.filter(item => item?.segment === filter.segment && item?.type_id === filter.type)
        if (filter.route && filter.type) myVehicles = myVehicles?.filter(item => item?.route_id === filter.route && item?.type_id === filter.type)

        stats.all = myVehicles.length;
        stats.loaded = myVehicles.filter(x => x.loaded === true).length;
        stats.in_route = myVehicles.filter(x => x.loaded === true && x.deviated === false).length;
        stats.deviated = myVehicles.filter(x => x.loaded === true && x.deviated === true).length;
        stats.moving = myVehicles.filter(x => x.loaded === true && x.moving === true && x.offline === false).length;
        stats.idle = myVehicles.filter(x => x.loaded === true && x.idle === true && x.offline === false).length;
        stats.halted = myVehicles.filter(x => x.loaded === true && x.halted === true && x.offline === false).length
        stats.offline = myVehicles.filter(x => (x.loaded === true && x.offline === true)).length
        stats.brakedown = myVehicles.filter(x => (x.loaded === true && x.isbrakedown === true)).length

        //unloaded stats
        stats.unloaded = myVehicles.filter(x => x.loaded === false).length;
        stats.in_route_empty = myVehicles.filter(x => x.loaded === false && x.deviated === false).length;
        stats.deviated_empty = myVehicles.filter(x => x.loaded === false && x.deviated === true).length;
        stats.moving_empty = myVehicles.filter(x => x.loaded === false && x.moving === true && x.offline === false).length;
        stats.idle_empty = myVehicles.filter(x => x.loaded === false && x.idle === true && x.offline === false).length;
        stats.halted_empty = myVehicles.filter(x => x.loaded === false && x.halted === true && x.offline === false).length
        stats.offline_empty = myVehicles.filter(x => x.loaded === false && x.offline === true).length
        stats.brakedown_empty = myVehicles.filter(x => (x.loaded === false && x.isbrakedown === true)).length
        stats.loaders = myVehicles.filter(x => x.type_id === 10026).length
        stats.dumpers = myVehicles.filter(x => x.type_id === 10027).length

        stats.today_loaded = myTrips?.length;
        stats.today_unloaded = unloadStat.filter(x => x?.rail_entry_time !== null).length;

        setStats(stats)

        changeColor()
    }

    const getHindranceReason = async () => {
        try {
            let res = await API.get(apiName, '/hindrance/get_reasons')
            setHindranceReasons(res)
        } catch (error) {
            console.log(error)
        }
    }

    const changeColor = async () => {
        let data = []
        let x = structuredClone(tempRoute)

        for (let item of x) {
            let vehicleCount = Object.values(vehicles).filter(x => x.segment === item?.properties?.name).length
            let vehicleCountLoaded = Object.values(vehicles).filter(x => x.segment === item?.properties?.name && x.loaded === true)
            data.push({ name: item?.properties?.name, count: vehicleCount, loaded: vehicleCountLoaded.length })
        }

        data = data.sort((a, b) => b.count - a.count)
        for (let item of x) {
            if (item?.properties?.name === data[0].name) {
                item.properties.stroke = "#ED2939"
                item.count = data[0].count
                item.loaded_count = data[0].loaded
            }
            else if (item?.properties?.name === data[1].name) item.properties.stroke = "#fbbc05"
            else if (item?.properties?.name === data.at(-1).name) item.properties.stroke = "#34a853"
            else item.properties.stroke = "#4285f4"

        }
        setRoute(x)

    }

    const applyFilter = (type, status) => {
        let temp = { ...filter }
        for (let item in temp) {
            if (item === type) temp[item] = status
            else if (item === "type" || item === "route" || item === "segment") { }
            else temp[item] = null
        }
        setFilter(temp)
    }

    const onMapClick = (e) => {
        if (showDistanceMarker) {
            if (distanceMarkers.length < 2) {
                distanceMarkers.push({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })
            }

            setDistanceMarkers(distanceMarkers)

            if (distanceMarkers.length === 2) {
                const distance = getDistance(distanceMarkers[0], distanceMarkers[1])
                if (distance < 1000) alert(`Distance: ${distance} mts`)
                else alert(`Distance: ${((distance / 1000)).toFixed(2)} kms`)
            }
        }
    }

    const handleDateApplied = (event, picker) => {
        const fromDate = moment(picker.startDate).unix()
        const toDate = moment(picker.endDate).unix()

        const start = moment.unix(fromDate).unix()
        const end = moment.unix(toDate).unix();

        setDateFilters({ start, end });
    };

    const searchTripHistory = async () => {
        try {
            setLoader(true);
            setTotalDistance([]);

            const myTripHistory = [...filter.vehicles];
            for (var vehicle of filter.vehicles) {
                vehicle.locations = [];
                vehicle.color = randomColor();
                vehicle.id = vehicle.value;

                let temp = Object.values(vehicles).find(
                    (item) => item.id === vehicle.value
                );
                vehicle.route = { name: temp.route_name };
                vehicle.type = temp.type_id;
                vehicle.transporter = { name: temp.transporter_name };

                try {
                    let query = `/report/distance?start=${dateFilters?.start}&end=${dateFilters?.end}&vehicle_id=${vehicle?.value}`;
                    let distanceTravelled = await API.get(apiName, query);
                    setTotalDistance((prev) => [...prev, ...distanceTravelled]);
                } catch (error) {
                    console.log(error)
                }

                let response = await API.get(
                    apiName,
                    `/vehicle/vehicle-location/log?start=${dateFilters?.start}&end=${dateFilters?.end}&vehicle_id=${vehicle?.value}`
                );
                let res = response.items;
                let formattedData = res.map((data) => {
                    return {
                        id: data.vehicle_id,
                        location: {
                            latitude: data.lat,
                            longitude: data.lng,
                        },
                        speed: data.speed,
                        ignition: data.ignition,
                        gsmSignal: data.signal,
                        enteredAt: +data.ts,
                        loaded: data.loaded,
                        gpsUpdatedAt: +data.ts,
                        deviated: data.deviated,
                        idle: data.idle,
                    };
                });

                vehicle.locations = vehicle.locations.concat(formattedData);

                let path = [];
                if (vehicle.locations.length === 0) {
                    setLoader(false);
                    return toast.error("No data found.");
                }
                for (var item of vehicle.locations) {
                    path.push({
                        lat: item.location.latitude,
                        lng: item.location.longitude,
                    });
                }
                vehicle.path = path;
            }

            setInitialView({
                center: {
                    lat: 24.495771731686176,
                    lng: 87.57790405529408,
                },
                zoom: 11,
            });
            setTripHistoryIndex(dateFilters.start);
            setTripHistory(myTripHistory);
            setLoader(false);
        } catch (error) {
            console.log(error)
        }
    }

    const getVehicleLocation = (index) => {
        if (tripHistoryIndex > 0) {
            let location = tripHistory[index].locations
                .filter(x => x.enteredAt < tripHistoryIndex)
                .sort((a, b) => a.enteredAt - b.enteredAt)
                .pop()

            return location || tripHistory[index].locations[0]
        }
    }

    const nearbyPetrollingVehicle = ({ latitude, longitude }) => {
        let allSecurityVehicles = Object.values(vehicles).filter(item => item.type_name === "SECURITY")

        let distance = []
        for (let item of allSecurityVehicles) {
            distance.push({ id: item?.id, distance: getDistance({ latitude, longitude }, { latitude: item?.location?.latitude, longitude: item.location?.longitude }) })
        }

        let sortedDistance = distance.sort((a, b) => a.distance - b.distance);
        return sortedDistance[0]?.id
    }

    const nearbyWaypoint = ({ latitude, longitude }) => {
        const allWaypoints = waypoints.map(location => {
            const object = {};
            for (const key in location) {
                object[key] = location[key];
            }
            return object;
        });

        let distance = []
        for (let item of allWaypoints) {
            distance.push({ name: item?.location, distance: getDistance({ latitude, longitude }, { latitude: item?.lat, longitude: item.lng }) })
        }

        let sortedDistance = distance.sort((a, b) => a.distance - b.distance);
        return sortedDistance[0]?.name
    }

    const resetFilters = () => {
        setFilter({
            vehicles: null,
            route: null,
            segment: null,
            loaded: null,
            deviated: null,
            idle: null,
            moving: null,
            halted: null,
            offline: null,
            loaded_empty: null,
            deviated_empty: null,
            idle_empty: null,
            moving_empty: null,
            halted_empty: null,
            offline_empty: null,
            transporterId: null,
            brakedown: null,
            loaders: null,
            dumpers: null,
            geofence: null
        });
        setSearchParams({})
        setDateFilters(null)
        onPause()
        setTripHistoryIndex(0)
        setPlayTripHistory(false)
        setTripHistory([])
        setTotalDistance([])

        setInitialView({
            center: {
                lat: 24.523795,
                lng: 87.554292
            },
            zoom: 11
        })

        setDistanceMarkers([]);
        setShowDistanceMarker(false);
        setTripHistory([]);
        setMode(0);
    }

    const isInGeofence = (location) => {
        for (const feature of geoFence) {
            let polygon = [];
            for (let coordinate of feature.geometry.coordinates[0]) {
                polygon.push({
                    latitude: coordinate[1],
                    longitude: coordinate[0]
                });
            }
            const status = geolib?.isPointInPolygon(location, polygon);
            if (status === true) return true;
        }
        return false;
    }
    function getGeofenceName(geofence, location) {
        for (const feature of geofence) {
            let polygon = [];
            for (let coordinate of feature.geometry.coordinates[0]) {
                polygon.push({
                    latitude: coordinate[1],
                    longitude: coordinate[0]
                });
            }
            const status = geolib?.isPointInPolygon(location, polygon);
            if (status === true) return feature.properties.name;
        }
        return null;
    }

    async function createHindrance() {
        try {
            let vehicle_loaded = Object.values(vehicles).filter(item => item?.segment === hindranceData.segment && item.loaded === true).length
            let vehicle_unloaded = Object.values(vehicles).filter(item => item?.segment === hindranceData.segment && item.loaded !== true).length
            Swal.showLoading()
            await API.post(apiName, '/hindrance', { body: { ...hindranceData, vehicle_loaded, vehicle_unloaded, opened_at: hindranceData.opened_at || moment().unix() } })
            Swal.close()
            toast.success("Record Created.")
            setHindranceData({})
            setShowHindranceMarker(false)
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong!")
        }
    }
    async function getHindrance(setState) {
        try {
            let res = await API.get(apiName, '/hindrance?close=false')
            setState(res.items)
        } catch (error) {
            console.log(error)
        }
    }

    async function updateHindrance() {
        try {
            Swal.showLoading()
            await API.put(apiName, `/hindrance/${hindranceData?.id}`, { body: { ...hindranceData } })
            Swal.close()
            toast.success(`Hindrance ${hindranceData?.closed ? "closed" : "updated"}.`)
            setHindranceData({})
            setShowHindranceMarker(false)
            setAllHindranceData([])
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        let abortController = new AbortController();
        const loadData = async () => {
            await Promise.all([
                checkAuthState(),
                getTripList(),
                getHindrance(setHindranceCount),
                getHindranceReason()
            ]);
        }

        setInitialView({
            center: {
                lat: 24.523795,
                lng: 87.554292
            },
            zoom: 11
        })

        loadData()
        return () => {
            abortController.abort();
        }
    }, [])

    useEffect(() => {
        filterStats()
    }, [vehicles, trips, filter]);

    useEffect(() => {
        if (filter.segment) {
            setInitialView({
                center: {
                    lat: getVehicles()[0] && getVehicles()[0]?.location?.latitude || 24.523795,
                    lng: getVehicles()[0] && getVehicles()[0].location?.longitude || 87.554292
                },
                zoom: 11
            })
        }
    }, [filter.segment])

    useEffect(() => {
        if (filter.vehicles && filter.vehicles.length > 0) {
            let vehicle = Object.values(vehicles).find(item => item.id === filter.vehicles.at(-1).label)
            setInitialView({
                center: {
                    lat: vehicle?.location?.latitude,
                    lng: vehicle.location?.longitude
                },
                zoom: 16
            })
        }
    }, [filter.vehicles])


    useEffect(() => {
        if (tripHistoryIndex === dateFilters?.end) {
            onPause()
            return;
        }
        if (dateFilters) {
            let diff = (dateFilters.end - dateFilters.start) / 100
            let currentDiff = Math.floor((tripHistoryIndex - dateFilters.start) / diff)
            setSliderValue(currentDiff)
        }


    }, [tripHistoryIndex])


    useEffect(() => {
        if (moment().startOf("day") === moment()) {
            window.location.reload()
        }
    }, [moment()])


    return (
        <>
            <div className='map-top-bar'>
                <div className='row '>
                    <div className='col-auto px-0'>
                        <button className={`btn ${view === "roadmap" ? "btn-primary" : "btn-white"} ms-2`} onClick={() => setView("roadmap")}>Map</button>
                        <button className={`btn ${view === "hybrid" ? "btn-primary" : "btn-white"} ms-2`} onClick={() => setView("hybrid")}> Satelite</button>
                    </div>
                    {(roles?.includes("USERS") || roles?.includes("ADMIN") || roles?.includes("MANAGER") || roles?.includes("APPROVAL_CONTROL_ROOM")) && (
                        <div className='col-auto pe-0 w-25'>
                            <AsyncSelect defaultValue={filter.vehicles} isMulti isClearable theme={selectColor} placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => setFilter({ ...filter, vehicles: e })} />
                        </div>
                    )}

                    {mode === 0 && (roles?.includes("USERS") || roles?.includes("ADMIN") || roles?.includes("MANAGER") || roles?.includes("APPROVAL_CONTROL_ROOM")) && (
                        <div className='col-auto pe-0 '>
                            <AsyncSelect isClearable theme={selectColor} placeholder="Select Transporter" cacheOptions loadOptions={handleSearchTransporter} defaultOptions onChange={(e) => setFilter({ ...filter, transporterId: e?.value })} />
                        </div>
                    )}
                    <div className='col-auto pe-0'>
                        {mode === 0 &&
                            <div className='d-flex gap-2 w-100 align-items-start'>
                                {(roles?.includes("USERS") || roles?.includes("ADMIN") || roles?.includes("MANAGER") || roles?.includes("APPROVAL_CONTROL_ROOM")) && (
                                    <AsyncSelect theme={selectColor} isClearable required placeholder="Vehicle Type" cacheOptions loadOptions={handleSearchVehicleType} defaultOptions
                                        onChange={(e) => setFilter({ ...filter, type: e?.value })}
                                    />
                                )}
                                <AsyncSelect theme={selectColor} isClearable required placeholder="Route" cacheOptions loadOptions={handleSearchRoute} defaultOptions
                                    onChange={(e) => setFilter({ ...filter, route: e?.value })}
                                />

                                <button className='btn btn-white ms-2' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded={segmentOptions} onClick={() => setSegmentOptions(!segmentOptions)}>
                                    <div className='text-start'>Section: {filter.segment || 'All'}
                                        <i className={`ms-2 pt-3 fe fe-chevron-${segmentOptions ? 'up' : 'down'}`}></i>
                                    </div>
                                </button>
                                <div className='dropdown-menu dropdown-menu-end'>
                                    {route?.map((item, index) => (
                                        <span onClick={() => {
                                            setSearchParams({ segment: item.properties.name })
                                            setFilter({ ...filter, segment: item.properties.name })
                                        }} className='dropdown-item pointer' key={index}>
                                            {item.properties.name}
                                        </span>
                                    ))}
                                    <span className='dropdown-item pointer' onClick={() => {
                                        setSearchParams({})
                                        setFilter({ ...filter, segment: null })
                                    }} >All</span>
                                </div>
                                <button className='btn btn-white ms-2' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded={geoFenceOptions} onClick={() => setGeoFenceOption(!geoFenceOptions)}>
                                    <div className='text-start'>Geofence: {filter.geofence || 'All'}
                                        <i className={`ms-2 pt-3 fe fe-chevron-${geoFenceOptions ? 'up' : 'down'}`}></i>
                                    </div>
                                </button>
                                <div className='dropdown-menu dropdown-menu-end'>
                                    {geoFence?.map((item, index) => (
                                        <span onClick={() => {
                                            setFilter({ ...filter, geofence: item.properties.name })
                                            setInitialView({
                                                center: {
                                                    lat: item.geometry.coordinates[0][0][1],
                                                    lng: item.geometry.coordinates[0][0][0]
                                                },
                                                zoom: 17
                                            })
                                        }} className='dropdown-item pointer' key={index}>
                                            {item.properties.name}
                                        </span>
                                    ))}
                                    <span className='dropdown-item pointer' onClick={() => {
                                        setFilter({ ...filter, geofence: null })
                                        setInitialView({
                                            center: {
                                                lat: 24.495771731686176,
                                                lng: 87.57790405529408
                                            },
                                            zoom: 11
                                        })
                                    }} >All</span>
                                </div>
                                {(roles?.includes("USERS") || roles?.includes("APPROVAL_CONTROL_ROOM") || roles?.includes("ADMIN")) && (
                                    <button type='button' className='btn btn-white ms-2' onClick={() => setMode(1)}>
                                        <i className='fe fe-clock'></i>
                                    </button>)}
                            </div>
                        }

                        {mode === 1 &&
                            <div className='d-flex align-items-start'>
                                <DateRangePicker
                                    initialSettings={{
                                        timePicker: true,
                                        startDate: moment().startOf('day').valueOf() / 1000,
                                        endDate: moment().endOf('day').valueOf() / 1000,
                                        linkedCalendars: true,
                                        showCustomRangeLabel: true,
                                        showDropdowns: true,
                                        alwaysShowCalendars: true,
                                        startDate: moment().startOf('hour').toDate(),
                                        endDate: moment().startOf('hour').add(1, 'hour').toDate(),
                                    }}
                                    onApply={handleDateApplied}>
                                    <input className={`btn ml-2 pointer btn-white`} disabled={!filter.vehicles} />
                                </DateRangePicker>

                                <button type='button' className='btn btn-white ms-2' disabled={!dateFilters?.start} onClick={() => searchTripHistory()}>
                                    <i className='fe fe-search' /> Search
                                </button>
                            </div>
                        }
                    </div>
                    {mode === 1 && totalDistance.length && (

                        <div className='col-auto p-2 mx-2 bg-white rounded d-flex gap-3'>
                            {totalDistance?.map(item => (
                                <div>
                                    {item?.vehicle_id}
                                    <h4>Total Distance : {item?.total_distance} km</h4>
                                </div>
                            ))}
                        </div>
                    )}
                </div >

            </div >

            {(mode === 0 && !roles?.includes("PATROLLING_VEHICLE") || roles?.includes("ADMIN") || roles?.includes("MANAGER") || roles?.includes("USERS")) &&
                <>
                    <div className='map-left-bar'>
                        <div>
                            <button className={`btn w-100 btn-${filter.loaded === null ? 'primary' : 'light'}`} onClick={() => applyFilter()}>
                                <div className='small font-weight-bold'>All</div>
                                <h1 className='mb-0 mt-1'>{stats.all || '-'}</h1>
                            </button>
                        </div>


                        <div className='d-flex mt-2'>
                            <div className='d-flex gap-2 flex-column p-0 me-2'>
                                <button className={`btn w-100 btn-${filter.loaded === true ? 'primary' : 'light'} `} onClick={() => applyFilter("loaded", true)}>
                                    <div className='small font-weight-bold'>Loaded</div>
                                    <h1 className='mb-0 mt-1'>{stats.loaded || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.deviated === false ? 'primary' : 'light'} `} onClick={() => applyFilter("deviated", false)}>
                                    <div className='small font-weight-bold'>In Route</div>
                                    <h1 className='mb-0 mt-1'>{stats.in_route || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.deviated === true ? 'primary' : 'light'} `} onClick={() => applyFilter("deviated", true)}>
                                    <div className='small font-weight-bold'>Deviated</div>
                                    <h1 className='mb-0 mt-1'>{stats.deviated || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.moving === true ? 'primary' : 'light'} `} onClick={() => applyFilter("moving", true)}>
                                    <div className='small font-weight-bold'>Moving</div>
                                    <h1 className='mb-0 mt-1'>{stats.moving || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.idle === true ? 'primary' : 'light'} `} onClick={() => applyFilter("idle", true)}>
                                    <div className='small font-weight-bold'>Idle</div>
                                    <h1 className='mb-0 mt-1'>{stats.idle || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.halted === true ? 'primary' : 'light'} `} onClick={() => applyFilter("halted", true)}>
                                    <div className='small font-weight-bold'>Halted</div>
                                    <h1 className='mb-0 mt-1'>{stats.halted || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.offline === true ? 'primary' : 'light'} `} onClick={() => applyFilter("offline", true)}>
                                    <div className='small font-weight-bold'>Offline</div>
                                    <h1 className='mb-0 mt-1'>{stats.offline || '-'}</h1>
                                </button>
                                {roles.includes("BREAKDOWN_VIEW") && (
                                    <button className={`w-100 btn btn-${filter.brakedown === true ? 'primary' : 'light'} `} onClick={() => applyFilter("brakedown", true)}>
                                        <div className='small font-weight-bold'>Breakdown</div>
                                        <h1 className='mb-0 mt-1'>{stats.brakedown || '-'}</h1>
                                    </button>
                                )}
                                <button className={`w-100 btn btn-${filter.loaders === true ? 'primary' : 'light'} `} onClick={() => applyFilter("loaders", true)}>
                                    <div className='small font-weight-bold'>Loaders</div>
                                    <h1 className='mb-0 mt-1'>{stats.loaders || '-'}</h1>
                                </button>
                            </div>


                            <div className='d-flex gap-2 flex-column p-0 '>
                                <button className={`w-100 btn btn-${filter.loaded === false ? 'primary' : 'light'} `} onClick={() => applyFilter("loaded", false)}>
                                    <div className='small font-weight-bold'>Empty</div>
                                    <h1 className='mb-0 mt-1'>{stats.unloaded || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.deviated_empty === false ? 'primary' : 'light'} `} onClick={() => applyFilter("deviated_empty", false)}>
                                    <div className='small font-weight-bold'>In Route</div>
                                    <h1 className='mb-0 mt-1'>{stats.in_route_empty || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.deviated_empty === true ? 'primary' : 'light'} `} onClick={() => applyFilter("deviated_empty", true)}>
                                    <div className='small font-weight-bold'>Deviated</div>
                                    <h1 className='mb-0 mt-1'>{stats.deviated_empty || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.moving_empty === true ? 'primary' : 'light'} `} onClick={() => applyFilter("moving_empty", true)}>
                                    <div className='small font-weight-bold'>Moving</div>
                                    <h1 className='mb-0 mt-1'>{stats.moving_empty || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.idle_empty === true ? 'primary' : 'light'} `} onClick={() => applyFilter("idle_empty", true)}>
                                    <div className='small font-weight-bold'>Idle</div>
                                    <h1 className='mb-0 mt-1'>{stats.idle_empty || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.halted_empty === true ? 'primary' : 'light'} `} onClick={() => applyFilter("halted_empty", true)}>
                                    <div className='small font-weight-bold'>Halted</div>
                                    <h1 className='mb-0 mt-1'>{stats.halted_empty || '-'}</h1>
                                </button>
                                <button className={`w-100 btn btn-${filter.offline_empty === true ? 'primary' : 'light'} `} onClick={() => applyFilter("offline_empty", true)}>
                                    <div className='small font-weight-bold'>Offline</div>
                                    <h1 className='mb-0 mt-1'>{stats.offline_empty || '-'}</h1>
                                </button>
                                {roles.includes("BREAKDOWN_VIEW") &&
                                    (<button className={`w-100 btn btn-${filter.brakedown_empty === true ? 'primary' : 'light'} `} onClick={() => applyFilter("brakedown_empty", true)}>
                                        <div className='small font-weight-bold'>Breakdown</div>
                                        <h1 className='mb-0 mt-1'>{stats.brakedown_empty || '-'}</h1>
                                    </button>)
                                }
                                <button className={`w-100 btn btn-${filter.dumpers === true ? 'primary' : 'light'} `} onClick={() => applyFilter("dumpers", true)}>
                                    <div className='small font-weight-bold'>Dumpers</div>
                                    <h1 className='mb-0 mt-1'>{stats.dumpers || '-'}</h1>
                                </button>
                            </div>
                        </div>

                    </div >
                    {(roles?.includes("USERS") || roles?.includes("ADMIN") || roles?.includes("MANAGER") || roles?.includes("APPROVAL_CONTROL_ROOM")) && (
                        <div className='map-right-bar'>
                            <div className='mt-2'>
                                <button className={`btn w-100 btn-light `}>
                                    <div className='small font-weight-bold'>Today's Loaded</div>
                                    <div className='display-2 mb-0 mt-1'>{stats.today_loaded || '-'}</div>
                                </button>
                            </div>
                            <div className='mt-2'>
                                <button className={`w-100 btn btn-light `}>
                                    <div className='small font-weight-bold'>Today's Unloaded</div>
                                    <div className='display-2 mb-0 mt-1'>{stats?.today_unloaded || '-'}</div>
                                </button>
                            </div>
                            <div className='mt-2'>
                                <button className={`w-100 btn btn-light `}>
                                    <div className='small font-weight-bold'>Trips In Transit</div>
                                    <div className='display-2 mb-0 mt-1'>{stats.loaded || '-'}</div>
                                </button>
                            </div>
                            {roles.includes("HINDRANCE_VIEW") && (
                                <div className='card p-3 btn btn-white fw-bold mt-5 ripple_image' onClick={() => setAllHindranceData((prev) => prev.length ? [] : hindranceCount)}>
                                    <i className='fe fe-info text-danger'></i>{" "}
                                    <span className='m-0'>Jam in {hindranceCount.length} Places</span>
                                </div>
                            )}
                        </div>
                    )}

                </>
            }
            <div className='map-right-bottom-bar d-flex flex-column gap-2'>
                {roles?.includes('HINDRANCE_EDIT') && (
                    <div className='col-auto px-0'>
                        <button className={`btn ${showHindranceMarker ? 'btn-primary' : 'btn-white'} ms-2`} onClick={async () => {
                            setShowHindranceMarker(!showHindranceMarker)
                            if (!showHindranceMarker) {
                                Swal.fire({
                                    text: "Select a vehicle in the congested traffic area or choose 'See Active Hindrance' to see all hindrance",
                                    imageUrl: "/img/hindrance.png",
                                    imageWidth: 200,
                                    imageHeight: 200,
                                    imageAlt: "Custom image",
                                    confirmButtonColor: '#407050',
                                    cancelButtonColor: 'red',
                                    showDenyButton: true,
                                    denyButtonText: 'See Active Hindrance',
                                    confirmButtonText: 'Create Hindrance',
                                    denyButtonColor: '#e4a950'
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        console.log('Confirm button was clicked');
                                    } else if (result.isDenied) {
                                        await getHindrance(setAllHindranceData)
                                    } else if (result.isDismissed) {
                                        console.log('Modal was dismissed');
                                    }
                                });
                            } else setAllHindranceData([])
                        }}>
                            <img src='/img/hindrance.png' height={16}></img>
                        </button>
                    </div>
                )}

                <div className='col-auto px-0'>
                    <button className='btn btn-white ms-2 ' onClick={resetFilters}>
                        <i className='fe fe-refresh-ccw'></i>
                    </button>
                </div>
                {mode === 0 && (
                    <>
                        <div className='col-auto px-0' >
                            <button className='btn btn-white ms-2 ' onClick={() => { setMute(prev => prev === false ? true : false) }}>
                                <i className={`fe ${mute ? "fe-volume-x" : "fe-volume-2"}`}></i>
                            </button>
                        </div>

                        <div className='col-auto px-0'>
                            <button className={`btn ms-2 btn-${showDistanceMarker ? 'primary' : 'white'} `} onClick={() => { setShowDistanceMarker(!showDistanceMarker); setDistanceMarkers([]) }}>
                                <img src="./img/ruler.png" alt="" width={15} />
                            </button>
                        </div>
                        <div className='col-auto px-0'>
                            <button className='btn btn-white ms-2 ' onClick={() => setShowLegend(!showLegend)}>
                                <i className='fe fe-info'></i>
                            </button>
                        </div>
                    </>
                )}
            </div>
            {
                mode === 0 && <div className='map-bottom-bar bg-white fw-bold'>
                    {/* {Object.values(allSegments).find(item=>item?.color === "#ED2939")?.name} is having maximum number of vehicles. */}
                    <marquee>{settings?.find(item => item?.name === "notice")?.value}</marquee>
                </div>
            }
            {
                mode === 1 && tripHistoryIndex > 0 && <div className='map-bottom-bar bg-white fw-bold'>
                    <div>
                        <input type="range" className="form-range text-text-primary" step="0.5" value={sliderValue}
                            onChange={e => {
                                let diff = (dateFilters.end - dateFilters.start) / 100
                                setTripHistoryIndex(dateFilters.start + (diff * e.target.value))
                                setSliderValue(e.target.value)
                            }} />
                        <div className='d-flex justify-content-between w-100 z-0 '> <p className='m-0 small'>
                            {getPlayerDuration(dateFilters?.start, tripHistoryIndex)}</p>
                            <p className='m-0 small'>{getPlayerDuration(dateFilters?.start, dateFilters?.end)}
                            </p></div>
                    </div>
                    <div className='d-flex'>
                        <button className='btn btn-white ms-auto' onClick={playRev}><i className='fe fe-chevrons-left' /></button>
                        {playTripHistory ? <button type='button' className='btn btn-white ms-2' onClick={() => onPause()}>
                            <i className='fe fe-pause' /> Pause
                        </button> : <button type='button' className='btn btn-white ms-2' disabled={!dateFilters?.start} onClick={() => onPlay()}>
                            <i className='fe fe-play' /> Play
                        </button>}
                        <button className='btn btn-white mx-2' onClick={onPlay}><i className='fe fe-chevrons-right' /></button>
                        <div>
                            <Select
                                theme={selectColor}
                                placeholder="Speed"
                                menuPlacement='top'
                                defaultValue={{ value: 0, label: "1x" }}
                                options={[{ value: 0, label: "1x" }, { value: -2, label: "2x" }, { value: -4, label: "4x" }, { value: -8, label: "8x" }]}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: '40px',
                                        borderColor: '#e3ebf6'
                                    }),
                                }}
                                onChange={(e) => {
                                    onPause()
                                    setPlayForward(10 + e.value)
                                }}
                            />
                        </div>


                        <button type='button' className='btn btn-white  me-auto ms-2'>
                            {tripHistoryIndex > 0 ? <div className="d-flex gap-2 align-items-center small">
                                <div>{moment(tripHistoryIndex * 1000).format("DD-MMM-YYYY")}</div>
                                <div>{moment(tripHistoryIndex * 1000).format("h:mm a")}</div>
                            </div> : '-'}
                        </button>
                    </div>
                </div>
            }


            {/* End of Filters */}
            <MapLoader show={loader} >
                <MapLoader show={!isLoaded} >
                    <GoogleMap
                        zoom={initialView?.zoom}
                        center={initialView?.center}
                        mapContainerClassName='map-container'
                        onLoad={onLoadMap}
                        onClick={onMapClick}
                        options={{
                            mapTypeControl: false,
                            streetViewControl: true,
                            mapTypeId: view,
                        }}
                    >
                        <Marker position={{ lat: pickupAndDropMarkers?.dumka?.location?.latitude, lng: pickupAndDropMarkers?.dumka?.location?.longitude }} ></Marker>
                        <Marker position={{ lat: pickupAndDropMarkers?.coal_yard?.location?.latitude, lng: pickupAndDropMarkers?.coal_yard?.location?.longitude }} ></Marker>
                        <Marker position={{ lat: pickupAndDropMarkers?.pakur?.location?.latitude, lng: pickupAndDropMarkers?.pakur?.location?.longitude }} ></Marker>
                        <KmlLayer url={settings?.find(item => item.name === "geo_fence")?.value}></KmlLayer>

                        {mode === 0 && <div>
                            {route.map(item => (
                                <React.Fragment key={item?.properties?.name}>
                                    {item?.geometry?.type !== 'Point' && (
                                        <Polyline key={item?.properties?.name} path={item.geometry.coordinates.map(item => {
                                            return { lng: item[0], lat: item[1] }
                                        })} options={{ strokeColor: item.properties?.stroke, strokeOpacity: 1, strokeWeight: 6 }} />
                                    )}
                                </React.Fragment>
                            ))}
                            {!allHindranceData?.length && getVehicles().map((vehicle, index) => (<VehicleMarker key={index} index={index} vehicle={vehicle} nearbyVehicle={nearbyPetrollingVehicle} nearbyWaypoint={nearbyWaypoint} vehicleTypes={vehicleTypes} setVehicles
                                ={setVehicles} getVehicleList={getVehicleList} roles={roles} showHindranceMarker={showHindranceMarker} setHindranceData={setHindranceData} />))}

                            <TrafficLayer />
                        </div>}
                        {mode === 1 && <>
                            {tripHistory.map((vehicle, index) => <>
                                <Polyline index={index} key={vehicle.id} path={vehicle.path} options={{ strokeColor: vehicle.color, strokeOpacity: 1, strokeWeight: 5 }} />
                                {vehicle.locations.length > 0 && <VehicleHistoryMarker location={getVehicleLocation(index)} vehicle={vehicle} vehicleTypes={vehicleTypes} />}
                                {vehicle?.locations?.map(item => (<>{item.speed > 60 && <SpeedMarker key={item.id} item={item} />}</>))}
                                {vehicle?.locations?.map((item, index) => (<>{item.idle &&
                                    <>
                                        {isInGeofence(item.location) === false && (
                                            <UnscheduleMarker key={item.id} item={item} nextItem={vehicle?.locations[index - 1]?.gpsUpdatedAt} />
                                        )}
                                    </>
                                }</>))}
                            </>
                            )}
                        </>}

                        {distanceMarkers.map((marker, index) => (
                            <Marker key={index} position={{ lat: marker.latitude, lng: marker.longitude }} ></Marker>))}
                        {allHindranceData.map((marker, index) => (
                            <HindranceMarker key={marker.lat} marker={marker} setHindranceData={setHindranceData} roles={roles} />))}
                        {waypoints.map((marker, index) => (
                            <WayPointMarker key={index} lat={marker?.lat} lng={marker?.lng} name={marker?.location} />
                        ))}

                        {route.map(item => (
                            <React.Fragment key={item?.properties?.name}>
                                {item?.geometry?.type === 'Point' && (
                                    <WayPointMarker key={item?.properties?.name} lat={item?.geometry?.coordinates[1]} lng={item?.geometry?.coordinates[0]} name={item?.properties?.name} />
                                )}
                            </React.Fragment>
                        ))}
                    </GoogleMap>
                </MapLoader >
            </MapLoader>

            <div className='poweredBy-Tracker-page bg-white'>
                {/* <div className='d-flex gap-2'>
                    <img src='/img/logo.svg' alt='BGR_Background_Image' width={100} className='mt-3' />
                    <div>
                        <h4 className='m-0 text-primary '>Powered by</h4>
                        <img src="/img/shar.png" alt="shar" height={40} />
                    </div>
                    <a target='_blank' href='https://neetable.com/'>
                        <PoweredBy />
                    </a>
                </div> */}
                {/* <div>
                    <table className='table-bordered'>
                        <thead>
                            <tr>
                                <th className='text-center'>Geofence</th>
                                <th className='text-center'>Trucks</th>
                                <th className='text-center'>Loaders</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(geoFenceCount).map(item => (
                                <tr key={item}>
                                    <td align='center'>{item}</td>
                                    <td align='center'>{geoFenceCount[item].trucks}</td>
                                    <td align='center'>{geoFenceCount[item].loaders}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}
            </div>

            {
                showLegend && <div className={`floating-menu-data p-3 shadow`} >
                    {/* {legend?.map((item, index) => (
                    <div className={`row mb-3`} key={index}>
                        <div className='col-lg-3 col-md-3 col-sm-3'>
                            <img src={item?.image} alt='standard Map' className='rounded truckicon' />
                        </div>
                        <div className='col-lg-9 m-auto'>
                            <h3>{item?.name}</h3>
                        </div>
                    </div>))} */}
                    <div className='p-3'>
                        {vehicleTypes?.map(type => (
                            <div className={`row mb-3`} key={type.id} >
                                {type?.name}
                                <div className='d-flex justify-content-between gap-2'>
                                    <div>
                                        {Object.keys(JSON.parse(type?.icon)).map(item => (
                                            <h3 className='p-3'>{item}</h3>
                                        ))}
                                    </div>
                                    <div>
                                        {Object.values(JSON.parse(type?.icon)).map(item => (
                                            <div key={item} >
                                                <img src={item} alt='standard Map' className='rounded truckicon' />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
            <div className='visually-hidden'>
                <audio controls ref={audioRef} muted={mute} src="https://bgr-storage143049-prod.s3.ap-south-1.amazonaws.com/public/siren.mp3">
                    <source src="https://bgr-storage143049-prod.s3.ap-south-1.amazonaws.com/public/siren.mp3" type="audio/mpeg" />
                </audio>
            </div>
            <Modal show={hindranceData?.segment} onHide={() => setHindranceData({})} centered>
                <form className='row' onSubmit={(e) => {
                    e.preventDefault()
                    if (hindranceData?.created_at) updateHindrance()
                    else createHindrance()
                }}>
                    <Modal.Header>
                        <Modal.Title>Create Hindrance for {hindranceData?.segment}</Modal.Title>
                        loaded - {Object.values(vehicles).filter(item => item?.segment === hindranceData.segment && item.loaded === true).length}  <br />
                        unloaded - {Object.values(vehicles).filter(item => item?.segment === hindranceData.segment && item.loaded !== true).length}
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="form-group col-sm col-lg-6">
                                <label className="form-label mx-1">Start Date and Time</label>
                                <input
                                    required
                                    type="datetime-local"
                                    className="form-control"
                                    placeholder="Eg. Padum Nagar"
                                    disabled={hindranceData?.created_at}
                                    value={hindranceData?.opened_at ? moment.unix(hindranceData?.opened_at).format("YYYY-MM-DDTHH:mm") : moment().format("YYYY-MM-DDTHH:mm")}
                                    onChange={(e) => setHindranceData((prev) => ({ ...prev, opened_at: moment(e.target.value).unix() }))}
                                />
                            </Col>
                            <Col className="form-group col-sm col-lg-6">
                                <label className="form-label mx-1">Place</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Eg. Pachaibera Village"
                                    value={hindranceData?.place || ""}
                                    onChange={(e) => setHindranceData((prev) => ({ ...prev, place: e.target.value }))}
                                />
                            </Col>
                            <Col className='form-group col-sm col-lg-6'>
                                <label className="form-label mx-1">Reason</label>
                                <CreatableSelect
                                    required={true}
                                    theme={selectColor}
                                    placeholder="Select Reason"
                                    defaultValue={hindranceData?.reason ? { value: hindranceData?.reason, label: hindranceData?.reason } : null}
                                    options={hindranceReasons.map(item => {
                                        return { label: item.reason, value: item.reason }
                                    })}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: '40px',
                                            borderColor: '#e3ebf6'
                                        }),
                                    }}
                                    onChange={async (e) => {
                                        if (e.__isNew__ === true) await API.post(apiName, '/hindrance/create_reason', { body: { reason: e.value } })
                                        setHindranceData((prev) => ({ ...prev, reason: e?.value }))
                                    }}
                                />
                            </Col>
                            <Col className="form-group col-sm col-lg-6">
                                <label className="form-label mx-1">Thana</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Eg. Amrapara"
                                    value={hindranceData?.thana || ""}
                                    onChange={(e) => setHindranceData((prev) => ({ ...prev, thana: e.target.value }))}
                                />
                            </Col>
                            <Col className="form-group col-sm col-lg-6">
                                <label className="form-label mx-1">Shift</label>
                                <Select
                                    required
                                    theme={selectColor}
                                    placeholder="Select Shift"
                                    defaultValue={hindranceData?.shift ? { value: hindranceData?.shift, label: hindranceData?.shift } : null}
                                    options={shifts.map(item => {
                                        return { label: item, value: item }
                                    })}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: '40px',
                                            borderColor: '#e3ebf6'
                                        }),
                                    }}
                                    onChange={(e) => setHindranceData((prev) => ({ ...prev, shift: e?.value }))}
                                />
                            </Col>
                            <Col className="form-group col-sm col-lg-6">
                                <label className="form-label mx-1">Shift Person</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Eg. Alam"
                                    value={hindranceData?.shift_person || ""}
                                    onChange={(e) => setHindranceData((prev) => ({ ...prev, shift_person: e.target.value }))}
                                />
                            </Col>
                            <Col className="form-group col-sm col-lg-6">
                                <label className="form-label mx-1">Patrolling Team</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Eg. Church Street"
                                    value={hindranceData?.patrolling_team || ""}
                                    onChange={(e) => setHindranceData((prev) => ({ ...prev, patrolling_team: e.target.value }))}
                                />
                            </Col>
                            <Col className="form-group col-sm col-lg-12">
                                <label className="form-label mx-1">Feedback(Reason For Jam)</label>
                                <textarea
                                    className="form-control"
                                    value={hindranceData?.feedback || ""}
                                    onChange={(e) => setHindranceData((prev) => ({ ...prev, feedback: e.target.value }))}
                                />
                            </Col>
                            {hindranceData?.created_at && (
                                <>
                                    <Col className="form-group col-sm col-lg-6">
                                        <label className="form-label mx-1">End Date and Time</label>
                                        <input
                                            required={hindranceData?.closed}
                                            type="datetime-local"
                                            className="form-control"
                                            placeholder="Eg. Padum Nagar"
                                            min={moment.unix(hindranceData?.created_at).format("YYYY-MM-DDTHH:mm")}
                                            value={hindranceData?.closed_at ? moment.unix(hindranceData?.closed_at).format("YYYY-MM-DDTHH:mm") : null}
                                            onChange={(e) => setHindranceData((prev) => ({ ...prev, closed_at: moment(e.target.value).unix() }))}
                                        />
                                    </Col>
                                    <Col className="form-group col-sm col-lg-12">
                                        <label className="form-label mx-1">Closing Comments</label>
                                        <textarea
                                            className="form-control"
                                            value={hindranceData?.closing_comments || ""}
                                            onChange={(e) => setHindranceData((prev) => ({ ...prev, closing_comments: e.target.value }))}
                                        />
                                    </Col>
                                    <Col className="form-group col-sm col-lg-6">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckChecked"
                                            onChange={(e) => setHindranceData((prev) => ({ ...prev, closed: e.target.checked }))}
                                            checked={hindranceData?.closed ? true : false}
                                        />
                                        <label className="form-label mx-1">Close</label>
                                    </Col>
                                </>
                            )}
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setHindranceData({})}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            <b>Save</b>
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal >
        </>
    )
}
export default LiveTracker;



const VehicleMarker = ({ index, vehicle, nearbyVehicle, nearbyWaypoint, vehicleTypes, setVehicles, getVehicleList, roles, showHindranceMarker, setHindranceData }) => {
    const [showInfo, setShowInfo] = useState(false);
    const { loaded, type_name: type, offline } = vehicle
    const [latestTrip, setLatesTrip] = useState("")
    const [showForm, setShowForm] = useState(false)
    const [brakedownData, setBrakedownData] = useState({ brakedown_on: null, fixed_on: null, reason: null, vehicle_id: null })

    const markerIcon = () => {
        let icon = null;
        let icons = JSON.parse(vehicleTypes.find(item => item.id === vehicle?.type_id)?.icon)
        for (let item of vehicleTypes) {
            if (item.id === vehicle?.type_id) {
                if (loaded) {
                    icon = icons['loaded']
                    if (vehicle?.voltage < 3500) icon = icons["loaded_tampered"]
                    else if (vehicle?.offline) icon = icons[`loaded_offline`];
                    else if (vehicle?.suspicious) {
                        if (vehicle?.deviated) icon = icons["unloaded_suspicious_deviated"]
                        else icon = icons["unloaded_suspicious"]
                    }

                    else if (vehicle?.idle) {
                        if (vehicle.deviated) icon = icons[`loaded_idle_deviated`]
                        else icon = icons[`loaded_idle`]
                    }

                    else if (vehicle?.halted) {
                        if (vehicle?.deviated) icon = icons[`loaded_halted_deviated`]
                        else icon = icons[`loaded_halted`];
                    }
                    else if (vehicle?.deviated) icon = icons[`loaded_deviated`];
                    if (vehicle?.isbrakedown) icon = icons["loaded_brakedown"]
                }
                if (!loaded) {
                    icon = icons['unloaded']
                    if (vehicle?.voltage < 3500) icon = icons["unloaded_tampered"]
                    if (vehicle?.type_id === 10001 && !vehicle?.ignition) icon = icons["unloaded_ignition_off"]
                    else if (vehicle?.offline) icon = icons[`unloaded_offline`];
                    else if (vehicle?.suspicious) {
                        if (vehicle?.deviated) icon = icons["unloaded_suspicious_deviated"]
                        else icon = icons["unloaded_suspicious"]
                    }

                    else if (vehicle?.idle) {
                        if (vehicle.deviated) icon = icons[`unloaded_idle_deviated`]
                        else icon = icons[`unloaded_idle`]
                    }

                    else if (vehicle?.halted) {
                        if (vehicle?.deviated) icon = icons[`unloaded_halted_deviated`]
                        else icon = icons[`unloaded_halted`];
                    }
                    else if (vehicle?.deviated) icon = icons[`unloaded_deviated`];
                    if (vehicle?.isbrakedown) icon = icons["unloaded_brakedown"]
                }
            }

        }
        if (icon) return icon
        else return icons['unloaded'];
    }

    const getLatestTrip = async () => {
        let data = await API.get(apiName, `/gatepass?take=${2}&vehicle_id=${vehicle?.id}&sort=mine_entry_time DESC&deleted=false`)
        let exitAt = data.items[0]?.mine_exit_time || data.items[1]?.mine_exit_time
        exitAt && setLatesTrip(moment(exitAt * 1000).format("DD-MMM-YYYY, hh:mm A"))
    }

    const createBrakedownLog = async () => {
        if (vehicle?.isbrakedown) return updateBrakedownLog()
        try {
            if (!brakedownData.brakedown_on) return toast.error("Please enter breakdown date !")
            setShowForm(false)
            await API.post(apiName, '/brakedown', { body: brakedownData })
            setVehicles((prev) => ({ ...prev, [vehicle?.id]: { ...prev[vehicle?.id], isbrakedown: true } }))
            toast.success("Vehicle marked as breakdown.")
            window.location.reload()
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong!")
        }
    }

    const updateBrakedownLog = async () => {
        try {
            setShowForm(false)
            await API.put(apiName, `/brakedown/${brakedownData?.id}`, { body: brakedownData })
            setVehicles((prev) => ({ ...prev, [vehicle?.id]: { ...prev[vehicle?.id], isbrakedown: false } }))
            toast.success("Vehicle marked as fixed.")
            window.location.reload()
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong!")
        }
    }

    const filterDriver = async (inputValue) => {
        let res = await API.get(apiName, `/driver?keyword=${inputValue}&v_type=water_tanker`)
        let values = res.items.map((item) => {
            return { label: item.name + ` (${item.id})`, value: item.id };
        });
        return values;
    };

    const handleSearchDriver = (inputValue) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterDriver(inputValue));
            }, 1000);
        });

    const getBrakedownData = async (vehicleId) => {
        try {
            let res = await API.get(apiName, `/brakedown?vehicle_id=${vehicleId}&take=1`)
            return res.items
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Marker Marker zIndex={index}
                position={{ lat: vehicle?.location?.latitude || 24.636285, lng: vehicle?.location?.longitude || 87.81634 }}
                onClick={async () => {
                    console.log(vehicle)
                    setBrakedownData((prev) => ({ ...prev, vehicle_id: vehicle?.id }))
                    if (vehicle?.isbrakedown) {
                        let data = await getBrakedownData(vehicle?.id)
                        setBrakedownData((prev) => ({ ...prev, vehicle_id: vehicle?.id, ...data[0] }))
                    }
                    if (showHindranceMarker && vehicle?.segment !== "-") {
                        setHindranceData({ segment: vehicle?.segment, route_id: vehicle?.route_id, lat: vehicle?.lat, lng: vehicle?.lng, patrolling_team: nearbyVehicle(vehicle?.location) })
                    }
                    else {
                        setShowInfo(!showInfo)
                        getLatestTrip()
                    }
                }}
                icon={{
                    url: markerIcon(),
                    scaledSize: new window.google.maps.Size(40, 40),
                }}
            >

                {
                    showInfo &&
                    <InfoWindow onCloseClick={() => setShowInfo(false)}>
                        <div>
                            <div>{vehicle?.route_name === 'Dumka' ? <span className='badge bg-primary'>{vehicle?.route_name}</span> :
                                <span className='badge bg-warning'>{vehicle?.route_name}</span>}
                            </div>

                            <h3 className='mt-2 mb-1'>{vehicle?.id}</h3>
                            <div className='mt-2 mb-1'>{type}</div>
                            <div className='mt-2 mb-1'>{vehicle?.transporter_name} <b>{vehicle?.transporter_phone}</b></div>
                            {vehicle?.driver_name && (
                                <div className='mt-2 mb-1'>Driver - {vehicle?.driver_name} (<b>{vehicle?.driver_contact}</b>)</div>
                            )}
                            <hr className='m-1' />
                            <div className='d-flex align-items-center gap-3 mt-2'>
                                <div className='fs-4 info-tag'>
                                    <><div>Trip:</div>
                                        <div>{vehicle?.loaded ? "Loaded" : "Empty"}</div></>

                                    <><div>Deviation:</div>
                                        <div>{vehicle.deviated ? 'Deviated' : 'In Route'}</div></>

                                    <><div>Section:</div>
                                        <div>{vehicle?.segment || "_"}</div></>

                                    <><div>GSM Signal:</div>
                                        <div>{vehicle?.gsmSignal || "_"}</div></>

                                    <><div>Speed:</div>
                                        <div>{vehicle.speed || '0'} kmph</div></>
                                    <><div>Voltage:</div>
                                        <div>{vehicle?.voltage && vehicle?.voltage / 1000 || "-"} volts</div></>

                                    <><div>Ignition:</div>
                                        <div>{vehicle.ignition ? "ON" : "OFF"}</div></>

                                    {vehicle?.deviated && vehicle?.deviated_since > 0 && <><div>Deviated Since:</div>
                                        <div>{moment(vehicle?.deviated_since * 1000).format("DD-MMM-YYYY, hh:mm A")}</div></>}

                                    {vehicle?.idle && vehicle?.idle_since > 0 && <><div>Idle Since:</div>
                                        <div>{moment(vehicle?.idle_since * 1000).format("DD-MMM-YYYY, hh:mm A")}</div></>}

                                    {vehicle?.halted && vehicle?.halted_since > 0 && <><div>Halted Since:</div><div>{moment(vehicle?.halted_since * 1000).format("DD-MMM-YYYY, hh:mm A")}</div></>}

                                    {vehicle?.offline_since > 0 && <><div>Offline Since:</div><div>{moment(vehicle?.offline_since * 1000).format("DD-MMM-YYYY, hh:mm A")}</div></>}
                                    <><div>Nearby Security :</div><div>{nearbyVehicle(vehicle?.location) || "_"}</div></>
                                    <><div>Nearby Waypoint :</div><div>{nearbyWaypoint(vehicle?.location) || "_"}</div></>
                                    {latestTrip && <><div>Last Trip Created:</div><div>{latestTrip}</div></>}
                                    {brakedownData?.brakedown_on && <><div >Breakdown Since:</div><div>{moment(brakedownData?.brakedown_on * 1000).format("DD-MMM-YYYY, hh:mm A")}</div></>}
                                    {brakedownData?.reason && <><div>Reason: </div> <div>{brakedownData?.reason}</div></>}
                                </div>
                            </div>
                            <div className='mt-3 small text-muted'>Last pinged - {vehicle?.gpsUpdatedAt ? moment(vehicle?.gpsUpdatedAt * 1000).fromNow() : 'Offline'}</div>
                            {roles?.includes("BREAKDOWN_EDIT") && (
                                <>
                                    {vehicle?.isbrakedown ? <button className='btn btn-sm btn-primary  badge my-2' onClick={() => setShowForm(!showForm)}>Mark as Fixed</button> : <button className='btn btn-sm btn-danger badge my-2' onClick={() => setShowForm(!showForm)}>Mark as Breakdown</button>}
                                </>
                            )}

                            {vehicle?.type_id === 10001 && (
                                <div className='my-2'>
                                    <AsyncSelect isClearable theme={selectColor} required placeholder="Assign Driver" defaultValue={vehicle?.driver_id ? { label: vehicle?.driver_name, value: vehicle?.driver_id } : null} loadOptions={handleSearchDriver} defaultOptions onChange={async (e) => {
                                        try {
                                            await API.put(apiName, `/vehicle/${vehicle?.id}`, { body: { ...vehicle, driver_id: e?.value } })
                                            toast.success('Driver is assigned to the water tanker.')
                                            getVehicleList(null, roles)
                                        } catch (error) {
                                            toast.error("Something went wrong!")
                                        }

                                    }} />
                                </div>
                            )}

                            <p className='text-muted m-0'><i className='fe fe-refresh-cw'></i> to see latest update on breakdown </p>
                            {showForm && (
                                <div className='d-flex align-items-end'>
                                    <div className="form-group m-1">

                                        {!vehicle?.isbrakedown ?
                                            <>
                                                <label className="form-label">{vehicle?.isbrakedown ? 'Fixed Time' : 'Breakdown Time'}</label>
                                                <input
                                                    type="datetime-local"
                                                    className="form-control form-control-sm"
                                                    max={moment().format("YYYY-MM-DDTHH:mm")}
                                                    value={brakedownData?.brakedown_on ? moment.unix(brakedownData?.brakedown_on).format("YYYY-MM-DDTHH:mm") :
                                                        moment().format("YYYY-MM-DDTHH:mm")
                                                    }
                                                    onChange={(e) => setBrakedownData((prev) => ({ ...prev, brakedown_on: moment(e.target.value).unix() }))}
                                                />
                                                <div className="form-group m-1">
                                                    <label className="form-label">Reason</label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder='Enter a reason for breakdown'
                                                        value={brakedownData?.reason || ""}
                                                        onChange={(e) => setBrakedownData((prev) => ({ ...prev, reason: e.target.value }))}
                                                    />
                                                </div>
                                            </> : (
                                                <div className="form-group m-1">
                                                    <label className="form-label">Closing Comments</label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder='Type closing_comments for breakdown'
                                                        value={brakedownData?.closing_comment || ""}
                                                        onChange={(e) => setBrakedownData((prev) => ({ ...prev, closing_comment: e.target.value }))}
                                                    />
                                                </div>
                                            )}
                                    </div>

                                    <button className='btn btn-sm btn-primary mb-2' onClick={createBrakedownLog}>Save</button>
                                </div>
                            )}

                        </div>
                    </InfoWindow>
                }
            </Marker>
        </div>
    )
}

const VehicleHistoryMarker = ({ index, location, vehicle, vehicleTypes }) => {
    const [showInfo, setShowInfo] = useState(false);
    const { loaded, deviated } = location

    const markerIcon = () => {
        let icon = null;
        let icons = JSON.parse(vehicleTypes.find(item => item.id === vehicle?.type)?.icon)
        for (let item of vehicleTypes) {
            if (item.id === vehicle?.type) {
                if (loaded) {
                    icon = icons['loaded']
                    if (vehicle?.voltage < 3500) icon = icons["loaded_tampered"]
                    else if (vehicle?.offline) icon = icons[`loaded_offline`];
                    else if (vehicle?.suspicious) {
                        if (vehicle?.deviated) icon = icons["unloaded_suspicious_deviated"]
                        else icon = icons["unloaded_suspicious"]
                    }

                    else if (vehicle?.idle) {
                        if (vehicle.deviated) icon = icons[`loaded_idle_deviated`]
                        else icon = icons[`loaded_idle`]
                    }

                    else if (vehicle?.halted) {
                        if (vehicle?.deviated) icon = icons[`loaded_halted_deviated`]
                        else icon = icons[`loaded_halted`];
                    }
                    else if (vehicle?.deviated) icon = icons[`loaded_deviated`];
                }
                if (!loaded) {
                    icon = icons['unloaded']
                    if (vehicle?.voltage < 3500) icon = icons["unloaded_tampered"]
                    else if (vehicle?.offline) icon = icons[`unloaded_offline`];
                    else if (vehicle?.suspicious) {
                        if (vehicle?.deviated) icon = icons["unloaded_suspicious_deviated"]
                        else icon = icons["unloaded_suspicious"]
                    }

                    else if (vehicle?.idle) {
                        if (vehicle.deviated) icon = icons[`unloaded_idle_deviated`]
                        else icon = icons[`unloaded_idle`]
                    }

                    else if (vehicle?.halted) {
                        if (vehicle?.deviated) icon = icons[`unloaded_halted_deviated`]
                        else icon = icons[`unloaded_halted`];
                    }
                    else if (vehicle?.deviated) icon = icons[`unloaded_deviated`];
                }
            }

        }
        if (icon) return icon
        else return icons['unloaded'];
    }

    return (
        <div>
            <Marker Marker zIndex={index}
                position={{ lat: location?.location?.latitude, lng: location?.location?.longitude }}
                onClick={() => setShowInfo(!showInfo)}
                icon={{
                    url: markerIcon(),
                    scaledSize: new window.google.maps.Size(40, 40),
                }}
            >

                {
                    showInfo &&
                    <InfoWindow onCloseClick={() => setShowInfo(false)}>
                        <div>
                            <div>{vehicle?.route?.name === 'Dumka' ? <span className='badge bg-primary'>{vehicle?.route?.name}</span> :
                                <span className='badge bg-warning'>{vehicle?.route?.name}</span>}
                            </div>

                            <h3 className='mt-2 mb-1'>{vehicle?.id}</h3>
                            <div className='d-flex align-items-center gap-3 mt-4'>
                                <div className='fs-4 d-flex flex-column  gap-2'>
                                    <div>Trip:</div>
                                    <div>Deviation:</div>
                                    <div>GSM Signal:</div>
                                    <div>Speed:</div>
                                </div>
                                <div className='fs-4 d-flex flex-column  gap-2'>
                                    <div>{location?.loaded ? "Loaded" : "Empty"}</div>
                                    <div>{location.deviated ? 'Deviated' : 'In Route'}</div>
                                    <div>{location.gsmSignal || "_"}</div>
                                    <div>{location.speed || '0'} kmph</div>
                                </div>
                            </div>
                            <div className='mt-3 small text-muted'>{location?.gpsUpdatedAt ? moment(location?.gpsUpdatedAt * 1000).fromNow() : 'Offline'}</div>
                        </div>
                    </InfoWindow>
                }
            </Marker>
        </div>
    )
}

const WayPointMarker = ({ lat, lng, name }) => {
    const [showInfo, setShowInfo] = useState(false)
    return (
        <div>
            <Marker
                position={{ lat: lat, lng: lng }}
                onClick={() => setShowInfo(!showInfo)}
                icon={{
                    url: "/img/icons/pin.png",
                    scaledSize: new window.google.maps.Size(22, 22)
                }}
            >
                {showInfo &&
                    <InfoWindow onCloseClick={() => setShowInfo(false)}>
                        <div>
                            <div className='mt-1 fw-bold'>Name: {name}</div>
                            <div className='mt-1'>Latitude: {lat || '_'}</div>
                            <div className='mt-1'>Longitude: {lng || "_"}</div>
                        </div>
                    </InfoWindow>
                }
            </Marker>
        </div>
    )
}

const SpeedMarker = ({ item }) => {
    const [show, setShow] = useState(false)
    return (
        <Marker
            onClick={() => setShow(!show)}
            position={{ lat: item.location.latitude, lng: item.location.longitude }}
            icon={{
                url: `/img/icons/speedometer.png`,
                scaledSize: new window.google.maps.Size(20, 20),
            }}
        >
            {show && (
                <InfoWindow
                    onCloseClick={() => setShow(false)}
                    position={{ lat: item.location.latitude, lng: item.location.longitude }}
                    key={`infowindow-${item.speed}`}
                    visible={true}>
                    <div>Speed : {item.speed} km/h</div>
                </InfoWindow>
            )}
        </Marker>
    )
}

const UnscheduleMarker = ({ item, nextItem }) => {
    const [show, setShow] = useState(false)
    return (
        <Marker
            onClick={() => setShow(!show)}
            position={{ lat: item.location.latitude, lng: item.location.longitude }}
            icon={{
                url: `/img/warning.png`,
                scaledSize: new window.google.maps.Size(20, 20),
            }}
        >
            {show && (
                <InfoWindow
                    onCloseClick={() => setShow(false)}
                    position={{ lat: item.location.latitude, lng: item.location.longitude }}
                    key={`infowindow-${item.speed}`}
                    visible={true}>
                    <>
                        <h4>Unscheduled Stop</h4>
                        <div>Start Time : {moment.unix(item?.gpsUpdatedAt).format("DD-MM-YYYY hh:mm A")}</div>
                        {nextItem && (
                            <>
                                <div>End Time : {moment.unix(nextItem).format("DD-MM-YYYY hh:mm A")}</div>
                                <div>Duration : {moment.unix(nextItem).diff(moment.unix(item?.gpsUpdatedAt), 'minutes')} m </div>
                            </>
                        )}
                    </>
                </InfoWindow>
            )}
        </Marker>
    )
}

const HindranceMarker = ({ setHindranceData, marker, roles }) => {
    const [show, setShow] = useState(false)
    return (
        <Marker position={{ lat: marker.lat, lng: marker.lng }}
            icon={{
                url: '/img/hindrance.png',
                scaledSize: new window.google.maps.Size(40, 40),
            }}
            onClick={() => {
                if (roles.includes("HINDRANCE_EDIT")) setHindranceData(marker)
                else setShow(true)
            }}>
            {show && (
                <InfoWindow
                    onCloseClick={() => setShow(false)}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    key={`infowindow-${marker.lat}`}
                    visible={true}>
                    <>
                        <span className='badge bg-primary mb-2'>{marker?.route_name}</span>
                        <div>{moment.unix(marker.opened_at).format("DD-MM-YYYY hh:mm A")}</div>
                        <h5>{marker?.vehicle_loaded} loaded and {marker?.vehicle_unloaded} Unloaded Vehicles</h5>
                        <div> Patrolling Team : <b>{marker?.patrolling_team}</b></div>
                        <div> Place : <b>{marker?.place}</b></div>
                        <div> Thana : <b>{marker?.thana}</b></div>
                        <div> Segment : <b>{marker?.segment}</b></div>
                        <div> Shift : <b>{marker?.shift}/ {marker?.shift_person}</b></div>
                        <div> Reason : <b>{marker?.reason}</b></div>
                        <div> Feedback : <b>{marker?.feedback}</b></div>
                    </>
                </InfoWindow>
            )}
        </Marker>
    )
}

