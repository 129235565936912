import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from "moment";
import { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import toast from "react-hot-toast";
import AsyncSelect from "react-select/async";
import { apiName, handleSearchRoute, handleSearchTransporter, handleSearchVehicles, selectColor } from "../../components/Utils";


import { API } from "aws-amplify";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import NoAccess from "../../components/NoAccess";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const VehiclePerformanceDash = () => {
  const primary = "#ccaf59"  //yellow
  const [date, setDate] = useState({ start: moment().startOf('day').unix(), end: moment().endOf('day').unix() })

  const [vehicle, setVehicle] = useState(null)
  const [transporter, setTransporter] = useState(null)
  const [route, setRoute] = useState(null)
  const [barData, setBarData] = useState([])

  const barThickness = barData.length > 10 ? 10 : 30


  const datax = {
    labels: barData.map(item => item.vehicle_no),
    datasets: [
      {
        label: 'Shortage',
        data: barData.map((item) => item?.total_short_weight),
        backgroundColor: primary,
        stack: 'Stack 0',
        barThickness
      }
    ],
  };

  const options = {
     events: [] ,
    plugins: [ChartDataLabels],
    indexAxis: 'y',
    plugins: {
      datalabels: {
        color: "green",
        anchor: 'end',
        align: 'end',
        offset : -12,
        font: {
          weight: 'bold',
        }
      },
      scales: {
        x: { grace: "5%", },
        y: { grace: "5%", },
      },
      title: { display: false, },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    }
  };



  const handleDateApplied = (event, picker) => {
    const fromDate = moment(picker.startDate).startOf("day").unix()
    const toDate = Math.floor(moment(picker.endDate).endOf("day").unix());
    setDate({ start: fromDate, end: toDate });
  };

  async function getBarData() {
    try {
      const start = date?.start
      let query = `/report/vehicle-performance-report?start=${start}&end=${date?.end}`
      if (transporter) query = `${query}&transporter_id=${transporter}`;
      if (vehicle) query = `${query}&vehicle_id=${vehicle}`;
      if (route) query = `${query}&route_id=${route}`;

      let  data  = await API.get(apiName,query)
      data = data.sort((a, b) => +b.total_short_weight - +a.total_short_weight)
      setBarData(data)
    } catch (error) {
      toast.error("Something went wrong !")
    }
  }

  useEffect(() => {
    getBarData()
  }, [date, route, transporter, vehicle])

  const roles = useSelector((state)=>state.userInfo.payload?.role)
  if(!roles?.includes("VEHICLE_PERFORMANCE")){
      return <NoAccess/>
  }

  return (
    <section>
      <div>
        <div className="row mb-3">
          <h2 className="col m-0">Vehicle Performance Dashboard</h2>
          <div className="col-auto">
            <DateRangePicker
              initialSettings={{
                startDate: moment.unix(date?.start).format("MM-DD-YYYY"),
                endDate: moment.unix(date?.end).subtract(5,'hours').subtract(30,'minutes').format("MM-DD-YYYY"),
                linkedCalendars: true,
                showCustomRangeLabel: true,
                showDropdowns: true,
                alwaysShowCalendars: true,
                ranges: {
                  'Today': [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                  'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                }
              }}
              onApply={handleDateApplied}
            >
              <input
                className={`btn btn-light ml-2 pointer`}
              />
            </DateRangePicker>
          </div>
          <div style={{ width: '200px' }}>
            <AsyncSelect isClearable theme={selectColor} required placeholder="Select Route" cacheOptions loadOptions={handleSearchRoute} defaultOptions onChange={(e) => setRoute(e?.value)} />
          </div>

          <div style={{ width: '230px' }}>
            <AsyncSelect isClearable theme={selectColor} required placeholder="Select Transporter" cacheOptions loadOptions={handleSearchTransporter} defaultOptions onChange={(e) => setTransporter(e?.value)} />
          </div>

          <div style={{ width: '200px' }}>
            <AsyncSelect isClearable theme={selectColor} required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => setVehicle(e?.value)} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-xl-8">
            <div className="card w-100 h-100 table-responsive">
              <div className="card-body ">
                <h3>Shortage per Vehicle</h3>
                <Bar options={options} data={datax} height={4000} />
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-4">
            <div className="w-100">
              <div className="card-body">
                <h3>Max Shortage</h3>
                <div className="table-responsive card">
                  <table className="table-hover table-bordered">
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                      <tr>
                        <th className="fs-5">Vehicle Number</th>
                        <th className="fs-5 text-center">Trip Count</th>
                        <th className="fs-5 text-center">Shortage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {barData.slice(0,5)?.map((item, idx) => (
                        <tr key={idx}>
                          <td align='left' className='fs-5'>{item?.vehicle_no}</td>
                          <td align='center' className='fs-5'>{item?.trip_count}</td>
                          <td align='center' className='fs-5'>{item?.total_short_weight}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <h3>Min Shortage</h3>
                <div className="table-responsive card">
                  <table className="table-hover table-bordered">
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                      <tr>
                        <th className="fs-5">Vehicle Number</th>
                        <th className="fs-5 text-center">Trip Count</th>
                        <th className="fs-5 text-center">Shortage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {barData.reverse().slice(0,5)?.map((item, idx) => (
                        <tr key={idx}>
                          <td align='left' className='fs-5'>{item?.vehicle_no}</td>
                          <td align='center' className='fs-5'>{item?.trip_count}</td>
                          <td align='center' className='fs-5'>{item?.total_short_weight}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default VehiclePerformanceDash

