
import { API, Storage } from "aws-amplify";
import moment from "moment";
import Swal from "sweetalert2";

export const limit = 100;
export const delay = 400;
export const apiName = 'api';
export const interval = 30

export function handlePaginationChange(newPageIndex, old, setCount) {
  setCount(newPageIndex - 1)
}

export async function fileUpload(file) {
  Swal.fire({
    title: "Uploading...",
    allowOutsideClick: false,
    showConfirmButton: false,
  });
  try {
    let res = await Storage.put(
      `activity-${Math.random().toString(36).substring(2, 15)}.${file.name.split(".")[1]
      }`,
      file,
      { contentType: file.type }
    );
    let link = await Storage.get(res?.key, { level: "public" });
    Swal.close();
    return link.substring(0, link.indexOf("?"));
  } catch (error) {
    Swal.close();
    Swal.fire("Oops!", error.message, "error");
  }
}

export function currentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export function convertUnixToDate(unixTimestamp) {
  const date = new Date(
    unixTimestamp * 1000 || Math.floor(new Date().getTime())
  );

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function getNextDate(currentDate) {
  const [year, month, day] = currentDate.split("-");
  const date = new Date(year, month - 1, day);
  const nextDayTimestamp = date.getTime() + 24 * 60 * 60 * 1000;
  const nextDate = new Date(nextDayTimestamp);
  const nextYear = nextDate.getFullYear();
  const nextMonth = String(nextDate.getMonth() + 1).padStart(2, "0");
  const nextDay = String(nextDate.getDate()).padStart(2, "0");
  const formattedNextDate = `${nextYear}-${nextMonth}-${nextDay}`;

  return formattedNextDate;
}

export const selectColor = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    text: "orangered",
    primary25: "#f0f0f0",
    primary: "#407050",
  },
})
export const timeDifference = (start, end) => {
  const moment1 = moment.unix(start);
  const moment2 = moment.unix(end);

  const differenceInMilliseconds = moment2.diff(moment1);
  const duration = moment.duration(differenceInMilliseconds);

  const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
  const minutes = String(duration.minutes()).padStart(2, '0');
  const seconds = String(duration.seconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}


export function awsTime(timestamp1, timestamp2) {
  const durationInSeconds = Math.abs(timestamp1 - timestamp2);
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const awsTimeFormat = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.000`;

  return awsTimeFormat;
}

export function getDuration(start, end) {
  if (start && end) {
    const startTime = moment(start * 1000);
    const endTime = moment(end * 1000);

    const duration = endTime.diff(startTime, 'minutes');
    const hour = Math.floor(duration / 60)
    const min = duration % 60
    return `${hour}h ${min}m`;
  } else return '-'
}

export function formatDateTime(timestamp) {
  return timestamp ? <div>
    <div>{moment(timestamp * 1000).format("h:mm a")}</div>
    <div className="small text-muted">{moment(timestamp * 1000).format("DD-MMM-YYYY")}</div>
  </div> : <>-</>
}

export function getTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = (minutes % 60).toFixed(0)

  return `${hours} h ${mins} m`
}

export function getPlayerDuration(start, end) {
  if (start && end) {
    const startTime = moment(start * 1000);
    const endTime = moment(end * 1000);

    const duration = moment.duration(endTime.diff(startTime));
    const days = duration.days();
    let hours = duration.hours().toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const seconds = duration.seconds().toString().padStart(2, '0');

    if (days > 0) hours = +hours + (24 * +days)

    return `${hours}:${minutes}:${seconds}`;
  } else {
    return '-';
  }
}


const filterVehicles = async (inputValue) => {
  let res = await API.get(apiName, `/vehicle?keyword=${inputValue}&deleted=${false}`);
  let values = res.items.map((item) => {
    return { label: item.id, value: item.id };
  });
  return values;
};

export const handleSearchVehicles = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterVehicles(inputValue));
    }, 1000);
  });

const filterTransporter = async (inputValue) => {
  let res = await API.get(apiName, `/transporter?keyword=${inputValue}`)
  let values = res.items.map((item) => {
    return { label: item.name, value: item.id };
  });
  return values;
};

export const handleSearchTransporter = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterTransporter(inputValue));
    }, 1000);
  });

const filterRoute = async (inputValue) => {
  let res = await API.get(apiName, `/route?keyword=${inputValue}`)
  let values = res.items.map((item) => {
    return { label: item.name, value: item.id };
  });
  return values;
};

export const handleSearchRoute = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterRoute(inputValue));
    }, 1000);
  });


const filterDriver = async (inputValue) => {
  let res = await API.get(apiName, `/driver?keyword=${inputValue}`)
  let values = res.items.map((item) => {
    return { label: item.name + ` (${item.id})`, value: item.id };
  });
  return values;
};

export const handleSearchDriver = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterDriver(inputValue));
    }, 1000);
  });
const filterVehicleType = async (inputValue) => {
  let res = await API.get(apiName, `/vehicle-type?keyword=${inputValue}`)
  let values = res.items.map((item) => {
    return { label: item.name, value: item.id };
  });
  return values;
};

export const handleSearchVehicleType = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterVehicleType(inputValue));
    }, 1000);
  });

export const shifts = ['1st', '2nd', '3rd']

export const dashboardRoles = ["EXECUTIVE", "DAILY_DISPATCH", "TRAFFIC", "TRANSPORTER_PERFORMANCE", "VEHICLE_PERFORMANCE"]
export const gatepassRoles = ["GATEPASS_LIST", "GATEPASS_OFFLINE", "IN_BARRIER", "WEIGH_BRIDGE", "OUT_BARRIER", "RAILWAY_SIDING_ENTRY", "RAILWAY_SIDING_EXIT"]
export const reportRoles = ["VEHICLE-MASTER", "VEHICLE-ATTENDANCE", "VEHICLE-PERFORMANCE", "DISPATCH", "DAILY-PERFORMANCE", "TRANSPORTER-PERFORMANCE", "TRANSPORTER-MASTER", "IDLE", "HALTED", "DEVIATION", "SPEEDING", "OFFLINE", "SUSPICIOUS", "IGNITION", "PING", "SECURITY_VEHICLE", "DISTANCE"]

export const otherRoles = ["TRACKER", "TRANSPORTER", "VEHICLE", "DRIVERS", "USERS", "PATROLLING_VEHICLE", "SETTINGS", "PARAMETERS", "CONTROL_TOWER", "HINDRANCE_VIEW", "BREAKDOWN_VIEW", "BREAKDOWN_EDIT", "HINDRANCE_EDIT"]

export const approvalRoles = [{ label: 'DIRECTOR', value: 'APPROVAL_DIRECTOR' }, { label: 'VICE_PRESIDENT', value: 'APPROVAL_VICE_PRESIDENT' }, { label: 'PROJECT_MANAGER', value: 'APPROVAL_PROJECT_MANAGER' }, { label: 'CONTROL_ROOM', value: 'APPROVAL_CONTROL_ROOM' }, { label: 'DISPATCH_MANAGER', value: 'APPROVAL_DISPATCH_MANAGER' }]

export const lanes = ["Lane_1_In", "Lane_2_In", "Lane_3_In", "Lane_4_In", "Lane_5_Out", "Lane_6_Out", "Lane_7_Out", "Lane_8_Out"]

export const customRoles = [
  "SUPER_ADMIN",
  "ADMIN",
  "MANAGER",
  "INCHARGE",
  "VIEWER",
  "PATROLLING",
  "TRANSPORTER",
  "DEVELOPER"
]
export const superAdminRoles = [
  "EXECUTIVE", "DAILY_DISPATCH", "TRAFFIC", "TRANSPORTER_PERFORMANCE", "VEHICLE_PERFORMANCE", "GATEPASS_LIST", "GATEPASS_OFFLINE", "IN_BARRIER", "WEIGH_BRIDGE", "OUT_BARRIER",
  "RAILWAY_SIDING_ENTRY", "RAILWAY_SIDING_EXIT", "VEHICLE-MASTER", "VEHICLE-ATTENDANCE", "VEHICLE-PERFORMANCE", "DISPATCH", "DAILY-PERFORMANCE", "TRANSPORTER-PERFORMANCE", "TRANSPORTER-MASTER",
  "IDLE", "HALTED", "DEVIATION", "SPEEDING", "OFFLINE", "SUSPICIOUS", "IGNITION", "PING", "SECURITY_VEHICLE", "DISTANCE", "TRACKER", "VEHICLE", "DRIVERS", "USERS", "PATROLLING_VEHICLE",
  "SETTINGS", "PARAMETERS", "CONTROL_TOWER", "HINDRANCE_VIEW", "BREAKDOWN_VIEW", "BREAKDOWN_EDIT", "HINDRANCE_EDIT", "TRANSPORTER"
]
