import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import toast from 'react-hot-toast';
import AsyncSelect from 'react-select/async';
import * as XLSX from 'xlsx';
import { apiName, handleSearchVehicles, selectColor } from './Utils';

export default function GpsReport({ title, api, formatData, showFilter = true }) {
    const [date, setDate] = useState({ start: moment().startOf('day').unix(), end: moment().endOf('day').unix() })
    const [vehicle, setVehicle] = useState(null)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])



    const handleDateApplied = (event, picker) => {
        const fromDate = moment(picker.startDate).startOf("day").unix()
        const toDate = Math.floor(moment(picker.endDate).endOf("day").unix());
        setDate({ start: fromDate, end: toDate });
    };

    const handleView = async () => {
        if (vehicle || api === 'suspicious' || api === 'security-vehicle' || api === "offline_since") {
            const load = toast.loading("Loading...")
            setLoading(true)
            try {
                let query = `/report/${api}?start=${date?.start}&end=${date?.end}&vehicle_id=${vehicle}`
                if (api === 'suspicious') {
                    query = `/report/${api}?start=${date?.start}&end=${date?.end}`
                    if (vehicle) query = `${query}&vehicle_id=${vehicle}`
                }
                let data = await API.get(apiName, query);
                setData(data)
                return formatData(data)

            } catch (error) {
                console.log(error)
            } finally {
                {
                    toast.dismiss(load)
                    setLoading(false)
                }
            }
        }
    }

    const handleDownload = () => {
        try {
            let temp = formatData(data)
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(temp);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, `${title}_${vehicle || ""}_${moment.unix(date?.start).format('DD-MMM-YYYY')} - ${moment.unix(date?.end).subtract(5, "hours").subtract(30, "minutes").format('DD-MMM-YYYY')}.xlsx`);
            toast.success("Download Successful.")
        } catch (error) {
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        handleView()
    }, [vehicle, date])


    return (
        <div>
            <h2 className='text-center'>{title}</h2>
            <div className='card p-2'>
                <div className='d-flex align-items-center justify-content-end gap-3'>
                    {showFilter && (
                        <>
                            <DateRangePicker
                                initialSettings={{
                                    startDate: moment.unix(date?.start).format("MM-DD-YYYY"),
                                    endDate: moment.unix(date?.end).subtract(5, 'hours').subtract(30, 'minutes').format("MM-DD-YYYY"),
                                    linkedCalendars: true,
                                    showCustomRangeLabel: true,
                                    showDropdowns: true,
                                    alwaysShowCalendars: true,
                                    ranges: {
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                    }
                                }}
                                onApply={handleDateApplied}
                            >
                                <input
                                    className={`btn btn-light ml-2 pointer`}
                                />
                            </DateRangePicker>
                            <div style={{ width: '200px' }}>
                                <AsyncSelect isClearable theme={selectColor} required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => setVehicle(e?.value)} />
                            </div>
                        </>
                    )}
                    <button disabled={loading} onClick={handleDownload} type='button' style={{ width: 'fit-content' }} className='btn btn-primary'><i className='fe fe-download'></i></button>
                </div>
            </div>
            {data?.length === 0 &&
                <span>*Please select vehicle to see the data</span>}
        </div>
    )
}
