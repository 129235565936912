
import React from "react";
import { useNavigate } from "react-router";
import AuthLayout from "../../layouts/AuthLayout";
import { Auth } from "aws-amplify";


export default function Pending() {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <h1>Waiting for Admin Approval</h1>
      <button
        className="btn mx-2 btn-primary fw-bold"
        onClick={() => {
          Auth.signOut().then(() => navigate("/"));
        }}
      >
        Logout
      </button>
    </AuthLayout>
  );
}
