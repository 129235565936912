import { API } from "aws-amplify";
import moment from "moment";
import { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import NoAccess from "../../components/NoAccess";
import { apiName, handleSearchRoute, handleSearchTransporter, handleSearchVehicles, selectColor } from "../../components/Utils";
import BarChart from "../../components/charts/BarChart";
import DoughnutChart from "../../components/charts/DoughnutChart";
import { Bar } from "react-chartjs-2";



const ExecutiveDashboard = () => {
    const primary = "#ccaf59"  //yellow
    const secondary = "#407050" // green
    const allsettings = useSelector(state => state?.setting?.payload)
    const target = allsettings?.find(item => item?.name === "target").value || 0

    const backgroundColors = [secondary, primary];
    const [date, setDate] = useState({ start: moment().startOf('day').unix(), end: moment().endOf('day').unix() })
    const days = moment.unix(date?.end).diff(moment.unix(date?.start), 'days')

    const [vehicle, setVehicle] = useState(null)
    const [transporter, setTransporter] = useState(null)
    const [route, setRoute] = useState(null)
    const [data, setData] = useState([])
    const [barDataPakur, setBarDataPakur] = useState([])
    const [barDataDumka, setBarDataDumka] = useState([])
    const [pieData, setPieData] = useState({})
    const barThickness = barDataPakur.length > 10 ? 20 : 30

    const bar_chart_labels = ['Delivered Tons']
    const pie_chart_data = {
        labels: ['Pakur', 'Dumka'],
        datasets: [{ data: [pieData?.pakur?.tons_rw_delivered, pieData?.dumka?.tons_rw_delivered] }],
    };

    const bar_chart_data = (barDataPakur.length > 0 ? barDataPakur : barDataDumka).map(item => {
        return { name: moment(item?.exit_date).format("DD-MMM"), deliver_tons: item?.total_sum, color_1: secondary }
    })

    const options = {
        plugins: {
            datalabels: {
                color: "black",
                display: true,
                anchor: 'end',
                align: 'top',
                offset: -5,
                rotation: -90,
                font: {
                    weight: 'bold',
                }

            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },

    };

    const dataX = {
        labels: bar_chart_data.map(item => item?.name),
        datasets: [
            {
                label: 'Dumka',
                data: barDataDumka.map((item) => item.total_sum),
                backgroundColor: primary,
                barThickness,
                stack: 'Stack 0',
                borderWidth: 3,
                borderColor: "white",
                borderRadius: 5,

            },
            {
                label: 'Pakur',
                data: barDataPakur.map((item) => item.total_sum),
                backgroundColor: secondary,
                barThickness,
                borderWidth: 3,
                stack: 'Stack 1',
                borderColor: "white",
                borderRadius: 5,
            }
        ],
    };

    const handleDateApplied = (event, picker) => {
        const fromDate = moment(picker.startDate).startOf("day").unix()
        const toDate = Math.floor(moment(picker.endDate).endOf("day").unix());
        setDate({ start: fromDate, end: toDate });
    };

    function getSum(value) {
        if (data.length > 0) {
            let total = data.reduce((acc, item) => +acc + +item[value], 0)
            if (+total) return total
        }
        return 0
    }

    async function getData() {
        try {
            let query = `/report/trips-and-tonnage-delivered?start=${date?.start}&end=${date?.end}`
            if (transporter) query = `${query}&transporter_id=${transporter}`;
            if (vehicle) query = `${query}&vehicle_id=${vehicle}`;
            if (route) query = `${query}&route_id=${route}`;

            let data = await API.get(apiName, query)
            setData(data)
        } catch (error) {
            toast.error("Something went wrong !")
        }

    }
    async function getBarData() {
        try {
            const start = date?.start === moment().startOf('day').unix() ? moment().subtract(6, 'days').startOf('day').unix() : date?.start
            let query = `/dashboard/executive-target?start=${start}&end=${date?.end}&route_id=${1}`
            let query2 = `/dashboard/executive-target?start=${start}&end=${date?.end}&route_id=${2}`
            if (transporter) {
                query = `${query}&transporter_id=${transporter}`;
                query2 = `${query2}&transporter_id=${transporter}`;
            }
            if (vehicle) {
                query = `${query}&vehicle_id=${vehicle}`;
                query2 = `${query2}&vehicle_id=${vehicle}`;
            }
            // if (route) query = `${query}&route_id=${route}`;

            let data = await API.get(apiName, query)
            let data2 = await API.get(apiName, query2)
            setBarDataPakur(data)
            setBarDataDumka(data2)
            if (route === 1) setBarDataDumka([])
            if (route === 2) setBarDataPakur([])
        } catch (error) {
            toast.error("Something went wrong !")
        }
    }

    async function getPieData() {
        try {
            let query = `/report/trips-and-tonnage-delivered?start=${date?.start}&end=${date?.end}&route_id=1`
            let query1 = `/report/trips-and-tonnage-delivered?start=${date?.start}&end=${date?.end}&route_id=2`

            if (transporter) {
                query = `${query}&transporter_id=${transporter}`
                query1 = `${query1}&transporter_id=${transporter}`
            }
            if (vehicle) {
                query = `${query}&vehicle_id=${vehicle}`
                query1 = `${query1}&vehicle_id=${vehicle}`
            }

            let res = await API.get(apiName, query)
            let res1 = await API.get(apiName, query1)

            if (route === 1) res1[0] = []
            if (route === 2) res[0] = []

            setPieData({ pakur: res[0], dumka: res1[0] })
        } catch (error) {
            toast.error("Something went wrong !")
        }
    }

    useEffect(() => {
        getData()
        getBarData()
        getPieData()
    }, [date, route, transporter, vehicle])

    const roles = useSelector((state) => state.userInfo.payload?.role)

    if (!roles?.includes("EXECUTIVE")) {
        return <NoAccess />
    }

    return (
        <section>
            <div>
                <div className="row mb-3">
                    <h2 className="col m-0">Executive Dashboard</h2>
                    <div className="col-auto">
                        <DateRangePicker
                            initialSettings={{
                                startDate: moment.unix(date?.start).format("MM-DD-YYYY"),
                                endDate: moment.unix(date?.end).subtract(5, "hours").subtract(30, "minutes").format("MM-DD-YYYY"),
                                linkedCalendars: true,
                                showCustomRangeLabel: true,
                                showDropdowns: true,
                                alwaysShowCalendars: true,
                                ranges: {
                                    'Today': [moment(), moment()],
                                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                    'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                }
                            }}
                            onApply={handleDateApplied}
                        >
                            <input
                                className={`btn btn-light ml-2 pointer`}
                            />
                        </DateRangePicker>
                    </div>
                    <div style={{ width: '200px' }}>
                        <AsyncSelect isClearable theme={selectColor} required placeholder="Select Route" cacheOptions loadOptions={handleSearchRoute} defaultOptions onChange={(e) => setRoute(e?.value)} />
                    </div>

                    <div style={{ width: '230px' }}>
                        <AsyncSelect isClearable theme={selectColor} required placeholder="Select Transporter" cacheOptions loadOptions={handleSearchTransporter} defaultOptions onChange={(e) => setTransporter(e?.value)} />
                    </div>

                    <div style={{ width: '200px' }}>
                        <AsyncSelect isClearable theme={selectColor} required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => setVehicle(e?.value)} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-6 col-xl">
                        <div className="card">
                            <div className="card-body p-3 py-3 my-3">
                                <div className="row align-items-end gx-0">
                                    <div className="col">
                                        <div className="d-flex p-1 gap-3 align-items-center">
                                            <div className="border rounded px-3">
                                                <i className="fe fe-target display-3" style={{ color: secondary }}></i>
                                            </div>
                                            <div className="d-flex flex-column ps-3 dashboard-counter">
                                                <span className="title fs-2">
                                                    <strong className=" text-muted">Target</strong>
                                                    <h2 className="my-1">{(getSum("tons_mw_delivered") / (days + 1))?.toFixed(2)} / {Intl.NumberFormat("en-IN").format(target)}</h2>
                                                </span>
                                                <div className="progress" style={{ width: '200px' }}>
                                                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${((getSum("tons_mw_delivered") / target) / (days + 1) * 100).toFixed(2)}%`, backgroundColor: secondary }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        {getSum("tons_mw_delivered") &&
                                            <h1 className="m-0"> {((getSum("tons_mw_delivered") / target) / (days + 1) * 100).toFixed(2)}%</h1>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 col-xl">
                        <div className="card">
                            <div className="d-flex justify-content-between">
                                <h3 className="m-0 px-4 pt-3">Delivered</h3>
                            </div>
                            <div className="card-body p-3">
                                <div className="row align-items-center gx-0">
                                    <div className="col">
                                        <div className="d-flex p-2 gap-3 align-items-center">
                                            <div className="px-2 rounded ">
                                                <i className="fe fe-truck display-4" style={{ color: secondary }}></i>
                                            </div>
                                            <div className="d-flex flex-column ps-3 dashboard-counter">
                                                <span className="title">
                                                    <span className=" text-muted">Trips</span>
                                                    <h2 className="my-1">{getSum("trips_delivered")}</h2>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="d-flex p-2 gap-3 align-items-center">
                                            <div className="px-2 rounded ">
                                                <i className="fe fe-truck display-4" style={{ color: secondary }}></i>
                                            </div>
                                            <div className="d-flex flex-column ps-3 dashboard-counter">
                                                <span className="title">
                                                    <span className=" text-muted">Tippers</span>
                                                    <h2 className="my-1">{getSum("tippers_delivered")}</h2>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="d-flex p-2 gap-3 align-items-center">
                                            <div className="px-2 rounded">
                                                <i className="fe fe-truck display-4" style={{ color: secondary }}></i>
                                            </div>
                                            <div className="d-flex flex-column ps-3 dashboard-counter">
                                                <span className="title">
                                                    <span className=" text-muted">Tons (RW)</span>
                                                    <h2 className="my-1">{getSum("tons_rw_delivered")?.toFixed(2)}</h2>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 col-xl">
                        <div className="card">
                            <h3 className="m-0 px-4 pt-3">Shortage</h3>
                            <div className="card-body p-3">
                                <div className="row align-items-center gx-0">
                                    <div className="col">
                                        <div className="d-flex p-2 gap-3 align-items-center">
                                            <div className="px-2 rounded ">
                                                <i className="fe fe-truck display-4" style={{ color: secondary }}></i>
                                            </div>
                                            <div className="d-flex flex-column ps-3 dashboard-counter">
                                                <span className="title">
                                                    <span className=" text-muted">Tons</span>
                                                    {getSum("shortage") && (
                                                        <h2 className="my-1">{getSum("shortage")?.toFixed(2)}   ( {((getSum("shortage") / getSum("tons_mw_delivered")) * 100).toFixed(2)} %  )</h2>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-8">
                        <div className="card p-4">
                            <div className="card-body" style={{ width: "63vw", height: "62vh" }}>
                                <h3>Coal Target/Delivered</h3>
                                {/* <BarChart data={bar_chart_data} label={bar_chart_labels} datasets={dataSetsBar} /> */}
                                <Bar options={options} data={dataX} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4 ">
                        <div className="card w-100" style={{ height: "67vh" }}>
                            <div className="card-body">
                                <h3>Coal Delivered (Pakur / Dumka)</h3>
                                <div className="d-flex justify-content-center  align-items-center h-100">
                                    <DoughnutChart data={pie_chart_data} backgroundColors={backgroundColors} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default ExecutiveDashboard