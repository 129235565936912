import React, { useState } from 'react'
import GpsReport from '../../components/GpsReport'
import moment from 'moment'
import { getTime } from '../../components/Utils'
import NoAccess from '../../components/NoAccess'
import { useSelector } from 'react-redux'

export default function Ping() {
  const [data, setData] = useState([])

  const formatData = (x) => {
    const res = x.map(item => {
      let report = {
            "Date" : moment.unix(item?.ts).format("DD-MMM-YYYY, hh:mm A"),
          "Vehicle": item?.vehicle_id,
          "Latitude" : item?.lat,
          "Longitude" : item?.lng,
      };
      return report
  })
    setData(x)
    return res
  }
  
  const roles = useSelector((state)=>state.userInfo.payload?.role)
  if(!roles?.includes("PING")) return <NoAccess/>

  return (
    <div>
      <GpsReport title={'Ping Report'} api={'ping'} formatData={formatData} />
      <div className="card">
        <table className='table-hover table-bordered'>
          <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
            <tr>
              <th className="fs-5 text-center">Date</th>
              <th className="fs-5 text-center">Vehicle</th>
              <th className="fs-5 text-center">Latitude</th>
              <th className="fs-5 text-center">Longitude</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <tr key={idx}>
                <td align="center">{moment.unix(item?.ts).format("DD-MMM-YYYY, hh:mm A")}</td>
                <td align="center">{item?.vehicle_id}</td>
                <td align="center">{item?.lat}</td>
                <td align="center">{item?.lng}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
      </div>
    </div>
  )
}
