import React from 'react'
import sidebarData from "../data/sidebar.json"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useSelector } from 'react-redux'

export default function TopNav({ address }) {
  const navigate = useNavigate()

  const location = useLocation()
  const path = location?.pathname
  const user = useSelector((state) => state.userInfo.payload);

  function allow(role) {
    if (user?.role) {
      for (let item of user?.role) {
        if (role.includes(item)) {
          return true
        }
      }
    }
  }

  return (
    <nav className="navbar navbar-expand-lg" id="topnav">
      <div className="container-fluid px-lg-5">
        {/* Toggler */}
        <button
          className="navbar-toggler me-auto collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="navbar-user">
          <h4 className='m-2'>Welcome , {user?.name}</h4>
          {typeof address === "string" && <h4 className='m-2'>({address.slice(8)} )</h4>}
          <div className="dropdown">
            <a
              href="#"
              className="avatar avatar-sm  dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src="/img/user.png"
                alt="..."
                className="avatar-img rounded-circle"
              />
            </a>
            <div className="dropdown-menu dropdown-menu-end">
              <button type='button' className="dropdown-item" onClick={async () => {
                await Auth.signOut()
                navigate('/')
              }}>
                Logout
              </button>
            </div>
          </div>
        </div>
        <div
          className="navbar-collapse me-lg-auto order-lg-first collapse"
          id="navbar"
        >
          <img src="/img/logo.svg" alt="logo" height={30} />
          <ul className="navbar-nav me-lg-auto ms-lg-5">
            {
              sidebarData?.map((item, idx) => (
                <li key={idx} className={`nav-item dropdown ${item?.childrens?.length > 0 && "x-down"}`}>
                  {allow(item?.role) && (
                    <Link
                      className={`nav-link show ${item?.childrens?.length > 0 && "dropdown-toggle"} ${path?.includes(item?.link) && "active"} `}
                      to={item?.link}
                      target={item?.link?.includes("tracker") ? "_blank" : "_self"}
                      id="topnavPages"
                      data-bs-toggle={item?.childrens?.length > 0 ? "dropdown" : ""}
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {item?.name}
                    </Link>
                  )}

                  <ul className="dropdown-menu x-item" aria-labelledby="topnavDashboards">
                    {item?.childrens?.map((x, idx) => (
                      <div key={idx}>
                        <li className='x-child'>
                          {user?.role?.includes(x?.role) && (
                            <Link className="dropdown-item active" to={x?.link}>
                              {x?.name}
                            </Link>
                          )}
                        </li>
                      </div>
                    ))}
                  </ul>
                </li>
              ))
            }
          </ul>
          {/* {!user?.role?.includes("USERS") && (
                  <>
                    {user?.role?.includes("IN_BARRIER") && (
                      <Link to={"/gatepass/create"} className="mx-2 nav-link"><b className="fs-2">In Barrier</b></Link>
                    )}
                    {user?.role?.includes("OUT_BARRIER") && (
                      <Link to={"/gatepass/create"} className="mx-2 nav-link"><b className="fs-2">Out Barrier</b></Link>
                    )}
                    {user?.role?.includes("WEIGH_BRIDGE") && (
                      <Link to={"/gatepass/create"} className="mx-2 nav-link"><b className="fs-2">Weigh Bridge</b></Link>
                    )}
                    {user?.role?.includes("RAILWAY_SIDING_ENTRY") && (
                      <Link to={"/gatepass/create"} className="mx-2 nav-link"><b className="fs-2">Railway Siding Entry</b></Link>
                    )}
                    {user?.role?.includes("RAILWAY_SIDING_EXIT") && (
                      <Link to={"/gatepass/create"} className="mx-2 nav-link"><b className="fs-2">Railway Siding Exit</b></Link>
                    )}
                  </>
                )} */}
        </div>
      </div>
    </nav>


  )
}
