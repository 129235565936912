import { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import NoAccess from '../../components/NoAccess';
import ReportCard from '../../components/ReportCard';
import { selectColor } from '../../components/Utils';
import moment from 'moment';

export default function VehicleAttendanceReport() {
    const [data, setData] = useState([])
    const [vehicles, setVehicles] = useState({ pakur: 0, dumka: 0 })
    const [present, setPresent] = useState({ pakur: 0, dumka: 0 })
    const [absent, setAbsent] = useState({ pakur: 0, dumka: 0 })
    const [tempData, setTempData] = useState([])

    const options = [
        { value: 'Present', label: 'Present' },
        { value: 'Absent', label: 'Absent' }
    ]



    function formatData(data, vehicles, totalCountRes) {
        if (vehicles) {
            setVehicles({ pakur: totalCountRes.find(item => item.name === "Pakur")?.total_vehicles, dumka: totalCountRes.find(item => item.name === "Dumka")?.total_vehicles })
            setPresent({ pakur: vehicles.find(item => item.route === "Pakur")?.present, dumka: vehicles.find(item => item.route === "Dumka")?.present })
            setAbsent({ pakur: totalCountRes.find(item => item.name === "Pakur")?.total_vehicles - vehicles.find(item => item.route === "Pakur")?.present, dumka: totalCountRes.find(item => item.name === "Dumka")?.total_vehicles - vehicles.find(item => item.route === "Dumka")?.present })

        }
        const res = data.map(item => {
            let report = {
                "Vehicle No.": item?.id,
                "Transporter Name": item?.transporter_name,
                "Route Name": item?.route_name,
                "No. of Gatepass": item?.gatepass_count,
                "Last Trip Date": item?.last_trip ? moment.unix(item?.last_trip).format("DD-MM-YYYY hh:mm A") : null
            };
            return report
        })

        setData(res)
        setTempData(res)
        return res
    }
    const roles = useSelector((state) => state.userInfo.payload?.role)
    if (!roles?.includes("VEHICLE-ATTENDANCE")) {
        return <NoAccess />
    }

    return (
        <>
            <ReportCard title={"Vehicle Attendance Report"} formatData={formatData} api={'vehicle-attendance-report'} />
            <div className="table-responsive card">
                <table className="table-hover table-bordered">
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                        <tr>
                            <th className={"text-center"}></th>
                            <th className={"text-center"}>Total Vehicles</th>
                            <th className={"text-center"}>Present</th>
                            <th className={"text-center"}>Absent</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td align='center'>Pakur</td>
                            <td align='center'>{vehicles?.pakur}</td>
                            <td align='center'>{present?.pakur} {present?.pakur && <span>( {((present?.pakur / vehicles?.pakur) * 100).toFixed(2)} % )</span>}</td>
                            <td align='center'>{absent?.pakur} {absent?.pakur && <span>( {((absent?.pakur / vehicles?.pakur) * 100).toFixed(2)} %)</span>}</td>
                        </tr>
                        <tr>
                            <td align='center'>Dumka</td>
                            <td align='center'>{vehicles?.dumka}</td>
                            <td align='center'>{present?.dumka} {present?.dumka && <span>( {((present?.dumka / vehicles?.dumka) * 100).toFixed(2)} % )</span>}</td>
                            <td align='center'>{absent?.dumka} {absent?.dumka && <span>( {((absent?.dumka / vehicles?.dumka) * 100).toFixed(2)} %)</span>}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='my-3 row'>
                <div className='col-2 ms-auto'>
                    <Select options={options} key={vehicles?.pakur} theme={selectColor} placeholder="Status" isClearable onChange={(e) => {
                        let temp = []
                        if (e?.value === "Present") temp = tempData.filter(item => +item["No. of Gatepass"] !== 0)
                        else if (e?.value === "Absent") temp = tempData.filter(item => +item["No. of Gatepass"] === 0)
                        else temp = tempData
                        setData(temp)
                    }} />
                </div>
            </div>
            <div className="table-responsive card">
                <table className="table-hover table-bordered">
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                        <tr>
                            {data?.length > 0 && Object.keys(data[0])?.map(item => (
                                <th className={`fs-5 ${item !== 'Transporter Name' && "text-center"}`} key={item}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, idx) => (
                            <tr key={idx}>
                                <td align='center' className='fs-5'>{item["Vehicle No."]}</td>
                                <td align='left' className='fs-5'>{item["Transporter Name"]}</td>
                                <td align='center' className='fs-5'>{item["Route Name"]}</td>
                                <td align='center' className='fs-5'>{item["No. of Gatepass"]}</td>
                                <td align='center' className='fs-5'>{item["Last Trip Date"]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
