import { API } from "aws-amplify"
import { useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { apiName, handleSearchRoute, handleSearchTransporter, handleSearchVehicles, selectColor } from "../../components/Utils"
import Header from "../../layouts/Header"

export default function RemoveVehicle() {
    const navigate = useNavigate()
    const [oldVehicle, setOldVehicle] = useState({})
    const [newVehicle, setNewVehicle] = useState({})
    const [loading, setLoading] = useState(false)

    async function handleCreateReq() {
        const loading = toast.loading('Loading...')
        setLoading(true)
        try {
            await API.post(apiName, '/vehicle-request', { body: { old_vehicle_id: oldVehicle?.id, old_vehicle_details: oldVehicle, type: "TRANSFER", new_vehicle_id: newVehicle?.id, new_vehicle_details: newVehicle } })
            toast.success('Transfer request created.')
            navigate('/vehicle/request-list')
        } catch (error) {
            console.log(error)
            toast.error("Soemthing went wrong.")
        } finally {
            {
                toast.dismiss(loading)
                setLoading(false)
            }
        }
    }

    return (
        <div className="container my-4">
            <Header name={'Transfer Vehicle'} />
            <div className="card col col-12 p-5 mt-5">
                <form className="row" onSubmit={(e) => {
                    e.preventDefault()
                    handleCreateReq()
                }}>
                    <div className="col col-lg-12">
                        <label className="my-2">Select Vehicle <span className="text-danger">*</span></label>
                        <AsyncSelect theme={selectColor} required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={async (e) => {
                            let res = await API.get(apiName, `/vehicle/${e?.value}`)
                            setOldVehicle(res)
                            setNewVehicle(res)
                        }} />
                    </div>
                    <div className="col col-lg-6">
                        <label className="my-2">Old Transporter</label>
                        <input
                            type="text"
                            className="form-control"
                            disabled
                            required
                            value={oldVehicle?.transporter_name}
                            maxLength={40}
                        />
                    </div>
                    <div className="col col-lg-6">
                        <label className="my-2">Old Route</label>
                        <input
                            type="text"
                            className="form-control"
                            disabled
                            required
                            value={oldVehicle?.route_name}
                            maxLength={40}
                        />
                    </div>
                    <div className="col col-lg-6">
                        <label className="my-2">New Transporter</label>
                        <AsyncSelect theme={selectColor} required placeholder="Select Transporter" cacheOptions loadOptions={handleSearchTransporter} defaultOptions onChange={async (e) => {
                            setNewVehicle((prev) => ({ ...prev, transporter_id: e?.value, transporter_name: e?.label }))
                        }} />
                    </div>
                    <div className="col col-lg-6">
                        <label className="my-2">New Route</label>
                        <AsyncSelect theme={selectColor} required placeholder="Select Route" cacheOptions loadOptions={handleSearchRoute} defaultOptions onChange={async (e) => {
                            setNewVehicle((prev) => ({ ...prev, route_id: e?.value, route_name: e?.label }))
                        }} />
                    </div>
                    <div className="flex">
                        <button disabled={loading} className="btn btn-primary my-3 ms-auto">Create Request</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
