import moment from "moment";
import { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import toast from "react-hot-toast";
import AsyncSelect from "react-select/async";
import { apiName, handleSearchRoute, handleSearchTransporter, handleSearchVehicles, selectColor } from "../../components/Utils";
import DoughnutChart from "../../components/charts/DoughnutChart";

import { API } from "aws-amplify";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import NoAccess from "../../components/NoAccess";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



const DailyDispatch = () => {
    const primary = "#ccaf59"  //yellow
    const secondary = "#407050" // green
    const backgroundColors = [secondary, primary];
    const [date, setDate] = useState({ start: moment().subtract(6, 'days').startOf('day').unix(), end: moment().endOf('day').unix() })
    const [vehicle, setVehicle] = useState(null)
    const [transporter, setTransporter] = useState(null)
    const [route, setRoute] = useState(null)
    const [barData, setBarData] = useState([])
    const [shortage, setShortage] = useState({ pakur: 0, dumka: 0 })

    const barThickness = barData.length > 10 ? 10 : 30


    const datax = {
        labels: barData.map(item => moment(item.date).format("DD-MMM")),
        datasets: [
            {
                label: 'Tons (MW)',
                data: barData.map((item) => item?.total_mw),
                backgroundColor: primary,
                stack: 'Stack 0',
                barThickness,
                borderWidth: 3,
                borderColor: "white",
                borderRadius: 5,
            },
            {
                label: 'Tons (RW)',
                data: barData.map((item) => item?.total_rw),
                backgroundColor: secondary,
                stack: 'Stack 1',
                barThickness,
                borderWidth: 3,
                borderColor: "white",
                borderRadius: 5,
            },
        ],
    };
    const options = {
        plugins: {
            datalabels: {
                color: "green",
                anchor: 'start',
                offset: 'top',
                align: 'top',
                formatter: Math.round,
                font: {
                    weight: 'bold',
                }
            },
            title: {
                display: false,
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
    };



    const pie_chart_data = {
        labels: ['Pakur', 'Dumka'],
        datasets: [{ data: [shortage?.pakur?.toFixed(2), shortage?.dumka?.toFixed(2)] }],
    };



    const handleDateApplied = (event, picker) => {
        const fromDate = moment(picker.startDate).startOf("day").unix()
        const toDate = Math.floor(moment(picker.endDate).endOf("day").unix());
        setDate({ start: fromDate, end: toDate });
    };

    async function getBarData() {
        try {
            const start = date?.start === moment().startOf('day').unix() ? moment().subtract(6, 'days').startOf('day').unix() : date?.start
            let query = `/dashboard/daily-dispatch?start=${start}&end=${date?.end}`
            if (transporter) query = `${query}&transporter_id=${transporter}`;
            if (vehicle) query = `${query}&vehicle_id=${vehicle}`;
            if (route) query = `${query}&route_id=${route}`;

            let data = await API.get(apiName, query)
            let temp = []

            let group = Object.groupBy(data, ({ exit_date }) => exit_date)

            for (let item in group) {
                let date = item
                let total_mw = group[item].reduce((acc, curr) => acc + +curr.mw, 0)
                let total_rw = group[item].reduce((acc, curr) => acc + +curr.rw, 0)
                temp.push({ date, total_mw, total_rw })
            }

            setShortage({
                pakur: (data?.filter(item => item.route_name === "Pakur")).reduce((acc, curr) => acc + +curr.shortage, 0),
                dumka: (data?.filter(item => item.route_name === "Dumka")).reduce((acc, curr) => acc + +curr.shortage, 0)
            })
            setBarData(temp)
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong !")
        }
    }

    useEffect(() => {
        getBarData()
    }, [date, route, transporter, vehicle])


    const roles = useSelector((state) => state.userInfo.payload?.role)
    if (!roles?.includes("DAILY_DISPATCH")) {
        return <NoAccess />
    }

    return (
        <section>
            <div>
                <div className="row mb-3">
                    <h2 className="col m-0">Day Wise Dispatch</h2>
                    <div className="col-auto">
                        <DateRangePicker
                            initialSettings={{
                                startDate: moment.unix(date?.start).format("MM-DD-YYYY"),
                                endDate: moment.unix(date?.end).format("MM-DD-YYYY"),
                                linkedCalendars: true,
                                showCustomRangeLabel: true,
                                showDropdowns: true,
                                alwaysShowCalendars: true,
                                ranges: {
                                    'Today': [moment(), moment()],
                                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                    'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                }
                            }}
                            onApply={handleDateApplied}
                        >
                            <input
                                className={`btn btn-light ml-2 pointer`}
                            />
                        </DateRangePicker>
                    </div>
                    <div style={{ width: '200px' }}>
                        <AsyncSelect isClearable theme={selectColor} required placeholder="Select Route" cacheOptions loadOptions={handleSearchRoute} defaultOptions onChange={(e) => setRoute(e?.value)} />
                    </div>

                    <div style={{ width: '230px' }}>
                        <AsyncSelect isClearable theme={selectColor} required placeholder="Select Transporter" cacheOptions loadOptions={handleSearchTransporter} defaultOptions onChange={(e) => setTransporter(e?.value)} />
                    </div>

                    <div style={{ width: '200px' }}>
                        <AsyncSelect isClearable theme={selectColor} required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => setVehicle(e?.value)} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-8">
                        <div className="card w-100 h-100">
                            <div className="card-body">
                                <h3>Coal Target/Delivered</h3>
                                {/* <BarChart data={bar_chart_data} label={bar_chart_labels} datasets={dataSetsBar} /> */}
                                <Bar options={options} data={datax} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4 ">
                        <div className="card w-100 h-100">
                            <div className="card-body">
                                <h3>Total Shortage (Pakur / Dumka)</h3>
                                <div className="d-flex justify-content-center  align-items-center h-100">
                                    <DoughnutChart data={pie_chart_data} backgroundColors={backgroundColors} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default DailyDispatch

