import React, { useState } from 'react'
import GpsReport from '../../components/GpsReport'
import moment from 'moment'
import { getTime } from '../../components/Utils'
import { useSelector } from 'react-redux'
import NoAccess from '../../components/NoAccess'

export default function IdleReport() {
  const [data, setData] = useState([])

  const formatData = (x) => {
    x = x.filter(item => +item?.max > 59)
    const res = x.map(item => {
      let report = {
          "Date": moment.unix(item?.halted_since).format("DD-MMM-YYYY, hh:mm A"),
          "Duration" : getTime(item?.max / 60) || 0,
      };
      return report
  })
    setData(x)
    return res
  }

  const roles = useSelector((state)=>state.userInfo.payload?.role)
  if(!roles?.includes("HALTED")) return <NoAccess/>

  return (
    <div>
      <GpsReport title={'Halted Report'} api={'halted'} formatData={formatData} />
      <div className="card">
        <table className='table-hover table-bordered'>
          <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
            <tr>
              <th className="fs-5 text-center">Date</th>
              <th className="fs-5 text-center">Duration</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <tr key={idx}>
                <td align="center">{moment.unix(item?.halted_since).format("DD-MMM-YYYY, hh:mm A")}</td>
                <td align="center">{getTime(item?.max / 60) || 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.length === 0 && <h4 className="text-center my-4">No data found.</h4>}
      </div>
    </div>
  )
}
