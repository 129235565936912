import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
Chart.register(ChartDataLabels);

const BarChart = ({ data, label,datasets }) => {
    const chartData = {
        labels: data.map((item) => item.name),
        datasets: datasets
    };


    const chartOptions = {
        maintainAspectRatio : false,
        plugins: [ChartDataLabels],
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                grace:"5%",
                
            },
        },
        plugins: {
            datalabels: {
                color : "black",
                anchor: 'end',
                offset:-4,
                align: 'top',
                formatter: Math.round,
                font: {
                    weight: 'bold',
                }
            }
        }
    };

    return <Bar data={chartData} options={chartOptions} />;
};

export default BarChart;
