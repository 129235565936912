import { Pagination } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import * as XLSX from "xlsx";
import Spinner from '../../components/Spinner';
import { apiName, delay, limit, selectColor } from '../../components/Utils';


const RequestList = () => {
  const navigate = useNavigate()
  const user = useSelector((state) => state?.userInfo?.payload);
  const approvalRole = user?.role.find(item => item.includes("APPROVAL"))


  const modalBtnRef = useRef()

  const [list, setList] = useState([])
  const [spinner, setSpinner] = useState(true)
  const [show, setShow] = useState(false)
  const [comment, setComment] = useState("")
  const [type, setType] = useState(null)
  const [search, setSearch] = useState("")
  const [status, setStatus] = useState(null)
  const [dateFilters, setDateFilters] = useState(null)
  const [approvalList, setApprovalList] = useState([])
  const [count, setCount] = useState(0)
  const [total, setTotal] = useState(0)


  function handlePaginationChange(newPageIndex) {
    setCount(newPageIndex - 1)
  }

  const fetchData = async () => {
    try {
      setSpinner(true)
      let query = `/vehicle-request?take=${limit}&skip=${count * limit}&vehicle_id=${search}`
      if (type) query = `${query}&type=${type?.value}`
      if (dateFilters) query = `${query}&start=${dateFilters?.start}&end=${dateFilters?.end}`

      let data = await API.get(apiName, query)
      setList(data.items)
      setTotal(data.count)
      setSpinner(false)
    } catch (error) {
      console.log(error)
      setSpinner(false)
    }
  }

  const getStatusIcon = (data) => {
    if (data === true) {
      return <i className='fe fe-check-circle'></i>
    } else if (data === false) {
      return <i className='fe fe-x-circle'></i>
    }
    return <i className='fe fe-clock'></i>
  }

  const onApproveHandler = async () => {
    if (approvalList.length === 0) return toast.error("Please select request !")
    if (approvalRole === "APPROVAL_DISPATCH_MANAGER") return toast.error("No access...")

    if (!approvalRole) return toast.error("No acccess..")

    approvalList.map(async (item) => {
      let { id, history, type } = item

      if (history === null) {
        history = [{ user: user?.name, status, comment, date: moment().format('DD-MM-YYYY[, ]hh:mm A'), role: approvalRole }]
      } else {
        let temp = history
        if (!temp) {
          temp.approve = []
        }
        temp?.unshift({ user: user?.name, status, comment, date: moment().format('DD-MM-YYYY[, ]hh:mm A'), role: approvalRole })
        history = temp
      }
      try {
        setSpinner(true)
        let payload = {}

        const filter = list?.filter((x) => x?.id === id)

        if (type === "ADD" && approvalRole === "APPROVAL_CONTROL_ROOM" && status && !filter[0].approved_by_director) {
          toast.error("Cannot approve without Director's approval!")
          setSpinner(false)
          return
        } else if (type !== "ADD" && approvalRole === "APPROVAL_CONTROL_ROOM" && status && !filter[0].approved_by_vice_president) {
          toast.error("Cannot approve without Vice President's approval!")
          setSpinner(false)
          return
        }

        if (approvalRole === "APPROVAL_VICE_PRESIDENT") {
          payload.approved_by_vice_president = status
        } else if (approvalRole === "APPROVAL_DIRECTOR") {
          payload.approved_by_director = status
        } else if (approvalRole === "APPROVAL_PROJECT_MANAGER") {
          payload.approved_by_project_manager = status
        }
        else if (approvalRole === "APPROVAL_CONTROL_ROOM") {
          payload.approved_by_control_room = status
        }
        payload.history = history

        await API.put(apiName, `/vehicle-request/${id}`, { body: { ...item, ...payload } })

        if (payload.approved_by_control_room) {
          if (type !== 'DELETE' && type !== "TRANSFER") {
            await API.post(apiName, `/vehicle`, { body: { ...item?.new_vehicle_details, approved: true } })
            await API.post(apiName, '/vehicle/add-to-zk', { body: { pin: item.new_vehicle_id, cardNo: item.new_vehicle_details.rfid, deptCode: "BGR" } })
          }

          if (type === "TRANSFER") await API.put(apiName, `/vehicle/${item?.new_vehicle_id}`, { body: { ...item?.new_vehicle_details } })

          if (type === "DELETE" || type === "EXCHANGE") {
            await API.put(apiName, `/vehicle/${item?.old_vehicle_id}`, { body: { ...item?.old_vehicle_details, deleted: true, active: false } })
          }
        }

        if (status) {
          toast.success(type === "DELETE" ? "Vehicle Deleted." : "Vehicle Approved")
        } else {
          toast.error("Vehicle Rejected")
        }
        const index = list.findIndex((x) => x.id === id)
        const temp = [...list]
        temp[index].history = item.history

        if (approvalRole === "APPROVAL_VICE_PRESIDENT") {
          temp[index].approved_by_vice_president = status
        } else if (approvalRole === "APPROVAL_DIRECTOR") {
          temp[index].approved_by_director = status
        } else if (approvalRole === "APPROVAL_PROJECT_MANAGER") {
          temp[index].approved_by_project_manager = status
        } else if (approvalRole === "APPROVAL_CONTROL_ROOM") {
          temp[index].approved_by_control_room = status
        }

        setList(temp)
        setComment("")
        setShow(false)
      } catch (error) {
        console.log(error)
        toast.error(error?.message)
        setSpinner(false)
      }
    })
    setSpinner(false)
  }

  const handleDownload = async () => {
    const loading = toast.loading("Downloading...")
    try {
      let query = `/vehicle-request?take=${999999999}`
      if (dateFilters) query = `${query}&start=${dateFilters?.start}&end=${dateFilters?.end}`
      if (!dateFilters) query = `${query}&start=${moment().startOf("day").unix()}&end=${moment().endOf("day").unix()}`
      if (type) query = `${query}&type=${type?.value}`
      let res = await API.get(apiName, query)

      let temp = res.items.map(item => {
        return {
          DATE: moment.unix(item?.created_at).format("DD-MM-YY"),
          ID: item?.id,
          VEHICLE_NUMBER: item?.new_vehicle_id,
          OLD_VEHICLE_NUMBER: item?.old_vehicle_id,
          TYPE: item?.type,
          NEW_TRANSPORTER_NAME: item?.new_vehicle_details?.transporter_name,
          OLD_TRANSPORTER_NAME: item?.old_vehicle_details?.transporter_name,
          NEW_ROUTE: item?.new_vehicle_details?.route_name,
          OLD_ROUTE: item?.old_vehicle_details?.route_name,

          APPROVED_BY_PM: item?.approved_by_project_manager === true ? "YES" : item?.approved_by_project_manager === false ? "NO" : "PENDING",
          APPROVED_BY_PM_ON: item?.history ? item?.history?.find(item => item?.role === "APPROVAL_PROJECT_MANAGER")?.date : "",

          APPROVED_BY_VP: item?.approved_by_vice_president === true ? "YES" : item?.approved_by_vice_president === false ? "NO" : "PENDING",
          APPROVED_BY_VP_ON: item?.history ? item?.history.find(item => item?.role === "APPROVAL_VICE_PRESIDENT")?.date : "",

          APPROVED_BY_DIRECTOR: item?.approved_by_director === true ? "YES" : item?.approved_by_director === false ? "NO" : "PENDING",
          APPROVED_BY_DIRECTOR_ON: item?.history ? item?.history.find(item => item?.role === "APPROVAL_DIRECTOR")?.date : "",

          PHYSICAL_VERIFCATION: item?.approved_by_control_room ? "DONE" : "PENDING",
          PHYSICAL_VERIFCATION_ON: item?.history ? item?.history.find(item => item?.role === "APPROVAL_CONTROL_ROOM")?.date : "",
        }
      })
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(temp);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `Request List ${(dateFilters?.start ? moment.unix(dateFilters?.start).format("DD-MM-YYYY") : moment().format("DD-MM-YY"))} to ${(dateFilters?.end ? moment.unix(dateFilters?.end).format("DD-MM-YYYY") : moment().format("DD-MM-YY"))}.xlsx`);
      toast.success("Download Successful.")
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong !")
    } finally { toast.dismiss(loading) }
  }

  const handleDateApplied = (event, picker) => {
    const start = moment(picker.startDate).startOf("day").unix()
    const end = Math.floor(moment(picker.endDate).endOf("day").unix());
    setDateFilters({ start, end });
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchData()
    }, delay);

    return () => clearTimeout(timer)
  }, [type, search, dateFilters, count])


  return (
    <div className="container-fluid">
      <div className="header">
        <div className="header-body">
          <div className="d-flex">
            <div className="col">
              <h1 className="header-title ">Request List</h1>
            </div>
            <div className="col-auto ms-auto d-flex gap-3" >
              <button className="btn btn-primary w-100 fw-bold" onClick={() => {
                if (approvalList.length === 0) {
                  return toast.error("Please select request !")
                }
                setStatus(true)
                setShow(true)
              }}>{approvalRole === "APPROVAL_CONTROL_ROOM" ? "Verify" : "Approve"}</button>
              {approvalRole !== "APPROVAL_CONTROL_ROOM" && <button className="btn btn-danger w-100 fw-bold"
                onClick={() => {
                  if (approvalList.length === 0) {
                    return toast.error("Please select request !")
                  }
                  setStatus(false)
                  setShow(true)
                }}>Reject</button>}
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <div className="input-group input-group-flush input-group-merge input-group-reverse">
                <input
                  autoFocus
                  className="form-control list-search"
                  type="search"
                  placeholder="Search by vehicle number"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
                <span className="input-group-text">
                  <i className="fe fe-search"></i>
                </span>
              </div>
            </div>
            <div className='col-auto' style={{ width: "10rem" }}>
              <Select
                isClearable
                theme={selectColor}
                defaultValue={type}
                placeholder="TYPE"
                options={[{ value: "ADD", label: "ADD" }, { value: "EXCHANGE", label: "EXCHANGE" }, { value: "TRANSFER", label: "TRANSFER" },
                { value: "DELETE", label: "REMOVE" }]}
                onChange={e => setType(e)}
              />
            </div>
            <div className='col-auto'>
              <DateRangePicker
                initialSettings={{
                  startDate: moment(dateFilters?.start).format("MM-DD-YYYY"),
                  endDate: moment(dateFilters?.end).format("MM-DD-YYYY"),
                  linkedCalendars: true,
                  showCustomRangeLabel: true,
                  showDropdowns: true,
                  alwaysShowCalendars: true,
                }}
                onApply={handleDateApplied}
              >
                <input
                  className={`btn ${dateFilters ? "btn-primary" : "btn-white"} ml-2 pointer`}
                />
              </DateRangePicker>
            </div>
            <div className='col-auto d-flex gap-1'>
              <button
                className="btn btn-light"
                onClick={() => {
                  setSearch("")
                  setType(null)
                  setDateFilters(null)
                }}>
                <i className='fe fe-x' />
              </button>
              <button
                data-toggle="modal" data-target="#exampleModal"
                className="btn btn-primary w-100 fw-bold" onClick={handleDownload}><i className="fe fe-download" /></button>
            </div>
          </div>
        </div>
        <Spinner show={spinner}>
          <div className="table-responsive" style={{ height: "64vh" }}>
            <table className="table table-sm table-hover  card-table">
              <thead className="sticky-top" style={{ zIndex: 0 }}>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Vehicle / Transporter </th>
                  <th>Route</th>
                  <th>Type</th>
                  <th className='text-center'>Project Manager</th>
                  <th className='text-center'>Vice President</th>
                  <th className='text-center'>Director</th>
                  <th className='text-center'>Physical Verification</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => (
                  <tr key={index} className="pointer" onClick={() => navigate(`/vehicle/request/${item?.id}`)}>

                    <td onClick={(e) => e.stopPropagation()}><input type="checkbox" className="checkbox"
                      disabled={(approvalRole === "APPROVAL_PROJECT_MANAGER" && item?.approved_by_project_manager !== null && item?.approved_by_vice_president !== null)
                        || (approvalRole === "APPROVAL_VICE_PRESIDENT" && item?.approved_by_vice_president !== null && item?.approved_by_director !== null)
                        || (approvalRole === "APPROVAL_DIRECTOR" && item?.approved_by_director !== null)
                        || (approvalRole === "APPROVAL_CONTROL_ROOM" && item?.approved_by_control_room !== null)}
                      onChange={e => {
                        if (e.target.checked) {
                          setApprovalList((prev) => ([...prev, item]))
                        } else {
                          let temp = structuredClone(approvalList)
                          temp = temp.filter(x => x.id !== item.id)
                          setApprovalList(temp)
                        }
                      }} /> </td>
                    <td>
                      <div>{item?.id}</div>
                      <div>{moment.unix(item.created_at).format("DD-MM-YYYY")}</div>
                    </td>
                    <td className="fw-bold">
                      <div>{item?.new_vehicle_id || item?.old_vehicle_id}</div>
                      <div className="text-secondary">{item?.new_vehicle_details?.transporter_name || item?.old_vehicle_details?.transporter_name}</div>
                    </td>
                    <td>{item?.new_vehicle_details?.route_name || item?.old_vehicle_details?.route_name}</td>
                    <td>{item?.type === "DELETE" ? "REMOVE" : item?.type}</td>

                    <td className='text-center'>
                      {getStatusIcon(item?.approved_by_project_manager)}
                      <p className='m-0 mt-3'>{item?.history?.find(item => item?.role?.includes("PROJECT_MANAGER"))?.date?.split(",")[0]}</p>
                    </td>

                    <td className='text-center'>{getStatusIcon(item?.approved_by_vice_president)}
                      <p className='m-0 mt-3'>{item?.history?.find(item => item?.role?.includes("VICE_PRESIDENT"))?.date?.split(",")[0]}</p></td>

                    <td className='text-center'>{item?.type === "ADD" ? getStatusIcon(item?.approved_by_director) : item?.approved_by_director === null ? "_" : getStatusIcon(item?.approved_by_director)}
                      <p className='m-0 mt-3'>{item?.history?.find(item => item?.role?.includes("DIRECTOR"))?.date?.split(",")[0]}</p>
                    </td>

                    <td className='text-center'>{getStatusIcon(item?.approved_by_control_room)}
                      <p className='m-0 mt-3'>{item?.history?.find(item => item?.role?.includes("CONTROL_ROOM"))?.date?.split(",")[0]}</p>
                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
            {list.length === 0 && <p className="text-center mt-3">No items found</p>}
          </div>
          <div className="card-footer d-flex justify-content-center">
            <Pagination
              currentPage={count + 1}
              totalPages={Math.ceil(total / limit)}
              siblingCount={1}
              onNext={() => setCount((prev) => prev + 1)}
              onPrevious={() => setCount((prev) => prev - 1)}
              onChange={handlePaginationChange}
            />
          </div>
        </Spinner>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <form onSubmit={(e) => {
          e.preventDefault()
          onApproveHandler()
        }}>
          <Modal.Header>
            <Modal.Title><b>Remarks</b></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input className="form-control" placeholder="Enter remarks" value={comment} onChange={(e) => setComment(e.target.value)} maxLength={60} /></Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button disabled={spinner} variant={`${status === true ? "primary" : "danger"}`} type="submit">
              <b>{status === true ? `${approvalRole === "APPROVAL_CONTROL_ROOM" ? "Verify" : "Approve"}` : "Reject"}</b>
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Select Date Range (Optional)</h5>
            </div>
            <div className="modal-body">
              <DateRangePicker
                initialSettings={{
                  startDate: moment(dateFilters?.start).format("MM-DD-YYYY"),
                  endDate: moment(dateFilters?.end).format("MM-DD-YYYY"),
                  linkedCalendars: true,
                  showCustomRangeLabel: true,
                  showDropdowns: true,
                  alwaysShowCalendars: true,
                }}
                onApply={handleDateApplied}>
                <input className={`h-fit btn w-100 btn-white pointer`} />
              </DateRangePicker>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary fw-bold" ref={modalBtnRef} data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestList