import React from "react";

const Header = ({ name, onclick, buttonTitle, ref, icon }) => {
  return (
    <div className="header">
      <div className="header-body">
        <div className="d-flex">
          <div className="col">
            <h1 className="header-title">{name}</h1>
          </div>
          {
            onclick && (
              <div className="col-auto" ref={ref}>
                <div onClick={onclick} className="btn btn-primary lift fw-bold">
                  {buttonTitle !== null && <span className="me-2">{buttonTitle}</span>}
                  {icon?.length > 0 && <i className="fe fe-camera"></i>}
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Header;