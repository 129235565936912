import { configureStore } from "@reduxjs/toolkit";
import { userInfoSlice } from "./userInfo";
import { vehicleSlice } from "./vehicles";
import { settingSlice } from "./setting";
const store = configureStore({
  reducer: {
    userInfo: userInfoSlice.reducer,
    vehicles: vehicleSlice.reducer,
    setting: settingSlice.reducer
  },
});

export default store;
