import { Doughnut } from 'react-chartjs-2';
import { ArcElement } from "chart.js";
import Chart from "chart.js/auto";
Chart.register(ArcElement);

const DoughnutChart = (props) => {
    const { data, backgroundColors, width, height } = props;
    const chartData = {
        labels: data?.labels || [],
        datasets: [
            {
                data: data?.datasets[0]?.data || [],
                backgroundColor: backgroundColors || [],
            },
        ],
    };

    const chartOptions = {
        maintainAspectRatio: false,
        cutout: '45%',
        plugins: {
            title: { display: false },
            datalabels: {
               color : 'black',
               font:{
                weight : 'bold'
               }
            },
            legend: {
                position: 'bottom',
                padding: { top: 100 },
                title: {
                    fontColor: 'black',
                    fontSize: 12,
                },
                labels: {
                    pointStyle: 'circle',
                    usePointStyle: true,
                },
            },
        },
    };

    return (
        <section>
            {chartData?.labels?.length > 0 && (
                <Doughnut data={chartData} options={chartOptions} height={400} />
            )}
        </section>
    );
};

export default DoughnutChart

