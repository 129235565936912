
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Barcode from "react-barcode";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import NoAccess from "../../components/NoAccess";
import { apiName, currentDate, handleSearchDriver, handleSearchVehicles, interval, selectColor } from "../../components/Utils";


export default function GatepassCreate() {
  const navigate = useNavigate();
  const closeModal = useRef(null)
  const closeDriverModal = useRef(null)
  const [id, setId] = useState(new Date().getMilliseconds());
  const [id2, setId2] = useState(Math.random());
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [printing, setPrinting] = useState(false)
  const [scanModal, setScanModal] = useState(true)
  const [scanDriverModal, setScanDriverModal] = useState(false)
  const [countDown, setCountDown] = useState(interval)
  const [start, setStart] = useState(false)
  let int = null
  let intDriver = null

  const user = useSelector((state) => state.userInfo.payload);
  const role = user?.role;

  const [show, setShow] = useState(true);
  const [gatepass, setGatepass] = useState({
    entryAt: "",
    exitAt: "",
  });
  const [vehicle, setVehicle] = useState(null);
  const [driver, setDriver] = useState(null);
  const [woNo, setWoNo] = useState(null);

  const [count, setCount] = useState({ driver: null, vehicle: null })
  const [transporter, setTransporter] = useState(null)

  async function checkvehicle(vehicle) {
    if (vehicle) {
      let res = await API.get(apiName, `/gatepass/zk/final-check?door=${user?.lane}&vehicle_id=${vehicle}`)
      return res
    }
  }

  async function checkDumkaVehicle(id) {
    try {
      let res = await API.get(apiName, `/gatepass?take=${2}&start=${moment().startOf("day").unix()}&end=${moment().endOf('day').unix()}&vehicle_id=${id}&route_id=2`)
      return +res.count;
    } catch (error) {
      console.log(error)
    }
  }

  async function handleScanVehicle(value) {
    if (location.pathname.includes("create-offline") && !role.includes("GATEPASS_OFFLINE")) {
      navigate("/gatepass/create")
    }
    const loading = toast.loading('Loading...')
    try {
      let res = await API.get(apiName, `/vehicle/${value}`)
      let currentGatepass = await API.get(apiName, `/gatepass?vehicle_id=${value}&take=1`)

      // if (!res?.rcd_pin) return toast.error("RCD Pin is required !")

      if (currentGatepass.items.length > 0) {
        let currentTimestamp = moment(currentGatepass.items[0]?.mine_entry_time)
        let currentExitAt = currentGatepass.items[0]?.rail_exit_time
        if (!currentExitAt) return toast.error("Previous trip is still active!")
        const newTimestamp = currentTimestamp.add(1, 'hour');
        let allow = moment().isAfter(newTimestamp)
        if (allow === false) {
          toast.error("Kindly try after one hour!")
          return;
        }
      }

      if (res?.active === false) {
        if (res?.comments) {
          toast.error(`Vehicle is currently blocked ! \nReason : ${res.comments[0]?.msg}`);
        } else {
          toast.error(`Vehicle is currently blocked !`)
        }
        return;
      }
      let data = res;
      const transporterRes = await API.get(apiName, `/transporter/${data?.transporter_id}`)
      setTransporter(transporterRes)

      let count = await API.get(apiName, `/gatepass?vehicle_id=${value}&start=${moment().startOf("day").unix()}&end=${moment().endOf("day").unix()}&deleted=${false}`)
      setCount((prev) => ({ ...prev, vehicle: count?.count }))
      if (transporterRes?.work_order) {
        let temp = transporterRes?.work_order
        let number = temp?.find(
          (item) =>
            item.route.toLowerCase() === data?.route_name.toLowerCase()
        );
        setWoNo(number);
      }
      const settings = await API.get(apiName, '/dashboard/settings?name=default_route')
      let route = JSON.parse(settings[0].value)
      if (route) {
        data.route_id = route?.value
        data.route_name = route?.label
      }
      if (data?.route_id === 2) {
        const check = await API.get(apiName, '/dashboard/settings?name=block more than 2 dumka gatepass')
        let res = await checkDumkaVehicle(data?.id)
        if (res >= 2 && check[0].value === 'true') {
          Swal.fire({
            title: 'Vehicle has 2 Dumka Gatepasses for today.',
            text: 'Route will change to Pakur !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#407050',
            cancelButtonColor: 'black',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel'
          }).then((result) => {
            if (result.isConfirmed) {
              data.route_id = 1
              data.route_name = 'Pakur'
              setVehicle(data)
            }
          });
        } else {
          setVehicle(data);
          clearInterval(int)
        }
      } else {
        setVehicle(data);
        clearInterval(int)
      }

    } catch (error) {
      toast.error("Something went wrong !");
      console.log(error);
    } finally { toast.dismiss(loading) }
  }

  async function handleScanDriver(value) {
    const loading = toast.loading('Loading...')
    try {
      let res = await API.get(apiName, `/driver/${value}`)
      let driver = res;
      if (driver === null) return toast.error("No Driver Found!");
      if (res.active === false) return toast.error("Driver is currently inactive!");

      let currentGatepass = await API.get(apiName, `/gatepass?driver_id=${value}`)

      if (currentGatepass.items.length > 0) {
        let currentExitAt = currentGatepass.items[0]?.rail_exit_time
        if (!currentExitAt) {
          toast.error(`Previous gatepass (${currentGatepass.items[0]?.id}) is still active for driver !`)
          return;
        }
      }

      let count = await API.get(apiName, `/gatepass?driver_id=${value}&start=${moment().startOf("day").unix()}&end=${moment().endOf("day").unix()}`)
      setCount((prev) => ({ ...prev, driver: count?.count }))
      setDriver(driver);
      return;
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong! ");
      return;
    } finally { toast.dismiss(loading) }
  }

  async function getDriverFromZK() {
    const scanning = toast.loading("Scanning...")
    try {
      if (!role.includes("IN_BARRIER")) {
        let currentRole = ''
        if (role.includes('WEIGH_BRIDGE')) currentRole = 'WEIGH_BRIDGE'
        if (role.includes('OUT_BARRIER')) currentRole = 'OUT_BARRIER'
        if (role.includes('RAILWAY_SIDING_ENTRY')) currentRole = 'RAILWAY_SIDING_ENTRY'
        if (role.includes('RAILWAY_SIDING_EXIT')) currentRole = 'RAILWAY_SIDING_EXIT'
        let res = await API.get(apiName, `/gatepass/zk/check-exit?door=${user?.lane}&role=${currentRole}`)
        if (res.gatepass) {
          setScanModal(false)
          return navigate(`/gatepass/${res.gatepass}`)
        }
        else if (res.message) return toast.error(res.message)
        else if (!res.vehicle_id) return toast.error(res.message)
        else return toast.error('No Gatepass Found !')
      }
      let res = await API.get(apiName, `/gatepass/zk/check-entry?door=${user?.lane}`)
      if (res?.vehicle_id) setVehicle({ id: res.vehicle_id })
      if (res.vehicle_id) {
        setScanModal(false)
        handleScanVehicle(res.vehicle_id)
      }
      else if (res.message) toast.error(res.message)
      else toast.error("No Driver found !")
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong !')
    } finally { toast.dismiss(scanning) }
  }
  async function getVehicleFromZK() {
    const scanning = toast.loading("Scanning...")
    try {
      if (!role.includes("IN_BARRIER")) {
        let currentRole = ''
        if (role.includes('WEIGH_BRIDGE')) currentRole = 'WEIGH_BRIDGE'
        if (role.includes('OUT_BARRIER')) currentRole = 'OUT_BARRIER'
        if (role.includes('RAILWAY_SIDING_ENTRY')) currentRole = 'RAILWAY_SIDING_ENTRY'
        if (role.includes('RAILWAY_SIDING_EXIT')) currentRole = 'RAILWAY_SIDING_EXIT'
        let res = await API.get(apiName, `/gatepass/zk/check-exit?door=${user?.lane}&role=${currentRole}`)
        if (res.gatepass) {
          setScanModal(false)
          return navigate(`/gatepass/${res.gatepass}`)
        }
        else if (res.message) return toast.error(res.message)
        else if (!res.vehicle_id) return toast.error(res.message)
        else return toast.error('No Gatepass Found !')
      }
      let res = await API.get(apiName, `/gatepass/zk/check-entry?door=${user?.lane}`)
      if (res?.vehicle_id) setVehicle({ id: res.vehicle_id })
      if (res.vehicle_id) {
        setScanModal(false)
        handleScanVehicle(res.vehicle_id)
      }
      else if (res.message) toast.error(res.message)
      else toast.error("No vehicle found !")
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong !')
    } finally { toast.dismiss(scanning) }
  }

  async function createGatePass() {
    try {
      // let checkLane = await checkvehicle(vehicle.id)
      // if (checkLane?.allow) {
      setCountDown(interval)
      setStart(false)
      setLoading(true)
      setPrinting(true)
      setGatepass((prev) => ({ ...prev, mine_entry_time: currentDate() }));

      const payload = {
        user_id: user?.id,
        transporter_id: vehicle.transporter_id,
        vehicle_id: vehicle.id,
        driver_id: driver?.id,
        route_id: vehicle?.route_id,
        active: true,
        offline: location.pathname.includes("create-offline") ? true : false,
        mine_entry_lane: user?.lane
      }

      let res = await API.post(apiName, '/gatepass', { body: payload })
      setStart(true)
      setGatepass((prev) => ({ ...prev, id: res.body[0].id }));
      if (res?.body[0]?.id) {
        setTimeout(async () => {
          window.print()
          setShow(false);
          setPrinting(false)
          await API.post(apiName, `/gatepass/zk/remote-access?door=${user?.lane}&type=Open&interval=${interval}`)
        }, 0)
      }

      if (vehicle?.suspicious) {
        await API.put(apiName, `/vehicle/${vehicle?.id}`, { body: { ...vehicle, suspicious: false } })
      }
      // } else {
      //   toast.error(checkLane?.message)
      // }

    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  }

  const makeOffline = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Gatepass will be in offline mode!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#407050',
      cancelButtonColor: 'black',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/gatepass/create-offline")
      }
    });
  }

  async function remote(type) {
    try {
      await API.post(apiName, `/gatepass/zk/remote-access?door=${user?.lane}&type=${type}&interval=${interval}`)
      if (type === 'Open') { setCountDown(interval); setStart(true); }
      else setCountDown(0)
      toast.success(`Gate is ${type}.`)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (closeModal && (role?.includes('WEIGH_BRIDGE') || role?.includes('RAILWAY_SIDING_ENTRY') || role?.includes('RAILWAY_SIDING_EXIT'))) { closeModal?.current?.click() }
    // if(role?.includes('IN_BARRIER') && vehicle){setScanDriverModal(true)}
  }, [role, vehicle])

  // useEffect(() => {
  //   if (scanModal && (!role?.includes('WEIGH_BRIDGE') || !role?.includes('RAILWAY_SIDING_ENTRY') || !role?.includes('RAILWAY_SIDING_EXIT'))) getVehicleFromZK()
  //   int = setInterval(() => {
  //     if (scanModal && (!role?.includes('WEIGH_BRIDGE') || !role?.includes('RAILWAY_SIDING_ENTRY') || !role?.includes('RAILWAY_SIDING_EXIT'))) getVehicleFromZK()
  //   }, 2000);
  //   return () => clearInterval(int)
  // }, [scanModal])
  useEffect(() => {
    if (role?.includes('WEIGH_BRIDGE') || role?.includes('RAILWAY_SIDING_ENTRY') || role?.includes('RAILWAY_SIDING_EXIT')) return;
    if (scanModal) getVehicleFromZK()
    int = setInterval(() => {
      if (scanModal) getVehicleFromZK()
    }, 2000);
    return () => clearInterval(int)
  }, [scanModal])

  // useEffect(() => {
  //   if (scanDriverModal && role?.includes('IN_BARRIER') && vehicle) getDriverFromZK()
  //   intDriver = setInterval(() => {
  //     if (scanDriverModal && !role?.includes('WEIGH_BRIDGE')) getDriverFromZK()
  //   }, 2000);
  //   return () => clearInterval(intDriver)
  // }, [scanDriverModal])

  let inter;
  useEffect(() => {
    if (start) {
      inter = setInterval(() => {
        setCountDown((prev) => prev - 1)
      }, 1000);
    }
    return () => clearInterval(inter)
  }, [start])

  useEffect(() => {
    if (countDown === 0) setStart(false)
  }, [countDown])



  let rolesAllowed = ["IN_BARRIER", "OUT_BARRIER", "WEIGH_BRIDGE", "RAILWAY_SIDING_ENTRY", "RAILWAY_SIDING_EXIT"]
  let allow = false

  for (let item of rolesAllowed) if (role?.includes(item)) allow = true

  if (!allow) return <NoAccess />

  return (
    <div className="row">
      {!scanModal && (
        <div className="bg-white side_pass col-3 rounded-4 d-flex flex-column gap-3 p-4" style={{ height: '84vh' }}>
          <div className="d-flex justify-content-between align-items-center my-4">
            <h1 className="m-0">{role?.includes("IN_BARRIER") ? location.pathname.includes("create-offline") ? `New Gate Pass - Offline - ${user?.lane}` : `New Gate Pass - ${user?.lane}` : "Update Gate Pass"}</h1>
            {role?.includes("IN_BARRIER") && <button className="btn btn-dark btn-sm" onClick={makeOffline}>Make Offline
              <i className="fe fe-wifi-off ms-1"></i>
            </button>}
          </div>
          {!role?.includes("IN_BARRIER") ? (
            <input className="form-control my-3" placeholder="Search Gatepass"
              onChange={(e) => {
                setTimeout(() => {
                  if (e.target.value.startsWith("G")) e.target.value = e.target.value.replace("G", "")
                  if (e.target.value.startsWith("g")) e.target.value = e.target.value.replace("g", "")
                  navigate(`/gatepass/${e.target.value.toUpperCase()}`)
                }, 600)
              }} />
          )
            :
            <>
              <AsyncSelect isDisabled={!show} key={id2} defaultValue={vehicle?.id ? { label: vehicle?.id, value: vehicle?.id } : null} isClearable theme={selectColor} required placeholder="Select Vehicle" loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => e?.value && handleScanVehicle(e?.value)} />
              <AsyncSelect key={id} isDisabled={!show} isClearable theme={selectColor} required placeholder="Select Driver" loadOptions={handleSearchDriver} defaultOptions onChange={(e) => e?.value && handleScanDriver(e?.value)} />
              {show && (
                <button
                  className="btn btn-dark d-flex justify-content-center"
                  onClick={createGatePass}
                  disabled={!vehicle || loading || !vehicle?.transporter_id || !transporter || !vehicle?.route_id}
                  type="button"
                >
                  Print <i className="fe fe-printer ms-1"></i>
                </button>)}
              {!show && (
                <button
                  className="btn btn-dark d-flex justify-content-center"
                  type="button"
                  onClick={() => {
                    setPrinting(true)
                    setTimeout(() => {
                      window.print()
                      setPrinting(false)
                    }, 20)
                  }}
                >
                  Print <i className="fe fe-printer ms-1"></i>
                </button>
              )}
              <button
                className="btn btn-light d-flex justify-content-center"
                type="button"
                onClick={() => {
                  setVehicle(null); setDriver(null); setWoNo(null); setGatepass(null)
                  setShow(true)
                  setTransporter(null)
                  setCount(null)
                  setLoading(false)
                  setId(new Date().getMilliseconds())
                  setId2(Math.random())
                  setScanModal(true)
                }}>
                Clear <i className="fe fe-x ms-1"></i>
              </button>
            </>
          }
          {(role?.includes("IN_BARRIER") || role?.includes("OUT_BARRIER")) && (
            <>
              <div className="d-flex w-100 gap-2 remote_btns">
                <button className="btn btn-primary d-flex justify-content-center w-100" onClick={() => remote("Open")}><i className="fe fe-chevrons-up" />Open Gate</button>
                <button className="btn btn-danger d-flex justify-content-center w-100" onClick={() => remote("Close")}><i className="fe fe-chevrons-down" /> Close Gate</button>
              </div>
              <p className="pointer remote_btns" onClick={() => {
                // if(vehicle) setScanDriverModal(true)
                // else
                setScanModal(true)
              }}><u><b className="text-primary">Switch to auto mode</b></u></p>
            </>
          )}
        </div>
      )}
      <div className="col-9 px-5">
        <div key={new Date().getSeconds()} className="gatepass_container bg-white  mb-5 p-4 rounded-4" style={{ backgroundImage: 'url("/img/truck-watermark.png")', backgroundPosition: "center", backgroundRepeat: 'no-repeat', height: `${!printing && '84vh'}` }}>
          <div className={`row px-5 py-2 ${!printing && "my-5"}`} >
            <div className="col-3">
              <img src="/img/logo.svg" alt="logo" height={printing ? 34 : 60} className="mt-3" />
            </div>
            <div className="col-6 text-center">
              <h1 className="mb-2">
                <u>Loading Slip & Gate Pass</u>
              </h1>
              <h3 className="mb-2">WBPDCL (A Govt Company)</h3>
              <h3 className="m-0">Pachhwara North Coal Mine</h3>
              {gatepass?.offline || location.pathname.includes("create-offline") && <h3 className="mt-2 text-danger">OFFLINE</h3>}
            </div>
            <div className="col-3">
              {show === false && <h2 className="m-0 text-center">*DUPLICATE</h2>}

              {gatepass?.id && (
                <div className="d-flex justify-content-end">
                  <Barcode value={gatepass?.id} height={60} />
                </div>)}
            </div>
          </div>
          <table className={`table ${printing ? "table-bordered table-sm" : ""}  w-100`}>
            <tbody>
              <tr>
                <td>S. No</td>
                <th>{gatepass?.id}</th>
                <td colSpan={2} className="text-center fs-2">
                  <strong>{transporter?.name}</strong>
                </td>
              </tr>
              <tr>
                <td>Trip ID</td>
                <th></th>
                <td rowSpan={2} className="text-center fs-1">
                  <strong>{vehicle?.id}</strong>
                </td>
                <td rowSpan={2} className="text-center fs-1">
                  <strong style={{ fontWeight: '700' }}>
                    {vehicle?.route_name}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>Work Order No.</td>
                <th>{woNo?.number}</th>
              </tr>
              <tr>
                <td>RFID No. / RCD Pin</td>
                <th>{vehicle?.rfid} / {vehicle?.rcd_pin}</th>
                <td className="text-start">Today's Trip Count</td>
                <th>{count?.vehicle && +count?.vehicle + 1}</th>
              </tr>
              <tr>
                <td>Driver's Name</td>
                <th style={{ textTransform: "uppercase" }}>
                  {driver?.name}  {driver?.id && `(${driver?.id})`}
                </th>
                <td>Driver's Age</td>
                <th className="text-start">
                  {driver?.dob && moment().diff(moment.unix(driver?.dob), "years") + " Years"}
                </th>
              </tr>
              <tr>
                <td>Driver's Licence No.</td>
                <th>
                  {driver?.licence_number}
                </th>
                <td className="text-start">Validity Till </td>
                <th>{driver?.licence_expiry && moment.unix(driver?.licence_expiry).format('DD-MM-YYYY')}</th>
              </tr>
              <tr>
                <td>Mobile No. </td>
                <th> {driver?.phone}</th>
                <td className="text-start">Driver's Today's Trip No. </td>
                <th>{count?.driver && +count?.driver + 1}</th>
              </tr>
              <tr >
                <td className="w-25">Coal Stock Yard In Time</td>
                <th style={{ fontSize: "14px" }} className="w-25">
                  {gatepass?.mine_entry_time && moment(gatepass?.mine_entry_time)?.format('DD-MM-YYYY[, ]hh:mm A')}
                </th>
                <td className="text-start">Coal Stock Yard Out Time</td>
                <th></th>
              </tr>
            </tbody>
          </table>
          <div className="row px-3 py-2 small text-center mt-2">
            <div className="col">
              <hr />
              Coal Yard Supervisor Sign
            </div>
            <div className="col">
              <hr />
              In Gate Security Supervisor
            </div>
            <div className="col">
              <hr />
              Weighment Clerk Sign
            </div>
            <div className="col">
              <hr />
              Out Gate Security Supervisor
            </div>
          </div>
        </div>
      </div>
      <Modal centered show={scanModal}>
        <div className="d-flex justify-content-center flex-column align-items-center gap-5 rounded position-relative">
          <h3 className="position-absolute top-0 end-0 m-4">{user?.lane}</h3>
          <img src="/img/logo.svg" alt="logo" height={30} className="position-absolute top-0 start-0 m-4" />
          <img src="/img/scan-truck.gif" height={560} alt="scan-truck" className="rounded" />
          <button className="btn btn-dark h-50 w-50 mb-5 fs-2" onClick={getVehicleFromZK}> <img src='/img/scan-qr.gif' height={40} className="rounded-2 mx-3"
          />Scanning Vehicle....</button>
        </div>
        <p className="text-center pointer text-secondary" ref={closeModal} onClick={() => setScanModal(false)}>Having Issues ? <u><b className="text-primary">Switch to manual mode</b></u></p>
      </Modal>
      <Modal centered show={scanDriverModal}>
        <div className="d-flex justify-content-center flex-column align-items-center gap-5 rounded position-relative">
          <h3 className="position-absolute top-0 end-0 m-4">{user?.lane}</h3>
          <img src="/img/logo.svg" alt="logo" height={30} className="position-absolute top-0 start-0 m-4" />
          <img src="/img/scan-face.gif" height={560} alt="scan-truck" className="rounded" />
          <button className="btn btn-dark h-50 w-50 mb-5 fs-2" onClick={getVehicleFromZK}> <img src='/img/scan-qr.gif' height={40} className="rounded-2 mx-3"
          />Scanning Driver....</button>
        </div>
        <p className="text-center pointer text-secondary" ref={closeDriverModal} onClick={() => setScanDriverModal(false)}>Having Issues ? <u><b className="text-primary">Switch to manual mode</b></u></p>
      </Modal>
      {start && (
        <div className="text-center timer bg-danger position-absolute end-0 rounded p-2 text-white" style={{ width: '10rem' }}>
          <p className="m-0">Gate will close in</p>
          <h1 className="m-0 display-4" >{countDown} sec</h1>
        </div>
      )}
    </div>
  );
}
