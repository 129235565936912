import { createSlice } from "@reduxjs/toolkit";

export const settingSlice = createSlice({
  name: "setting",
  initialState: [],
  reducers: {
    setSetting: (state, action) => {
      return (state = action);
    },
  },
});

export const { setSetting } = settingSlice.actions;
export default settingSlice.reducer;
