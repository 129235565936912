import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Spinner from "../../components/Spinner";
import { apiName, approvalRoles, customRoles, dashboardRoles, gatepassRoles, handleSearchTransporter, lanes, otherRoles, reportRoles, selectColor, superAdminRoles } from "../../components/Utils";
import Header from "../../layouts/Header";
import Select from "react-select";


export default function UpdateUser() {
  const navigate = useNavigate();
  const route = useLocation();
  const path = route.pathname;

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tempRole, setTempRole] = useState([])
  const [vehicleTypes, setVehicleTypes] = useState([])

  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    role: [],
    lane: ""
  });
  const [transporter, setTransporter] = useState(null)


  function handleFormChange(field, value) {
    let temp = structuredClone(user);
    temp[field] = value;
    setUser(temp);
  }

  async function handleUpdate() {
    setSubmitting(true);
    let { role } = user;
    let isMulti = role.filter(item => item?.includes("APPROVAL"))
    if (isMulti.length > 1) {
      setSubmitting(false)
      return toast.error("Can't have Multiple Approval Role.")
    }
    try {
      await API.put(apiName, `/user/${id}`, { body: { ...user, role, transporter_id: transporter?.value, active: true } })
      await API.put(apiName, `/user/update-attributes/${user?.id_ext}`, { body: { attributes: { phone_number: user?.phone, name: user?.name } } })

      navigate("/users");
      toast.success("User updated successfully.");
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      toast.error("Failed to Update user!");
    }
  }

  async function getData() {
    setLoading(true);
    try {
      let res = await API.get(apiName, `/user/${id}`)
      let type_res = await API.get(apiName, '/vehicle-type')
      setVehicleTypes(type_res.items)
      let data = res
      if (!data.role) data.role = []
      setUser(data);
      if (data?.transporter_id) {
        let resT = await API.get(apiName, `/transporter/${data?.transporter_id}`)
        setTransporter({ label: resT?.name, value: resT?.id })
      }

      setTempRole(data?.role)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function get(type) {
    try {
      await API.post(apiName, `/gatepass/zk/remote-access?door=${user?.lane}&type=${type}&interval=30`)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);



  if (loading) return <Spinner show={true} />;

  return (
    <div className="container">
      <Header name="Update User" />
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <form
            className="tab-content py-2"
            id="wizardSteps"
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdate();
            }}
          >
            <div>
              <div className="form-group">
                <label className="form-label">Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Eg. Anuj Prasad"
                  required
                  value={user.name}
                  maxLength={40}
                  onChange={(e) => handleFormChange("name", e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="form-label">Email <span className="text-danger">*</span></label>

                <input
                  type="text"
                  disabled={id}
                  className="form-control"
                  placeholder="Eg. prasad@gmail.com"
                  required
                  value={user.email}
                  maxLength={40}
                  onChange={(e) => handleFormChange("email", e.target.value)}
                />
              </div>

              <div className="form-group">
                <label id="topic" className="form-label">Phone <span className="text-danger">*</span></label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  country={"in"}
                  value={user.phone}
                  onChange={(phone) => handleFormChange("phone", "+" + phone)}
                />
              </div>
              <div className="form-group">
                <label id="topic" className="form-label">Transporter <b>(Select only if it is a transporter)</b></label>
                <AsyncSelect isClearable theme={selectColor} placeholder="Select Transporter" cacheOptions defaultValue={transporter} loadOptions={handleSearchTransporter} defaultOptions onChange={(e) => setTransporter(e)} />

              </div>
              <div className="form-group">
                <label id="topic" className="form-label">Lane <b>(Select only if it is related to Gatepass)</b></label>
                <Select isClearable theme={selectColor} placeholder="Select Lane" defaultValue={user?.lane ? { label: user.lane, value: user.lane } : null} options={lanes.map((item) => {
                  return { label: item, value: item };
                })} defaultOptions onChange={(e) => handleFormChange("lane", e?.value)} />

              </div>
              <div className="form-group">
                <div className="row">
                  <label id="topic" className="form-label col-6">Access</label>
                  <button type="button" className="col-auto ms-auto mx-5 btn btn-light btn-sm" onClick={() => {
                    setUser((prev) => ({ ...prev, role: [] }))
                  }}>Clear Access</button>

                </div>

                <div className="row m-2" key={vehicleTypes[0]}>
                  <div className="bg-white rounded p-3 row gap-3 mb-3">
                    <h4 className="p-0">Custom Roles</h4>
                    {customRoles.map((item => (
                      <div className="form-check form-switch col col-6 gap-3 col-lg-3" key={item}>
                        <input className="form-check-input" type="checkbox" checked={user.role.includes(item)} onChange={(e) => {
                          if (e.target.checked) {
                            if (item === 'SUPER_ADMIN') setUser((prev) => ({ ...prev, role: [...superAdminRoles, ...vehicleTypes.map(item => String(item.id)), 'SUPER_ADMIN'] }))
                            if (item === 'ADMIN') setUser((prev) => ({ ...prev, role: [...superAdminRoles.filter(item => !["PARAMETERS", 'USERS'].includes(item)), "ADMIN", ...vehicleTypes.map(item => String(item.id))] }))
                            if (item === 'MANAGER') setUser((prev) => ({ ...prev, role: [...superAdminRoles.filter(item => !["PARAMETERS", 'USERS'].includes(item)), "MANAGER", ...vehicleTypes.map(item => String(item.id))] }))
                            if (item === 'INCHARGE') setUser((prev) => ({ ...prev, role: ['TRACKER', '10026', '10001', 'INCHARGE'] }))
                            if (item === 'VIEWER') setUser((prev) => ({ ...prev, role: ['TRACKER', '10003', 'VIEWER'] }))
                            if (item === 'PATROLLING') setUser((prev) => ({ ...prev, role: ['TRACKER', 'PATROLLING_VEHICLE', 'PATROLLING', ...vehicleTypes.map(item => String(item.id))] }))
                            if (item === 'TRANSPORTER') setUser((prev) => ({ ...prev, role: ['TRACKER', 'TRANSPORTER'] }))
                            if (item === 'DEVELOPER') setUser((prev) => ({ ...prev, role: [...superAdminRoles.filter(item => !["PARAMETERS", 'USERS'].includes(item)), "DEVELOPER", ...vehicleTypes.map(item => String(item.id))] }))
                          }
                          else {
                            let temp = structuredClone(user)
                            temp.role = []
                            setUser(temp)
                          }
                        }}
                        />
                        <label className="form-check-label text-wrap" htmlFor="flexSwitchCheckChecked">{item}</label>
                      </div>
                    )))}
                  </div>

                  <div className="bg-white rounded p-3 row gap-3 mb-3">
                    <h4 className="p-0">Dashboard</h4>
                    {dashboardRoles.map((item => (
                      <div className="form-check form-switch col col-6 gap-3 col-lg-3" key={item}>
                        <input className="form-check-input" type="checkbox" checked={user?.role?.find(x => x === item) !== undefined} onChange={(e) => {
                          if (e.target.checked) {
                            setUser((prev) => ({ ...prev, role: [...prev.role, item] }))
                          } else {
                            let temp = structuredClone(user)
                            temp.role = temp.role.filter(x => x !== item)
                            setUser(temp)
                          }
                        }}
                        />
                        <label className="form-check-label text-wrap" htmlFor="flexSwitchCheckChecked">{item}</label>
                      </div>
                    )))}
                  </div>

                  <div className="bg-white rounded p-3 row gap-3 mb-3">
                    <h4 className="p-0">Gatepass</h4>
                    {gatepassRoles.map((item => (
                      <div className="form-check form-switch col col-6 gap-3 col-lg-3" key={item}>
                        <input className="form-check-input" type="checkbox" checked={user?.role?.find(x => x === item) !== undefined} onChange={(e) => {
                          if (e.target.checked) {
                            setUser((prev) => ({ ...prev, role: [...prev.role, item] }))
                          } else {
                            let temp = structuredClone(user)
                            temp.role = temp.role.filter(x => x !== item)
                            setUser(temp)
                          }
                        }}
                        />
                        <label className="form-check-label text-wrap" htmlFor="flexSwitchCheckChecked">{item}</label>
                      </div>
                    )))}
                  </div>
                  <div className="bg-white rounded p-3 row gap-3 mb-3">
                    <h4 className="p-0">Reports</h4>
                    {reportRoles.map((item => (
                      <div className="form-check form-switch col col-6 gap-3 col-lg-3" key={item}>
                        <input className="form-check-input" type="checkbox" checked={user?.role?.find(x => x === item) !== undefined} onChange={(e) => {
                          if (e.target.checked) {
                            setUser((prev) => ({ ...prev, role: [...prev.role, item] }))
                          } else {
                            let temp = structuredClone(user)
                            temp.role = temp.role.filter(x => x !== item)
                            setUser(temp)
                          }
                        }}
                        />
                        <label className="form-check-label" style={{ width: "max-content" }} htmlFor="flexSwitchCheckChecked">{item}</label>
                      </div>
                    )))}
                  </div>
                  <div className="bg-white rounded p-3 row gap-3 mb-3">
                    <h4 className="p-0">Vehicle Types</h4>
                    {vehicleTypes.map((item => (
                      <div className="form-check form-switch col col-6 gap-3 col-lg-3" key={item.id}>
                        <input className="form-check-input" type="checkbox" checked={user?.role?.find(x => x === String(item.id)) !== undefined} onChange={(e) => {
                          if (e.target.checked) {
                            setUser((prev) => ({ ...prev, role: [...prev.role, String(item.id)] }))
                          } else {
                            let temp = structuredClone(user)
                            temp.role = temp.role.filter(x => x !== String(item.id))
                            setUser(temp)
                          }
                        }}
                        />
                        <label className="form-check-label" style={{ width: "max-content" }} htmlFor="flexSwitchCheckChecked">{item.name}</label>
                      </div>
                    )))}
                  </div>
                  <div className="bg-white rounded p-3 row gap-3 mb-3">
                    <h4 className="p-0">Other</h4>
                    {otherRoles.map((item => (
                      <div className="form-check form-switch col col-6 gap-3 col-lg-3" key={item}>
                        <input className="form-check-input" type="checkbox" checked={user?.role?.find(x => x === item) !== undefined} onChange={(e) => {
                          if (e.target.checked) {
                            setUser((prev) => ({ ...prev, role: [...prev.role, item] }))
                          } else {
                            let temp = structuredClone(user)
                            temp.role = temp.role.filter(x => x !== item)
                            setUser(temp)
                          }
                        }}
                        />
                        <label className="form-check-label text-wrap" htmlFor="flexSwitchCheckChecked">{item}</label>
                      </div>
                    )))}
                  </div>

                  <div className="form-check form-switch col col-6 gap-3 col-lg-3" >
                    <input className="form-check-input" type="checkbox" onChange={(e) => {
                      if (e.target.checked) {
                        let ismulit = tempRole.filter(item => item.includes("APPROVAL"))
                        let allVehicles = vehicleTypes.map(item => String(item.id))
                        setUser((prev) => ({ ...prev, role: [...dashboardRoles, ...gatepassRoles, ...reportRoles, ...otherRoles, ...ismulit, ...allVehicles] }))
                      } else {
                        let temp = structuredClone(user)
                        temp.role = tempRole
                        setUser(temp)
                      }
                    }}
                    />
                    <label className="form-check-label text-wrap" htmlFor="flexSwitchCheckChecked">All</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                {/* <label id="topic" className="form-label">Approval Access </label> */}
                <div className="row gap-3 ms-1 bg-white p-3 rounded ">
                  <h4 className="p-0">Approval Access</h4>
                  {approvalRoles.map((item => (
                    <div className="form-check form-switch col col-6 gap-3 col-lg-3" key={item?.value}>
                      <input className="form-check-input" type="checkbox" checked={user.role.find(x => x === item?.value) !== undefined} onChange={(e) => {
                        if (e.target.checked) {
                          setUser((prev) => ({ ...prev, role: [...prev.role, item?.value] }))
                        } else {
                          let temp = structuredClone(user)
                          temp.role = temp.role.filter(x => x !== item?.value)
                          setUser(temp)
                        }
                      }}
                      />
                      <label className="form-check-label text-wrap" htmlFor="flexSwitchCheckChecked">{item?.label}</label>
                    </div>
                  )))}
                </div>
              </div>


              <div className="nav row align-items-center my-3">
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  <div className="col-auto">
                    <button
                      type="submit"
                      className="btn btn-primary fw-bold"
                      disabled={submitting === true}
                    >
                      {submitting ? (
                        <div
                          className="spinner-border text-white"
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          role="status"
                        ></div>
                      ) : (
                        <span>Save</span>
                      )}
                    </button>
                    {!submitting && (
                      <button
                        onClick={() => navigate(path.includes("mobile") ? "/mobile/users" : "/users")}
                        type="button"
                        className="btn btn-light mx-2 fw-bold"
                        disabled={submitting}>
                        Cancel
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}


