import React, { useRef } from "react";
import { fileUpload } from "./Utils";

export default function DropUpload({ state, setState, handleDrop, label }) {
  const imgInputRef = useRef();

  function handleRemove(item) {
    let temp = [...state];
    temp = temp.filter((url) => url !== item);
    setState(temp);
  }

  return (
    <div className="mb-4">
      <div
        className="card"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          handleDrop(e.dataTransfer.files[0], setState);
        }}
      >

        <div className="card-body">
          <div
            className="dropzone dropzone-multiple"
            role="presentation"
            tabIndex="0"
          >
            <input
              key={new Date().getMilliseconds()}
              type="file"
              tabIndex="-1"
              hidden
              ref={imgInputRef}
              accept={
                typeof state === "string"
                  ? "image/*"
                  : "image/*,application/pdf"
              }
              onChange={async (e) =>
                handleDrop(
                  e.target.files[0],
                  await fileUpload(e.target.files[0]),
                  setState
                )
              }
            />
            <div className="dz-message">
              Drop files or{" "}
              <button
                type="button"
                className="btn btn-sm mx-2 btn-primary fw-bold"
                onClick={() => imgInputRef.current.click()}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
      {typeof state === "string" ? (
        <div
          className="position-relative"
          style={{ width: "fit-content", borderRadius: ".4rem" }}
        >
          {state.length > 0 && (
            <img
              style={{
                borderRadius: ".4rem",
                objectFit: "cover",
              }}
              src={state}
              alt="cover_image"
              height={"100px"}
              width={"100px"}
              key={new Date().getMilliseconds()}
            />
          )}
        </div>
      ) : (
        <div className="d-flex gap-3">
          {Array.isArray(state) && state?.map((item, index) => (
            <div key={index}>
              {item.type.includes("pdf") && (
                <div
                  className="position-relative"
                  style={{ width: "fit-content", borderRadius: ".4rem" }}
                >
                  <a href={item?.url} target="blank">
                    <img
                      src="/img/pdf.png"
                      height={"100px"}
                      width={"100px"}
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                  <i
                    className="fe fe-x position-absolute top-0 end-0 bg-primary pointer"
                    onClick={() => handleRemove(item)}
                  ></i>
                </div>
              )}
              {item.type.includes("image") && (
                <div
                  className="position-relative"
                  style={{ width: "fit-content", borderRadius: ".4rem" }}
                >
                  <img
                    style={{
                      borderRadius: ".4rem",
                      objectFit: "cover",
                    }}
                    src={item.url}
                    alt="cover_image"
                    height={"100px"}
                    width={"100px"}
                    key={new Date().getMilliseconds()}
                  />
                  <i
                    className="fe fe-x position-absolute top-0 end-0 bg-light pointer"
                    onClick={() => handleRemove(item)}
                  ></i>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {/* <div className="d-flex gap-3">
        {state?.map((item) => (
          <div key={item.url}>
            {item.type.includes("pdf") && (
              <div
                className="position-relative"
                style={{ width: "fit-content", borderRadius: ".4rem" }}
              >
                <a href={item?.url} target="blank">
                  <img
                    src="/img/pdf.png"
                    height={"100px"}
                    width={"100px"}
                    style={{ objectFit: "contain" }}
                  />
                </a>
                <i
                  className="fa fa-remove position-absolute top-0 end-0 bg-primary"
                  onClick={() => handleRemove(item)}
                ></i>
              </div>
            )}
            {item.type.includes("image") && (
              <div
                className="position-relative"
                style={{ width: "fit-content", borderRadius: ".4rem" }}
              >
                <img
                  style={{
                    borderRadius: ".4rem",
                    objectFit: "cover",
                  }}
                  src={item.url}
                  alt="cover_image"
                  height={"100px"}
                  width={"100px"}
                  key={new Date().getMilliseconds()}
                />
                <i
                  className="fa fa-remove position-absolute top-0 end-0 bg-primary"
                  onClick={() => handleRemove(item)}
                ></i>
              </div>
            )}
          </div>
        ))}
      </div> */}
    </div>
  );
}
