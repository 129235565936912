import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import DropUpload from "../../components/DropUpload";
import NoAccess from "../../components/NoAccess";
import Spinner from "../../components/Spinner";
import { apiName, fileUpload, handleSearchTransporter, selectColor } from "../../components/Utils";
import Header from "../../layouts/Header";


export default function AddDriver() {
  const navigate = useNavigate();
  const { id } = useParams();
  const route = useLocation()
  const path = route.pathname
  const userRole = useSelector((state) => state?.userInfo?.payload?.role);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [driver, setDriver] = useState({});
  const [img, setImg] = useState("");

  function handleFormChange(field, value) {
    let temp = structuredClone(driver);
    temp[field] = value;
    setDriver(temp);
  }
  async function handleDrop(file) {
    setImg(await fileUpload(file));
  }

  async function handleSubmit() {
    const { name, phone, transporter_id, licence_number, expire, dob, v_type } = driver;
    let res = await API.get(apiName, `/driver?licence_number=${licence_number}`)
    if (res.count !== "0") return toast.error("Duplicate Licence Number Found !")
    setSubmitting(true);

    try {
      let input = {
        name: name.trim(),
        phone: phone || null,
        image: img || null,
        licence_number,
        licence_expiry: moment(expire).unix() || null,
        dob: moment(dob).unix() || null,
        active: true,
        transporter_id,
        v_type
      }
      if (id) {
        input = { ...input, ...driver, id }
        await API.put(apiName, `/driver/${id}`, { body: input })
      }
      else await API.post(apiName, `/driver`, { body: input })
      toast.success(`Driver ${id ? 'updated' : 'added'} successfully.`);
      navigate('/drivers')
    } catch (error) {
      console.log(error)
      console.log('Something went wrong !')
    }
    setSubmitting(false);
  }

  async function getData() {
    try {
      if (id) {
        setLoading(true);
        const res = await API.get(apiName, `/driver/${id}`)
        const data = res
        setDriver(data)
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this Driver!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#407050',
      cancelButtonColor: 'black',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await API.put(apiName, `/driver/${id}`, { body: { ...driver, deleted: true } })
      }
      toast.success("Driver deleted.")
      navigate("/drivers")
    });
  }

  useEffect(() => {
    if (userRole?.includes("DRIVERS")) {
      getData();
    }
  }, [userRole]);

  if (!userRole?.includes("DRIVERS")) return <NoAccess />;

  if (loading) return <Spinner show={true} />;

  return (
    <div className="container" >
      <Header name={id ? "Update Driver" : "New Driver"} />
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <form
            className="tab-content py-2"
            id="wizardSteps"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
            <div>
              <div className="form-group">
                <label className="form-label mx-1">Driver Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Eg. Anuj Prasad"
                  required
                  value={driver.name || ""}
                  onChange={(e) => handleFormChange("name", e.target.value)}
                />
              </div>

              <div className="form-group">
                <label id="topic" className="form-label mx-1">Phone <span className="text-danger">*</span></label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  country={"in"}
                  value={driver.phone || ""}
                  onChange={(phone) => handleFormChange("phone", "+" + phone)}
                />
              </div>
              <div className="d-flex gap-2">
                <div className="form-group w-100">
                  <label className="form-label mx-1">Driving Licence Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. JH1520120021051"
                    value={driver.licence_number || ""}
                    maxLength={15}
                    minLength={15}
                    onChange={(e) => handleFormChange("licence_number", e.target.value.toUpperCase())}
                  />
                </div>
                <div className="form-group w-100">
                  <label className="form-label mx-1">Expiry Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Eg. Anuj Prasad"
                    min={moment().format("YYYY-MM-DD")}
                    value={driver.licence_expiry ? moment.unix(driver.licence_expiry).format("YYYY-MM-DD") : null}
                    onChange={(e) => handleFormChange("licence_expiry", moment(e.target.value).unix())}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label mx-1">
                  Date of Birth
                </label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Eg. Anuj Prasad"
                  value={driver.dob ? moment.unix(driver.dob).format("YYYY-MM-DD") : null}
                  max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                  onChange={(e) => handleFormChange("dob", moment(e.target.value).unix())}
                />
              </div>

              <div className="form-group">
                <label id="topic" className="form-label mx-1">
                  Transporter
                </label>
                <AsyncSelect key={driver.transporter} isClearable defaultValue={driver?.transporter_id ? { label: driver?.transporter_name, value: driver?.transporter_id } : null} theme={selectColor} placeholder="Select Transporter" cacheOptions loadOptions={handleSearchTransporter} defaultOptions onChange={(e) => handleFormChange('transporter_id', e?.value)} />
              </div>
              <div className="form-group">
                <input type="checkbox" className="form-check-input" checked={driver?.v_type} onChange={(e) => handleFormChange('v_type', e?.target?.checked ? "water_tanker" : null)} />
                <label id="topic" className="form-label mx-1">
                  Water Tanker Driver
                </label>
              </div>
              <div className="form-group">
                <label id="topic" className="form-label mx-1">
                  Image
                </label>
                <DropUpload
                  state={img}
                  handleDrop={handleDrop}
                  setState={setImg}
                />
              </div>

              <div className="nav row align-items-center mb-3">
                {loading ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  <div className="col-auto">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={submitting === true || !driver?.phone}
                    >
                      {submitting ? (
                        <div

                          className="spinner-border text-white"
                          style={{
                            height: "25px",
                            width: "25px",
                          }}
                          role="status "
                        ></div>
                      ) : (
                        <span>Save</span>
                      )}
                    </button>
                    {!submitting && (
                      <button
                        onClick={() => path.includes("mobile") ? navigate("/mobile/drivers") : navigate("/drivers")}
                        type="button"
                        className="btn btn-light mx-2">
                        Cancel
                      </button>
                    )}
                    {id && !submitting && userRole?.includes("USERS") && (
                      <button
                        onClick={() => onDelete(id)}
                        type="button"
                        className="btn btn-dark mx-2"
                      >
                        Delete
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {driver?.comments && (
        <div className="table-responsive bg-white my-5">
          <h3 className="px-3 pt-3">Status History</h3>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Reason</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {driver?.comments && driver?.comments?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.username}</td>
                  <td>{item?.msg}</td>
                  <td>{item?.active ? "Active" : "Block"}</td>
                  <td style={{ minWidth: '200px' }}>{moment(item?.time).format("DD-MM-YYYY[, ]hh:mm A")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>)}
    </div>
  );
}
