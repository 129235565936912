import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { apiName } from '../components/Utils';
import { setSetting } from '../store/setting';
import { setUserInfo } from '../store/userInfo';
import TopNav from './TopNav';
import axios from 'axios';

export default function Wrapper() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const path = useLocation()
  const [location, setLocation] = useState('')

  async function checkAuthState() {
    try {
      let cogntioRes = await Auth.currentAuthenticatedUser();
      console.log(cogntioRes)
      let currentUser = await API.get(apiName, `/user/${cogntioRes?.attributes?.sub}`)
      let data = await API.get(apiName, "/dashboard/settings")
      data = data.sort((a, b) => a.name.localeCompare(b.name))
      dispatch(setSetting(data))

      if (currentUser?.deleted === true) {
        toast.error("Unauthorized Access")
        Auth.signOut()
        navigate("/")
        return;
      }
      let roleArray = currentUser?.role;
      dispatch(setUserInfo(currentUser));
      if (!roleArray) {
        navigate("/wait");
        return;
      }
      navigator.geolocation.getCurrentPosition(async (data) => {
        setLocation({ lat: data.coords.latitude, lng: data.coords.longitude })
        var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + data.coords.latitude + "," + data.coords.longitude + "&key=AIzaSyA406ENQAh8iTmJGj8jURjfnscx5osITcQ";

        let res = await axios.get(url)
        await API.post(apiName, '/login-history', { body: { user_id: currentUser?.id, location: { lat: data.coords.latitude, lng: data.coords.longitude }, address: res.data.plus_code.compound_code, device: navigator.userAgent } })
        setLocation(res.data.plus_code.compound_code)
      })
    } catch (e) {
      navigate("/")
      Auth.signOut()
      console.log(e);
    }
  }

  async function checkIfBlocked() {
    try {
      let res = await Auth.currentAuthenticatedUser();
      console.log(res)
    } catch (e) {
      navigate("/")
      Auth.signOut()
      console.log(e);
    }
  }

  useEffect(() => {
    checkAuthState()
  }, [])

  useEffect(() => {
    checkIfBlocked()
  }, [path.pathname])

  return (
    <>
      <TopNav address={location} />
      <div className='py-lg-1 px-lg-5 mt-4'>
        {location && <Outlet />}
      </div>
    </>
  )
}
