import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import NoAccess from '../../components/NoAccess';
import ReportCard from '../../components/ReportCard';

export default function TransporterMaster() {
    const [data, setData] = useState([])

    function formatData(data, route, routes) {
        const res = data.map((item, idx) => {
            let report = {
                "S.No": idx + 1,
                "Date In": moment.unix(item?.created).format("DD-MMM-YYYY"),
                "ID": item?.id,
                "Name": item?.name,
                "Total Vehicles": item?.vehicle_count,
                "Email": item?.email,
                "Phone": item?.phone,
                "GST": item?.gstin,
                "PAN": item?.pan_number,
                "Address 1": item?.address?.address1,
                "City": item?.address?.city,
                "State": item?.address?.state,
                "Postal Code": item?.address?.postalCode,
            };

            (route ? routes.filter(item => item.name === route) : routes).map(x => {
                report[`WO_${x.name}`] = item?.work_order?.find(item => item.route.toLowerCase() === x.name.toLowerCase())?.number
            })

            return report
        })
        setData(res)
        return res
    }

    const roles = useSelector((state) => state.userInfo.payload?.role)
    if (!roles?.includes("TRANSPORTER-MASTER")) {
        return <NoAccess />
    }

    return (
        <>
            <ReportCard title={"Transporter Master Report"} formatData={formatData} api={'transporter-master-report'} />
            <div className="table-responsive card">
                <table className="table-hover table-bordered">
                    <thead className="sticky-top bg-primary text-white" style={{ zIndex: 0 }}>
                        <tr>
                            {data?.length > 0 && Object.keys(data[0])?.map(item => (
                                <th className={`fs-5 ${item !== 'Name' && "text-center"}`} key={item}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, idx) => (
                            <tr key={idx}>
                                <td align='center' className='fs-5'>{item["S.No"]}</td>
                                <td align='center' className='fs-5'>{item["Date In"]}</td>
                                <td align='center' className='fs-5'>{item["ID"]}</td>
                                <td align='left' className='fs-5'>{item["Name"]}</td>
                                <td align='center' className='fs-5'>{item["Total Vehicles"]}</td>
                                <td align='center' className='fs-5'>{item["Email"]}</td>
                                <td align='center' className='fs-5'>{item["Phone"]}</td>
                                <td align='center' className='fs-5'>{item["GST"]}</td>
                                <td align='center' className='fs-5'>{item["PAN"]}</td>
                                <td align='center' className='fs-5'>{item["Address 1"]}</td>
                                <td align='center' className='fs-5'>{item["City"]}</td>
                                <td align='center' className='fs-5'>{item["State"]}</td>
                                <td align='center' className='fs-5'>{item["Postal Code"]}</td>
                                {Object.keys(item).filter(item => item.includes('WO')).map((x, i) => (
                                    <td key={i} align='center' className='fs-5'>{item[x]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
