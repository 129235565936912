const AuthLayout = ({ children }) => {
  return (
    <div className=" bg-primary w-full d-flex justify-content-center  align-items-center" style={{ height: "100vh", background: "url(/img/auth-side-cover.png)" }}>
      <div className="row w-100 justify-content-center">
        <div className="card col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 py-5 px-1 auth-animate glass-morph">{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;